import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const UPDATE_USER_TIMEZONE = "updateUserTimezone";
export const UPDATE_USER_TIMEZONE_SETTING = "updateUserTimezoneSetting";

// mutation types
export const SET_USER_TIMEZONE = "setUserTimezone";
export const SET_USER_TIMEZONE_SETTING = "setUserTimezoneSetting";

const state = {
  system_timezones: [],
  user_timezone: {},
  user_timezone_setting: {}
};

const getters = {
  supportedTimezones(state) {
    return state.system_timezones;
  },
  userTimezone(state) {
    return state.user_timezone;
  },
  userTimezoneSetting(state) {
    return state.user_timezone_setting
  }
};

const actions = {
  [UPDATE_USER_TIMEZONE](context, payload) {
    return ApiService.put("api/v1/users/" + payload.userId, {
      system_timezone_code: payload.data.timezoneCode
    })
    .then(res => {
      const timezone = res.data.data.timezone;
      context.commit(SET_USER_TIMEZONE, timezone);
    })
    .catch(error => {
      Vue.$log.error(error);
      throw error;
    });
  },
  [UPDATE_USER_TIMEZONE_SETTING](context, payload) {
    return ApiService.put("api/v1/users/" + payload.userId, {
      system_timezone_setting_id: payload.data.timezoneSetting
    })
    .then(res => {
      const timezoneSetting = res.data.data.timezoneSetting;
      context.commit(SET_USER_TIMEZONE_SETTING, timezoneSetting);
    })
    .catch(error => {
      Vue.$log.error(error);
      throw error;
    });
  }
};

const mutations = {
  [SET_USER_TIMEZONE](state, timezone) {
    state.user_timezone = timezone;
  },
  [SET_USER_TIMEZONE_SETTING](state, timezoneSetting) {
    state.user_timezone_setting = timezoneSetting;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};