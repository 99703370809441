<template>
  <div class="w-100">
    <b-form-group
      :id="id + '-group'"
      :label="computedLabel"
      :label-sr-only="label == null"
      :label-for="id"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError }"
    >
      <vue-editor
        v-if="fillInTheBlanksEditor"
        :id="id"
        :name="id"
        :editorToolbar="computedToolbar"
        v-model="model.$model"
        :state="validateState()"
        :disabled="submissionStates.submitting === true || disabled"
        :placeholder="computedLabel"
      >
      </vue-editor>
      <vue-editor
        v-else
        :id="id"
        :name="id"
        :editorToolbar="computedToolbar"
        v-model="model.$model"
        :state="validateState()"
        :disabled="submissionStates.submitting === true || disabled"
        :placeholder="(model.$model != null && model.$model.length > 0) ? null : computedLabel"
      ></vue-editor>
    </b-form-group>
    <div
      v-if="error != null && model.$anyError"
      class="custom-validation-failure mb-7"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "InputGenericRichText",

  props: {
    id: { type: String, required: true },
    model: { type: Object, required: true },
    label: { type: String, required: false, default: null },
    error: { type: String, required: false, default: null },
    submissionStates: { type: Object, required: true },
    disabled: { type: Boolean, required: false, default: false }, //Ammend how this behaves
    fillInTheBlanksEditor: { type: Boolean, required: false, default: false },
    allowImages: { type: Boolean, required: false, default: false },
  },

  components: {
    VueEditor,
  },

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
  },
  computed: {
    computedToolbar: function () {
      return [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        this.allowImages ? ["link", "image"] : ["link"],
        ["clean"],
      ];
    },
    computedLabel: function () {
      if (this.label != null) {
        return this.model.$params.hasOwnProperty("required")
          ? `${this.label}*`
          : this.label;
      } else {
        return "";
      }
    },
  },
};
</script>
