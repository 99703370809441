import ApiService from "@/core/services/api.service";
import Vue from "vue";
import VuexHelpers from "@/modules/together-helpers/helpers/VuexHelpers";

//action types
export const POPULATE_PROJECT_SEGMENTATION_LIST = "populateProjectSegmentationList";
export const POPULATE_LEGACY_PROJECT_SEGMENTATION_LIST = "populateLegacyProjectSegmentationList";
export const CREATE_SEGMENTATION = "createSegmentation";
export const UPDATE_PROJECT_SEGMENTATION = "updateProjectSegmentation";
export const UPDATE_PROJECT_SEGMENTATION_VARIABLES = "updateProjectSegmentationVariables";
export const DELETE_PROJECT_SEGMENTATION = "deleteProjectSegmentation";
export const CLEAR_PROJECT_SEGMENTATION_LIST = "clearProjectSegmentationList";
export const CLEAR_PROJECT_SEGMENTATION_ERRORS = "clearProjectSegmentationErrors";

// mutation types
export const SET_PROJECT_SEGMENTATION_LIST = "setProjectSegmentationList";
export const SET_LEGACY_PROJECT_SEGMENTATION_LIST = "setLegacyProjectSegmentationList";
export const RESET_PROJECT_SEGMENTATION_LIST = "resetProjectSegmentationList";
export const SET_PROJECT_SEGMENTATION_ERRORS = "setProjectSegmentationErrors";
export const RESET_PROJECT_SEGMENTATION_ERRORS = "resetProjectSegmentationErrors";
export const ADD_NEW_SEGMENTATION = "addNewSegmentation";
export const UPDATE_SEGMENTATION = "updateSegmentation";
export const RESET_SEGMENTATION = "resetSegmentation";
export const REMOVE_SEGMENTATION = "removeSegmentation";


const state = {
  project_segmentation_list: [],
  legacy_project_segmentation_list: [],
  errors: {}
};

const getters = {
  projectSegmentations(state) {
    return state.project_segmentation_list;
  },
  legacyProjectSegmentations(state) {
    return state.legacy_project_segmentation_list;
  },
  projectSegmentationErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_SEGMENTATION_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/project_segmentations", { params: payload.params }
    )
      .then(res => {
        const projectSegmentations = res.data.data._embedded.project_segmentations;
        context.commit(SET_PROJECT_SEGMENTATION_LIST, projectSegmentations);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_LEGACY_PROJECT_SEGMENTATION_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/legacy_project_segmentations", { params: payload.params }
    )
      .then(res => {
        const projectSegmentations = res.data;
        context.commit(SET_LEGACY_PROJECT_SEGMENTATION_LIST, projectSegmentations);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_SEGMENTATION](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + '/project_segmentations', payload.data)
      .then(res => {
        context.commit(ADD_NEW_SEGMENTATION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_SEGMENTATION](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/project_segmentations/" + payload.segmentationId, payload.data)
      .then(res => {
        context.commit(UPDATE_SEGMENTATION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_SEGMENTATION_VARIABLES](context, payload) {
    let url = payload.url;
    return VuexHelpers.update("ProjectSegmentation", context, url, payload, false);
  },
  [DELETE_PROJECT_SEGMENTATION](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + "/project_segmentations/" + payload.segmentationId)
      .then(res => {
        context.commit(REMOVE_SEGMENTATION, payload.segmentationId);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENTATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_SEGMENTATION_LIST](context) {
    context.commit(RESET_PROJECT_SEGMENTATION_LIST);
  },
  [CLEAR_PROJECT_SEGMENTATION_ERRORS](context) {
    context.commit(RESET_PROJECT_SEGMENTATION_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_SEGMENTATION_LIST](state, project_segmentations) {
    state.project_segmentation_list = project_segmentations;
  },
  [SET_LEGACY_PROJECT_SEGMENTATION_LIST](state, legacy_project_segmentations) {
    state.legacy_project_segmentation_list = legacy_project_segmentations;
  },
  [RESET_PROJECT_SEGMENTATION_LIST](state) {
    state.project_segmentation_list = {};
  },
  [SET_PROJECT_SEGMENTATION_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_SEGMENTATION_ERRORS](state) {
    state.errors = {};
  },
  [ADD_NEW_SEGMENTATION](state, projectSegmentation) {
    projectSegmentation.published = parseInt(projectSegmentation.published);
    state.project_segmentation_list.push(projectSegmentation);
  },
  [UPDATE_SEGMENTATION](state, project_segmentation) {
    let newProjectSegmentationArray = [];
    newProjectSegmentationArray.push(project_segmentation);
    state.project_segmentation_list = state.project_segmentation_list.map(obj => newProjectSegmentationArray.find(o => o.id === obj.id) || obj);
  },
  [RESET_SEGMENTATION](state, project_segmentation) {
    VuexHelpers.reset(state, 'project_segmentation' ,project_segmentation);
  },
  [REMOVE_SEGMENTATION](state, projectSegmentationId) {
    state.project_segmentation_list = state.project_segmentation_list.filter(function( obj ) {
      return obj.id !== projectSegmentationId;
    });
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};