import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_TAG_LIST = "populateProjectTagList";
export const POPULATE_PROJECT_CUSTOM_TAG_LIST = "populateProjectCustomTagList";
export const POPULATE_LEGACY_PROJECT_CUSTOM_TAG_LIST = "populateLegacyProjectCustomTagList";
export const CLEAR_PROJECT_TAG = "clearProjectTag";
export const CREATE_PROJECT_TAG = "createProjectTag";
export const DELETE_PROJECT_TAG = "deleteProjectTag";
export const CLEAR_PROJECT_TAG_ERRORS =
  "clearProjectTagErrors";

// mutation types
export const SET_PROJECT_TAG_LIST = "setProjectTagList";
export const RESET_PROJECT_TAG = "resetProjectTag";
export const SET_PROJECT_CUSTOM_TAG_LIST = "setProjectCustomTagList";
export const SET_LEGACY_PROJECT_CUSTOM_TAG_LIST = "setLegacyProjectCustomTagList";
export const SET_ENGAGEMENT_TAG_LIST = "setEngagementTagList";
export const RESET_PROJECT_CUSTOM_TAG_LIST = "resetProjectCustomTagList";
export const ADD_NEW_PROJECT_TAG = "addNewProjectTag";
export const REMOVE_PROJECT_TAG = "removeProjectTag";
export const SET_PROJECT_TAG_ERRORS = "setProjectTagErrors";
export const RESET_PROJECT_TAG_ERRORS =
  "resetProjectTagErrors";

const state = {
  project_tag: {},
  project_tag_list: {},
  project_custom_tag_list: [],
  legacy_project_custom_tag_list: [],
  project_engagement_tags: null,
  errors: {}
};

const getters = {
  projectTag(state) {
    return state.project_tag;
  },
  projectTagList(state) {
    return state.project_tag_list;
  },
  projectCustomTagList(state) {
    return state.project_custom_tag_list;
  },
  legacyProjectCustomTagList(state) {
    return state.legacy_project_custom_tag_list;
  },
  projectEngagementTagList(state) {
    return state.project_engagement_tags;
  },
  projectTagErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_TAG_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_tags"
    )
      .then(res => {
        const projectTags = res.data.data._embedded.project_tags;
        context.commit(SET_PROJECT_TAG_LIST, projectTags);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_TAG_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_CUSTOM_TAG_LIST](context, payload) {
    return ApiService.get(
        "api/v1/projects/" +
          payload.projectId +
          "/custom_tags"
      )
        .then(res => {
          const customTags = res.data;
          context.commit(SET_PROJECT_CUSTOM_TAG_LIST, customTags);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_PROJECT_TAG_ERRORS, error.response.data);
          } else {
            Vue.$log.error(error.response);
          }
          throw error;
        });
  },
  [POPULATE_LEGACY_PROJECT_CUSTOM_TAG_LIST](context, payload) {
    return ApiService.get(
        "api/v1/projects/" +
          payload.projectId +
          "/legacy_custom_tags"
      )
        .then(res => {
          const customTags = res.data;
          context.commit(SET_LEGACY_PROJECT_CUSTOM_TAG_LIST, customTags);
        })
        .catch(error => {
          if (error.response) {
            context.commit(SET_PROJECT_TAG_ERRORS, error.response.data);
          } else {
            Vue.$log.error(error.response);
          }
          throw error;
        });
  },
  [CLEAR_PROJECT_TAG](context) {
    context.commit(CLEAR_PROJECT_TAG);
  },
  [CREATE_PROJECT_TAG](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/project_tags",
      payload.data
    )
      .then(res => {
        context.commit(ADD_NEW_PROJECT_TAG, res.data.data)
        return res.data.data
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_TAG_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_TAG](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/project_tags/" +
        payload.id
    )
      .then(res => {
        context.commit(REMOVE_PROJECT_TAG, payload.id);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_TAG_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_TAG_ERRORS](context) {
    context.commit(CLEAR_PROJECT_TAG_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_TAG_LIST](state, projectTags) {
    state.project_tag_list = projectTags;
  },
  [RESET_PROJECT_TAG](state) {
    state.project_tag = {};
  },
  [SET_PROJECT_CUSTOM_TAG_LIST](state, customTags) {
    state.project_custom_tag_list = customTags;
  },
  [SET_LEGACY_PROJECT_CUSTOM_TAG_LIST](state, customTags) {
    state.legacy_project_custom_tag_list = customTags;
  },
  [RESET_PROJECT_CUSTOM_TAG_LIST](state) {
    state.project_custom_tag_list = [];
  },
  [SET_ENGAGEMENT_TAG_LIST](state, engagementTags = null) {
    state.project_engagement_tags = engagementTags;
  },
  [CLEAR_PROJECT_TAG](state) {
    state.project_tag = {};
  },
  [ADD_NEW_PROJECT_TAG](state, projectTag) {
    state.project_tag_list.push(projectTag);
  },
  [REMOVE_PROJECT_TAG](state, projectTagId) {
    state.project_tag_list = state.project_tag_list.filter(function( obj ) {
      return obj.id !== projectTagId;
    });
  },
  [SET_PROJECT_TAG_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_TAG_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
