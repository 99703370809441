import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const TOGGLE_PUBLISHED_STATE = "projectWidgetToggle";
export const TOGGLE_SUSPENSION_STATE_BULK = "toggleSuspensionStateBulk";
export const CLEAR_PUBLISHING_ERRORS = "clearPublishingErrors";

// mutation types
export const SET_PUBLISHING_ERRORS = "setPublishingErrors";
export const RESET_PUBLISHING_ERRORS = "resetPublishingErrors";

const state = {
  errors: {},
};

const getters = {
  publishingErrors(state) {
    return state.errors;
  },
};

const actions = {
  [TOGGLE_PUBLISHED_STATE](context, payload) {
    return ApiService.put("api/v1/" + payload.uri + "/" + payload.id + "/" + payload.published)
      .then(() => {
        return true;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PUBLISHING_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [TOGGLE_SUSPENSION_STATE_BULK](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/user_suspension", payload)
      .then(() => {
        return true;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PUBLISHING_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PUBLISHING_ERRORS](context) {
    context.commit(RESET_PUBLISHING_ERRORS)
  }
};

const mutations = {
  [SET_PUBLISHING_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_PUBLISHING_ERRORS](state) {
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};