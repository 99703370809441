import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_PROFILES_LIST = "populateProjectProfilesList";
export const CLEAR_PROJECT_PROFILES_LIST = "clearProjectProfilesList";
export const CLEAR_PROJECT_PROFILES_ERRORS = "clearProjectProfilesErrors";

// mutation types
export const SET_PROJECT_PROFILES_LIST = "setProjectProfilesList";
export const RESET_PROJECT_PROFILES_LIST = "resetProjectProfilesList";
export const SET_PROJECT_PROFILES_ERRORS = "setProjectProfilesErrors";
export const RESET_PROJECT_PROFILES_ERRORS = "resetProjectProfilesErrors";

const state = {
  project_profiles_list: [],
  errors: {}
};

const getters = {
  projectProfiles(state) {
    return state.project_profiles_list;
  },
  projectProfilesErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_PROFILES_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_profiles", {
      params: payload.params
    })
    .then(res => {
      const projectProfiles = res.data.data._embedded.project_profiles;
      context.commit(SET_PROJECT_PROFILES_LIST, projectProfiles);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_PROFILES_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_PROJECT_PROFILES_LIST](context) {
    context.commit(RESET_PROJECT_PROFILES_LIST);
  },
  [CLEAR_PROJECT_PROFILES_ERRORS](context) {
    context.commit(RESET_PROJECT_PROFILES_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_PROFILES_LIST](state, project_profiles) {
    state.project_profiles_list = project_profiles;
  },
  [RESET_PROJECT_PROFILES_LIST](state) {
    state.project_profiles_list = {};
  },
  [SET_PROJECT_PROFILES_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_PROFILES_ERRORS](state) {
    state.errors = {};
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};