<template>
  <b-form-group
    :id="id + 'group'"
    :label="label"
    :label-for="id"
    :label-sr-only="labelSrOnly"
    class="mb-0"
  >
    <b-form-select
      :id="id"
      v-model="model.$model"
      class="form-control form-control-solid"
      :class="{'py-3' : computedSlim, 'py-4' : !computedSlim }"
    >
      <b-form-select-option
        value="null"
      >
        {{ placeholder }}
      </b-form-select-option>
      <template v-for="(group, i) in groups">
        <b-form-select-option-group
          :key="i"
          :label="group[groupLabel]"
        >
          <template v-for="(option) in options">
            <b-form-select-option
              :key="i + option[optionLabel]"
              :value="option.id"
              v-if="option[optionForeign] == group.id"
            >
              {{ option[optionLabel] }}
            </b-form-select-option>
          </template>
        </b-form-select-option-group>
      </template>
    </b-form-select>
  </b-form-group>
</template>

<script>
export default {
  name: "GenericNestedDropdown",

  props: [
    'id',
    'label',
    'model',
    'labelDisplay',
    'groups',
    'groupLabel',
    'options',
    'optionLabel',
    'optionForeign',
    'placeholder',
    'slim'
  ],

  computed: {
    labelSrOnly: function() {
      if (this.labelDisplay != undefined) {
        if (this.labelDisplay == "1") {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    computedSlim: function() {
      if (this.slim != undefined) {
        return this.slim;
      } else {
        return false;
      }
    }
  }
};
</script>