import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_COMMUNITY_OPTION_LIST = "populateProjectCommunityOptionList";
export const CLEAR_PROJECT_COMMUNITY_OPTION = "clearProjectCommunityOption";
export const CREATE_PROJECT_COMMUNITY_OPTION = "createProjectCommunityOption";
export const UPDATE_PROJECT_COMMUNITY_OPTION = "updateProjectCommunityOption";
export const DELETE_PROJECT_COMMUNITY_OPTION = "deleteProjectCommunityOption";
export const CLEAR_PROJECT_COMMUNITY_OPTION_ERRORS =
  "clearProjectCommunityOptionErrors";

// mutation types
export const SET_PROJECT_COMMUNITY_OPTION_LIST = "setProjectCommunityOptionList";
export const SET_PROJECT_COMMUNITY_OPTION = "setProjectCommunityOption";
export const RESET_PROJECT_COMMUNITY_OPTION = "resetProjectCommunityOption";
export const SET_PROJECT_COMMUNITY_OPTION_ERRORS = "setProjectCommunityOptionErrors";
export const RESET_PROJECT_COMMUNITY_OPTION_ERRORS =
  "resetProjectCommunityOptionErrors";

const state = {
  project_community_option: {},
  project_community_option_list: {},
  errors: {}
};

const getters = {
  projectCommunityOption(state) {
    return state.project_community_option;
  },
  projectCommunityOptionList(state) {
    return state.project_community_option_list;
  },
  projectCommunityOptionErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_COMMUNITY_OPTION_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_misc_options"
    )
      .then(res => {
        const projectCommunityOptions = res.data.data._embedded.project_misc_options;
        context.commit(SET_PROJECT_COMMUNITY_OPTION_LIST, projectCommunityOptions);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNITY_OPTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_COMMUNITY_OPTION](context) {
    context.commit(CLEAR_PROJECT_COMMUNITY_OPTION);
  },
  [CREATE_PROJECT_COMMUNITY_OPTION](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/project_misc_options",
      payload.data
    )
      .then(res => {
        const projectCommunityOption = res.data.data;
        context.commit(SET_PROJECT_COMMUNITY_OPTION, projectCommunityOption);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNITY_OPTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_COMMUNITY_OPTION](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/project_misc_options/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(SET_PROJECT_COMMUNITY_OPTION, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNITY_OPTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_COMMUNITY_OPTION](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/project_misc_options/" +
        payload.id
    )
      .then(() => {
        context.commit(CLEAR_PROJECT_COMMUNITY_OPTION);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNITY_OPTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_COMMUNITY_OPTION_ERRORS](context) {
    context.commit(CLEAR_PROJECT_COMMUNITY_OPTION_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_COMMUNITY_OPTION](state, projectCommunityOption) {
    state.project_community_option = projectCommunityOption;
  },
  [SET_PROJECT_COMMUNITY_OPTION_LIST](state, projectCommunityOptions) {
    state.project_community_option_list = projectCommunityOptions;
  },
  [CLEAR_PROJECT_COMMUNITY_OPTION](state) {
    state.project_community_option = {};
  },
  [SET_PROJECT_COMMUNITY_OPTION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_COMMUNITY_OPTION_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
