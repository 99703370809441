<template>
  <tr
    v-if="editIndex != index"
  >
    <td></td>
    <td class="px-2 py-3 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
        @click="enabledRowEdit()"
      >
        {{ data.choice }}
      </a>
    </td>
    <td class="px-2 py-3 pl-7 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
        @click="enabledRowEdit()"
      >
        {{ segmentName }}
      </a>
    </td>
    <td class="px-2 py-3 d-table-cell text-right" style="width:70px">
      <a
        href="javascript:void(0)"
        class="text-danger text-hover-danger font-size-sm hidden-delete"
        @click="emitRemoval()"
      >
        {{ $t('GENERAL.TEXT.DELETE') }}
      </a>
    </td>
  </tr>
  <tr
    v-else
    class="form-inline-row"
  >
    <td></td>
    <td colspan="1" class="px-2 py-3 ml-0 mr-2 d-table-cell">
      <div>
        <b-form inline v-on:submit.prevent="emitUpdate()">
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0 form-control-solid w-100 text-left"
            :class="{ 'is-invalid' : input_error == 1 }"
            :placeholder="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.CHOICE')"
            v-model="editChoice.choice"
          ></b-input>
        </b-form>
      </div>
    </td>
    <td class="px-2">
      <InputGenericNestedDropdown
        id="input-places-in"
        :model="$v.editChoice.project_segment_id"
        :label="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.PLACES')"
        :groups="projectSegmentations"
        :options="projectSegments"
        groupLabel="name"
        optionLabel="name"
        :slim="true"
        optionForeign="project_segmentation_id"
        labelDisplay="0"
        :placeholder="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.PLACES_PLACEHOLDER')"
      >
      </InputGenericNestedDropdown>
    </td>
    <td class="px-2 pt-4 text-right">
      <b-button
        variant="outline-primary"
        class="py-1 pl-3 pr-2 mr-2"
        @click="emitUpdate()"
      >
        <i class="fas fa-check font-size-sm"></i>
      </b-button>
      <b-button
        variant="outline-danger"
        class="py-1 pl-3 pr-2"
        @click="disableRowEdit()"
      >
        <i class="fas fa-times font-size-sm"></i>
      </b-button>
    </td>
  </tr>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import InputGenericNestedDropdown from "@/view/components/form-inputs/GenericNestedDropdown";

export default {
  mixins: [validationMixin],

  name: "ExtendedProfileQuestionChoiceRow",

  props: [
    'data',
    'index',
    'selectedIndex',
    'editIndex',
    'currentChoices'
  ],

  data() {
    return {
      input_error: 0,
      editChoice: {
        choice: null,
        project_segment_id: null,
        segmentName: null
      }
    };
  },

  validations: {
    pre_populate_toggle: {},
    editChoice: {
      choice: {},
      project_segment_id: {}
    }
  },

  components: {
    InputGenericNestedDropdown
  },

  methods: {
    enabledRowEdit() {
      this.editChoice = {
        choice: this.data.choice,
        project_segment_id: this.data.project_segment_id
      };
      this.$emit("enableRowEdit",this.index);
    },
    disableRowEdit() {
      this.resetEdit();
      this.$emit('disableRowEdit');
    },
    emitRemoval() {
      this.$emit('choice_removed',this.index);
    },
    emitUpdate() {
      if (this.editChoice.choice.length > 0) {
        if (this.otherChoices.filter(x => x.choice.toLowerCase() === this.editChoice.choice.toLowerCase()).length == 0) {
          if (this.editChoice.project_segment_id != null) {
            this.editChoice.segmentName = this.projectSegments.filter(x => x.id === this.editChoice.project_segment_id)[0].name;
          }
          let payload = {
            index: this.index,
            choice: this.editChoice
          }
          this.$emit('choice_updated',payload);
          this.$emit('disableRowEdit');   
        } else {
          this.input_error = 1;
        }
      } else {
        this.$emit('choice_removed',this.index);
      }
    },
    resetEdit() {
      this.editChoice = {
        choice: null,
        project_segment_id: null
      };
    }
  },

  computed: {
    ...mapGetters([
      "projectSegmentations",
      "projectSegments",
    ]),

    otherChoices: function() {
      const otherChoices = this.currentChoices.filter(i => i.choice != this.data.choice);

      return otherChoices;
    },

    segmentName: function() {
      if (this.data.segmentName != undefined) {
        return this.data.segmentName;
      } else {
        return null;
      }
    },
  }
}
</script>