<template>
  <div class="pl-0 pr-0" :class="fluid == true ? 'col-xl-12' : 'col-xl-8'">
    <div class="card card-custom gutter-b mb-0">
      <b-spinner
        v-if="loadingComplete == false"
        variant="primary"
        label="Spinning"
        class="card-loader"
      >
      </b-spinner>
      <div v-if="loadingComplete" class="card-header card-header-tabs-line">
        <InformativeLabel
          :managementType="managementType"
          :type="pageType"
          :subject="computedSubject"
          :status="status"
          :tabCount="computedTabCount"
          :isActivity="isActivity"
        >
        </InformativeLabel>
        <div v-if="computedTabCount > 0" class="card-toolbar">
          <ul
            class="nav nav-tabs nav-bold nav-tabs-line"
            id="myTab"
            role="tablist"
          >
            <li v-for="tab in tabs" :key="tab.id" class="nav-item">
              <a
                v-if="tab.show == true || tab.show == null"
                class="nav-link"
                :class="{ active: currentTab == tab.id, disabled: lastTab == false && managementType == 'post'}"
                @click="changeTab(tab.id)"
                href="javascript:void(0)"
              >
                <span class="nav-text">
                  {{ tab.name }}
                  <i
                    v-if="tab.errors"
                    class="fas fa-exclamation-circle text-danger ml-2"
                  >
                  </i>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="loadingComplete" class="card-body">
        <form
          class="form mt-5"
          id="topform_form"
          @submit.prevent="submit"
        >
          <div
            v-for="tab in tabs"
            :key="tab.id"
            class="col-xl-12"
            :id="'topform_tab_' + tab.id"
            v-show="tab.id == currentTab"
          >
            <ErrorBox
              v-if="currentTab == 1"
              :errors="errors"
              @clear_errors="clearErrors"
            >
            </ErrorBox>
            <template v-for="(section, sectionIndex) in tab.sections">
              <div
                :key="sectionIndex"
                v-if="section.show == true || section.show == null"
              >
                <h3
                  v-if="section.title != null"
                  class="font-size-lg text-dark font-weight-bold mb-6 mt-10 px-0"
                >
                  {{ section.title }}
                </h3>
                <template v-for="(row, rowIndex) in section.rows">
                  <div
                    :key="sectionIndex + '-' + tab.id + '-' + rowIndex"
                    class="row d-flex justify-content-start mb-10 px-0"
                    :class="row.width == 'fluid' ? 'col-xl-12' : 'col-xl-8'"
                    v-if="row.show == true || row.show == null"
                  >
                    <template
                      v-for="(component, componentIndex) in row.components"
                    >
                      <component
                        v-if="component.show == true || component.show == null"
                        :is="component.name"
                        v-bind="component.properties"
                        v-on="component.on"
                        :key="
                          tab.id +
                            '-' +
                            sectionIndex +
                            '-' +
                            rowIndex +
                            '-' +
                            componentIndex
                        "
                      ></component>
                    </template>
                  </div>
                </template>
                <div
                  :key="tab.id + '-' + sectionIndex"
                  v-if="tab.sections[sectionIndex + 1] != null"
                  class="col-xl-8 separator separator-dashed my-7"
                ></div>
              </div>
            </template>
          </div>
        </form>
      </div>
      <div
        v-if="loadingComplete"
        class="card-footer d-flex justify-content-between my-0 py-4"
      >
        <router-link
          v-if="modal == false"
          tag="a"
          class="btn btn-secondary mr-2" 
          :to="parentPage"
        >
          {{ $t("GENERAL.BUTTONS.CANCEL") }}
        </router-link>
        <a
          v-else
          href="javascript:void(0)"
          class="btn btn-secondary mr-2"
          @click="$emit('cancel')"
        >
          {{ $t("GENERAL.BUTTONS.CANCEL") }}
        </a>
        <div class="justify-content-end">
          <a
            v-if="managementType == 'post' && lastTab == false"
            href="javascript:void(0)"
            class="btn btn-primary mr-2"
            @click="onNext"
          >
            {{ $t("GENERAL.BUTTONS.NEXT") }}
          </a>
          <template v-for="(action, actionIndex) in additionalActions">
            <a
              v-if="managementType == 'update' || managementType == 'duplicate' || lastTab == true"
              :key="actionIndex"
              href="javascript:void(0)"
              class="btn btn-primary mr-2"
              @click="submitAction(action.emit)"
            >
              {{ action.name }}
            </a>
          </template>
          <a
            v-if="managementType == 'update' || managementType == 'duplicate' || lastTab == true"
            href="javascript:void(0)"
            class="btn btn-primary mr-2"
            @click="submit"
          >
            {{ computedSaveButton }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBox from "@/view/components/ErrorBox";
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import InputGeneric from "@/view/components/form-inputs/GenericInput";
import InputGenericRadioOption from "@/modules/together-helpers/components/generic-inputs/GenericRadioOption";
import InputGenericTextArea from "@/modules/together-helpers/components/generic-inputs/GenericTextArea";
import InputGenericNestedCheckbox from "@/view/components/form-inputs/GenericNestedCheckbox";
import InputGenericDropdown from "@/modules/together-helpers/components/generic-inputs/GenericDropdown";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";
import InputGenericDatePicker from "@/modules/together-helpers/components/generic-inputs/GenericDatePicker";
import InformativeLabel from "@/view/components/InformativeLabel";
import InputGenericRichText from "@/view/components/form-inputs/GenericRichText";
import ExtendedProfileQuestionTable from "@/view/content/projects/ExtendedProfileQuestionTable";
import InputGenericGroup from "@/view/components/form-inputs/GenericInputGroup";
import InputPageTypeRadioOption from "@/view/components/form-inputs/PageTypeRadioOption";
import InputGenericCheckboxGroup from "@/modules/together-helpers/components/generic-inputs/GenericCheckboxGroup";
import InputActivityTitle from "@/view/components/form-inputs/ActivityTitle";
import InputActivityNotificationSettings from "@/view/components/form-inputs/ActivityNotificationSettings";
import InputActivityVoteNotificationSettings from "@/view/components/form-inputs/ActivityVoteNotificationSettings";
import InputActivityQuestionChoices from "@/view/components/form-inputs/ActivityQuestionChoices";
import InputGenericSelect from "@/view/components/form-inputs/GenericSelect";
import InputActivityQuestions from "@/view/components/form-inputs/ActivityQuestions.vue";
import InputActivityCategories from "@/view/components/form-inputs/ActivityCategories";
import fitbEditor from "@/modules/together-helpers/components/specialised-inputs/fitbEditor";
import ActivityQuestionSettings from "@/view/components/form-inputs/ActivityQuestionSettings";
import RegistrationOptions from "@/view/components/form-inputs/RegistrationOptions";
import EventsTable from "@/view/components/tables/EventsTable";
import markupPins from "@/modules/together-helpers/components/specialised-inputs/markupPins";

export default {
  name: "Topform",

  props: {
    tabs: {
      required: true,
      type: Array
    },
    managementType: {
      required: true,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    errors: {
      required: true,
      type: Object
    },
    submissionStates: {
      required: true,
      type: Object
    },
    parentPage: {
      required: true,
      type: String
    },
    modal: {
      required: false,
      type: Boolean,
      default: false
    },
    pageType: {
      required: true,
      type: String
    },
    status: {
      required: false,
      type: String
    },
    additionalActions: {
      required: false,
      type: Array
    },
    validation: {
      required: true,
      type: Object
    },
    loadingComplete: {
      required: true,
      type: Boolean
    },
    saveOverride: {
      required: false,
      type: String
    },
    fluid: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      currentTab: 1,
      lastTab: true
    };
  },

  components: {
    ErrorBox,
    InputGenericCheckbox,
    InputGeneric,
    InputGenericRadioOption,
    InputGenericTextArea,
    InputGenericNestedCheckbox,
    InputGenericDropdown,
    IntegratedDropzone,
    InputGenericDatePicker,
    InformativeLabel,
    InputGenericRichText,
    ExtendedProfileQuestionTable,
    InputGenericGroup,
    InputPageTypeRadioOption,
    InputGenericCheckboxGroup,
    InputActivityTitle,
    InputActivityNotificationSettings,
    InputActivityVoteNotificationSettings,
    InputActivityQuestionChoices,
    InputGenericSelect,
    InputActivityQuestions,
    InputActivityCategories,
    fitbEditor,
    ActivityQuestionSettings,
    RegistrationOptions,
    EventsTable,
    markupPins
  },

  mounted() {
    this.clearErrors();
    // here be dragons - we could explore clearing data inside topform, but I'm not sure how to dynamically call an action, it doesnt work if I import it since it has to be used somewhere.
    // could be done in a large if statement but that would be worse than the current setup.
    if (this.managementType == "post") {
      this.$emit("clear_existing_data");
    };
  },

  methods: {
    submit() {
      this.validation.$touch();
      if (this.validation.$anyError || this.errors.length > 0) {
        this.scrollTop();
      } else {
        this.scrollTop();
        this.$emit('submit');
      }
    },
    submitAction(action) {
      this.validation.$touch();
      if (this.validation.$anyError || this.errors.length > 0) {
        this.scrollTop();
      } else {
        this.scrollTop();
        this.$emit(action);
      }
    },
    clearErrors: function() {
      this.$emit("clear_errors");
    },
    scrollTop: function() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      }) 
    },
    onNext: function() {
      this.currentTab++;
      this.scrollTop();

      if (this.currentTab == this.computedTabCount) {
        this.lastTab = true;
      };
    },
    changeTab(tab) {
      if (this.managementType != 'post' || this.lastTab == true) {
        this.currentTab = tab;
        this.$emit('on-tab-changed',tab);
      };
    }
  },

  watch: {
    errors: {
      handler() {
        if (Object.keys(this.errors).length > 0) {
          this.currentTab = 1;
          this.scrollTop();
        };
      }
    },
    computedTabCount: {
      handler() {
        if (this.computedTabCount <= this.currentTab) {
          this.lastTab = true;
        } else {
          this.lastTab = false;
        };
      },
      immediate: true
    },
    loadingComplete: {
      handler() {
        if (this.loadingComplete == false) {
          this.currentTab = 1;
          this.lastTab = false;
        }
      }
    }
  },

  computed: {
    computedSubject: function() {
      if (this.name != undefined) {
        return this.name;
      } else {
        return null;
      };
    },
    computedTabCount: function() {
      let tabCount = 0;

      this.tabs.forEach(tab => {
        if (tab.show == true || tab.show == null) {
          tabCount++;
        }
      });

      return tabCount;
    },
    isActivity: function() {
      let activityTypes = ["blog", "diary", "discussion", "survey", "picture_book", "ideastorm", "external", "markup (legacy)", "poll"];

      if (activityTypes.includes(this.pageType)) {
        return true;
      } else {
        return false;
      };
    },
    computedSaveButton: function() {
      if (this.saveOverride != undefined) {
        if (this.saveOverride != null) {
          return this.saveOverride
        } else {
          return `${this.$t("GENERAL.BUTTONS.SAVE")}`;
        } 
      } else {
        return `${this.$t("GENERAL.BUTTONS.SAVE")}`;
      }
    },
    computedRowWidth: function() {
      let idx = this.currentTab - 1;

      if (this.loadingComplete) {
        if (this.tabs[idx].topformWidth != undefined) {
          if (this.tabs[idx].topformWidth == "fluid") {
            return "col-xl-12";
          } else {
            return "col-xl-8";
          };
        } else {
          return "col-xl-8";
        };
      } else {
        return "col-xl-8";
      };
    }
  }
}
</script>
