import VuexHelpers from "../../helpers/VuexHelpers";

// action types
export const CREATE_PROJECT_TEMPLATE = "createProjectTemplate";
export const UPDATE_PROJECT_TEMPLATE = "updateProjectTemplate";
export const DELETE_PROJECT_TEMPLATE = "deleteProjectTemplate";

// mutation types
export const SET_PROJECT_TEMPLATE_LIST   = "setProjectTemplateList";
export const SET_PROJECT_TEMPLATE_INFO   = "setProjectTemplateInfo";
export const SET_PROJECT_TEMPLATE_ERRORS = "setProjectTemplateErrors";
export const ADD_PROJECT_TEMPLATE        = "addProjectTemplate";
export const RESET_PROJECT_TEMPLATE      = "resetProjectTemplate";
export const STRIP_PROJECT_TEMPLATE      = "stripProjectTemplate";

const state = {
  project_template_list: [],
  project_template_info: null,
  project_template_errors: null,
};

const getters = {
  projectTemplateList(state) {
    return state.project_template_list;
  },
  projectTemplateInfo(state) {
    return state.project_template_info;
  },
  projectTemplateErrors(state) {
    return state.project_template_errors;
  },

};

const actions = {
  [CREATE_PROJECT_TEMPLATE](context, payload) {
    let url = payload.url;
    return VuexHelpers.create("ProjectTemplate", context, url, payload);
  },
  [UPDATE_PROJECT_TEMPLATE](context, payload) {
    let url = payload.url;
    return VuexHelpers.update("ProjectTemplate", context, url, payload);
  },
  [DELETE_PROJECT_TEMPLATE](context, payload) {
    let url = payload.url;
    return VuexHelpers.delete("ProjectTemplate", context, url, payload.projectTemplateId);
  },
}

const mutations = {
  [SET_PROJECT_TEMPLATE_LIST](state, project_template_list) {
    state.project_template_list = project_template_list;
  },
  [SET_PROJECT_TEMPLATE_INFO](state, project_template_info) {
    state.project_template_info = project_template_info;
  },
  [SET_PROJECT_TEMPLATE_ERRORS](state, errors = null) {
    state.project_template_errors = errors;
  },
  [ADD_PROJECT_TEMPLATE](state, project_template_info) {
    state.project_template_list.push(project_template_info);
  },
  [RESET_PROJECT_TEMPLATE](state, updatedProjectTemplate) {
    VuexHelpers.reset(state, 'project_template', updatedProjectTemplate);
  },
  [STRIP_PROJECT_TEMPLATE](state, removedProjectTemplateId) {
    VuexHelpers.strip(state, 'project_template', removedProjectTemplateId);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};