import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_DIARY_QUESTION_CHOICE_LIST = "populateDiaryQuestionChoiceList";
export const CLEAR_DIARY_QUESTION_CHOICE_LIST = "clearDiaryQuestionChoiceList";
export const CLEAR_DIARY_QUESTION_CHOICE_ERRORS = "clearDiaryQuestionChoiceErrors";

// mutation types
export const SET_DIARY_QUESTION_CHOICE_LIST = "setDiaryQuestionChoiceList";
export const RESET_DIARY_QUESTION_CHOICE_LIST = "resetDiaryQuestionChoiceList";
export const SET_DIARY_QUESTION_CHOICE_ERRORS = "setDiaryQuestionChoiceErrors";
export const RESET_DIARY_QUESTION_CHOICE_ERRORS = "resetDiaryQuestionChoiceErrors";

const state = {
  diary_question_choice_errors: {},
  diary_question_choice_list: [],
  diary_question_choice_info: []
};

const getters = {
  diaryQuestionChoiceErrors(state) {
    return state.diary_question_choice_errors;
  },
  diaryQuestionChoiceList(state) {
    return state.diary_question_choice_list;
  },
  diaryQuestionChoiceInfo(state) {
    return state.diary_question_choice_info;
  },
};

const actions = {
  [POPULATE_DIARY_QUESTION_CHOICE_LIST](context, payload) {
    return ApiService.get("api/v1/activity_diary_questions/" + payload.diaryQuestionId + "/diary_question_choices", {
      params: payload.params
    }).then((res) => {
      const diaryQuestionChoices = res.data.data._embedded.diary_question_choices;
      context.commit(SET_DIARY_QUESTION_CHOICE_LIST, diaryQuestionChoices);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_DIARY_QUESTION_CHOICE_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CLEAR_DIARY_QUESTION_CHOICE_LIST](context) {
    context.commit(RESET_DIARY_QUESTION_CHOICE_LIST);
  },
  [CLEAR_DIARY_QUESTION_CHOICE_ERRORS](context) {
    context.commit(RESET_DIARY_QUESTION_CHOICE_ERRORS);
  }
}

const mutations = {
  [SET_DIARY_QUESTION_CHOICE_LIST](state, diaryQuestionChoices) {
    state.diary_question_choice_list = diaryQuestionChoices;
  },
  [RESET_DIARY_QUESTION_CHOICE_LIST](state) {
    state.diary_question_choice_list = [];
  },
  [SET_DIARY_QUESTION_CHOICE_ERRORS](state, errors) {
    state.diary_question_choice_errors = errors;
  },
  [RESET_DIARY_QUESTION_CHOICE_ERRORS](state) {
    state.diary_question_choice_errors = {};
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};