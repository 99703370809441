import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_COMMUNICATIONS_LIST = "populateProjectCommunicationsList";
export const POPULATE_PROJECT_COMMUNICATIONS_TEMPLATES = "populateProjectCommunicationsTemplates";
export const POPULATE_PROJECT_COMMUNICATIONS_VARIABLES = "populateProjectCommunicationsVariables";
export const POPULATE_PROJECT_COMMUNICATIONS_TEMPLATE_INFO  = "populateProjectCommunicationsTemplateInfo";
export const POPULATE_PROJECT_COMMUNICATION_INFO = "populateProjectCommunicationInfo";
export const CLEAR_PROJECT_COMMUNICATIONS_LIST = "clearProjectCommunicationsList";
export const CLEAR_PROJECT_COMMUNICATION_INFO = "clearProjectCommunicationInfo";
export const CREATE_PROJECT_COMMUNICATION = "createProjectCommunication";
export const UPDATE_PROJECT_COMMUNICATION = "updateProjectCommunication";
export const DELETE_PROJECT_COMMUNICATION = "deleteProjectCommunication";
export const CLEAR_PROJECT_COMMUNICATION_ERRORS = "clearProjectCommunicationErrors";

// mutation types
export const SET_PROJECT_COMMUNICATION_LIST = "setProjectCommunicationList";
export const SET_PROJECT_COMMUNICATIONS_TEMPLATES_LIST = "setProjectCommunicationsTemplatesList";
export const SET_PROJECT_COMMUNICATIONS_VARIABLES_LIST = "setProjectCommunicationsVariablesList";
export const RESET_PROJECT_COMMUNICATION_LIST = "resetProjectCommunicationList";
export const SET_PROJECT_COMMUNICATION_INFO = "setProjectCommunicationInfo";
export const RESET_PROJECT_COMMUNICATION_INFO = "resetProjectCommunicationInfo";
export const ADD_TO_PROJECT_COMMUNICATION_LIST = "addToProjectCommunicationList";
export const UPDATE_PROJECT_COMMUNICATION_LIST = "updateProjectCommunicationList";
export const REMOVE_FROM_PROJECT_COMMUNICATION_LIST = "removeFromProjectCommunicationList";
export const SET_PROJECT_COMMUNICATION_ERRORS = "setProjectCommunicationErrors";
export const RESET_PROJECT_COMMUNICATION_ERRORS = "resetProjectCommunicationErrors";
export const SET_PROJECT_COMMUNICATION_PRELOAD = "setProjectCommunicationPreload";

const state = {
  project_communication_list: [],
  project_communication_template_list: [],
  project_communication_variable_list: [],
  project_communication_info: {},
  project_communication_preload: {},
  errors: {}
};

const getters = {
  projectCommunicationList(state) {
    return state.project_communication_list;
  },
  projectCommunicationTemplateList(state) {
    return state.project_communication_template_list;
  },
  projectCommunicationVariableList(state) {
    return state.project_communication_variable_list;
  },
  projectCommunicationInfo(state) {
    return state.project_communication_info;
  },
  projectCommunicationPreload(state) {
    return state.project_communication_preload;
  },
  projectCommunicationErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_COMMUNICATIONS_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_emails", { params: payload.params })
      .then(res => {
        const projectCommunications = res.data.data._embedded.project_emails;
        context.commit(SET_PROJECT_COMMUNICATION_LIST, projectCommunications);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_COMMUNICATIONS_TEMPLATES](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_email_templates", { params: payload.params })
      .then(res => {
        const projectCommunicationsTemplates = res.data.data._embedded.project_email_templates;
        context.commit(SET_PROJECT_COMMUNICATIONS_TEMPLATES_LIST, projectCommunicationsTemplates);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_COMMUNICATIONS_TEMPLATE_INFO](context, payload) {
    return ApiService.get(`api/v1/projects/${payload.projectId}/project_email_templates/${payload.id}`, { params: payload.params })
      .then(res => {
        return res.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_COMMUNICATIONS_VARIABLES](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_email_variables", { params: payload.params })
      .then(res => {
        const projectCommunicationsVariables = res.data.data._embedded.project_email_variables;
        context.commit(SET_PROJECT_COMMUNICATIONS_VARIABLES_LIST, projectCommunicationsVariables);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_COMMUNICATION_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_emails/" + payload.id, { params: payload.params })
      .then(res => {
        const projectCommunication = res.data.data;
        context.commit(SET_PROJECT_COMMUNICATION_INFO, projectCommunication);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_COMMUNICATIONS_LIST](context) {
    context.commit(RESET_PROJECT_COMMUNICATION_LIST);
  },
  [CLEAR_PROJECT_COMMUNICATION_INFO](context) {
    context.commit(RESET_PROJECT_COMMUNICATION_INFO);
  },
  [CREATE_PROJECT_COMMUNICATION](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/project_emails", payload.data)
      .then(res => {
        const projectCommunication = res.data.data;
        context.commit(ADD_TO_PROJECT_COMMUNICATION_LIST, projectCommunication);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_COMMUNICATION](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/project_emails/" + payload.id, payload.data)
      .then(res => {
        const projectCommunication = res.data.data;
        context.commit(UPDATE_PROJECT_COMMUNICATION_LIST, projectCommunication);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_COMMUNICATION](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + '/project_emails/' + payload.id)
      .then(res => {
        context.commit(REMOVE_FROM_PROJECT_COMMUNICATION_LIST, payload.id);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_COMMUNICATION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_COMMUNICATION_ERRORS](context) {
    context.commit(RESET_PROJECT_COMMUNICATION_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_COMMUNICATION_LIST](state, projectCommunications) {
    state.project_communication_list = projectCommunications;
  },
  [SET_PROJECT_COMMUNICATIONS_TEMPLATES_LIST](state, projectCommunicationsTemplates) {
    state.project_communication_template_list = projectCommunicationsTemplates;
  },
  [SET_PROJECT_COMMUNICATIONS_VARIABLES_LIST](state, projectCommunicationsVariables) {
    state.project_communication_variable_list = projectCommunicationsVariables;
  },
  [RESET_PROJECT_COMMUNICATION_LIST](state) {
    state.project_communication_list = [];
  },
  [SET_PROJECT_COMMUNICATION_INFO](state, projectCommunication) {
    state.project_communication_info = projectCommunication;
  },
  [RESET_PROJECT_COMMUNICATION_INFO](state) {
    state.project_communication_info = {};
  },
  [ADD_TO_PROJECT_COMMUNICATION_LIST](state, projectCommunication) {
    state.project_communication_list = state.project_communication_list.unshift(projectCommunication);
  },
  [UPDATE_PROJECT_COMMUNICATION_LIST](state, projectCommunication) {
    let newProjectCommunicationArray = [];
    newProjectCommunicationArray.push(projectCommunication);
    state.project_communication_list = state.project_communication_list.map(obj => newProjectCommunicationArray.find(o => o.id === obj.id) || obj);
  },
  [REMOVE_FROM_PROJECT_COMMUNICATION_LIST](state, projectCommunicationId) {
    state.project_communication_list = state.project_communication_list.filter(function( obj ) {
      return obj.id !== projectCommunicationId;
    });
  },
  [SET_PROJECT_COMMUNICATION_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_COMMUNICATION_ERRORS](state) {
    state.errors = {};
  },
  [SET_PROJECT_COMMUNICATION_PRELOAD](state, preload = {}) {
    state.project_communication_preload = preload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};