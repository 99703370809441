<template>
  <b-modal
    ref="modal"
    size="lg"
  >
    <template #modal-title>
      {{ $t('MODALS.TITLE.INSTRUCTIONS') }}
    </template>

    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary mr-3"
          @click="$refs.modal.hide()"
        >
          {{ $t('GENERAL.BUTTONS.CANCEL') }}
        </a>

        <a
          href="javascript:void(0)"
          class="btn btn-primary"
          @click="onCopyInstructions()"
        >
          Copy
        </a>
      </div>
    </template>

    <div class="p-5">
      <div
        v-if="typeof baseUrl == 'string' && baseUrl.length > 0"
        ref="content"
        class="form-control h-100"
        style="overflow: auto !important"
      >
        <p>
          {{ 
            `${(type == 2) ? 'Anonymous Registration' : 'On The Fly Registration'} /
            ${(instructionUrl == 'ts_url') ? 'Toluna Sample' : 'My Own Sample'}`
          }}
        </p>

        <p class="pt-5">
          <span class="font-weight-bold d-block">URL:</span>
          <span class="d-block">{{ baseUrl }}</span>          
        </p>

        <div class="pt-5">
          <span class="font-weight-bold d-block">Variables:</span>
          <p
            v-for="(variable, v) in [{short: 'extId', name: 'External ID', long: 'extId', description: 'External ID of participant'}].concat(enabledVariables)"
            :key="v"
            class="d-block mb-0"
          >
            {{ `{` + variable.long + `} (${variable.name}) => ${variable.description}`}}
          </p>
        </div>
      </div>      
    </div>

  </b-modal>
</template>

<script>
import Toast from "@/core/classes/Toast.js";

export default {
  name: "VariablesInstructionsModal",

  props: {
    instructionUrl:   { type: String, required: false, default: null },
    enabledVariables: { type: Array, required: true },
    urls:             { type: Object, required: true },
    type:             { type: Number, required: true }
  },

  data() {
    return {
      toast: new Toast()
    }
  },

  methods: {
    onCopyInstructions: function() {
      const range = document.createRange();
      range.selectNode(this.$refs.content);

      // Select the text content of the div
      window.getSelection().removeAllRanges(); // Clear previous selections
      window.getSelection().addRange(range);

      // Copy the selected text to the clipboard
      document.execCommand('copy');

      // Deselect the text (optional)
      window.getSelection().removeAllRanges();

      this.toast.notify(this, `Integrator instructions copied to clipboard`, `Instructions Copied`, 'success');

      this.$refs.modal.hide();
    }
  },

  computed: {
    baseUrl: function() {
      return this.urls[this.instructionUrl];
    }
  }
}
</script>