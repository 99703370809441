<template>
  <div
    class="mb-7 pt-3 mx-4 hex-select"
  >
    <hr class="mb-5 mt-2" />
    <template v-for="(pin, p) in model.$model">
      <div
        :key="pin.system_markup_pin_type_id"
      >
        <div
          class="d-flex justify-content-between"
        >

          <!--START: Icon Select-->
          <div class="col-2 text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="d-flex justify-content-center"
                  style="cursor: pointer"
                  v-bind="attrs"
                  v-on="on"
                >
                  <MarkupPin
                    style="width:45px; height:45px; background-position: center; background-repeat: no-repeat; background-size: cover"
                    :pinIndex="pin.system_markup_pin_type_id"
                  />                  
                </div>
                <a
                  href="javascript:void(0)"
                  style="font-size:.825rem"
                  v-bind="attrs"
                  v-on="on"
                >
                  Change Icon
                </a>
              </template>
              <v-list style="overflow: hidden !important; max-width: 310px">
                <template
                  v-for="(availableIcon) in availablePinTypes"
                >
                  <MarkupPin
                    class="d-inline-block col-2 fake-button"
                    style="width:30px; height:30px; background-position: center; background-repeat: no-repeat; background-size: cover; cursor: pointer"
                    :key="availableIcon"
                    :pinIndex="availableIcon"
                    @pin-selected="editPin(availableIcon, p)"
                  /> 
                </template>    
              </v-list>

            </v-menu>
          </div>
          <!--END: Icon Select-->

          <div class="col-10">
            <!--START: Label Select-->
            <label
              :for="`pin-label-input-${p}`"
              style="font-size:.925rem"
              class="fw-semibold pb-1"
            >
              Label
            </label>
            <div class="d-flex justify-content-start align-items-center">
              <input
                :id="`pin-label-input-${p}`"
                v-model="model.$model[p].label"
                class="form-control form-control-solid"
                style="height:30px"
              />

              <a
                href="javascript:void(0)"
                @click="removePin(pin.system_markup_pin_type_id)"
              >
                <i
                  v-if="model.$model.length > 1"
                  class="fa fa-times ml-5 text-danger"
                  
                />
              </a>
            </div>
            <!--END: Label Select-->

            <!--START: Pin Count Select-->
            <div class="pt-3">
              <label
                class="fw-semibold pb-2"
                style="font-size:.925rem"
              >
                Number of pins allowed
              </label>
              <div class="d-flex justify-content-start align-items-center">
                <!--START: Minimum Pin Select-->
                <div class="d-flex justify-content-start align-items-center mr-3">
                  <label
                    :for="`pin-minimum-input-${p}`"
                    class="fw-semibold mr-1 mb-0"
                    style="font-size:.875rem"
                  >
                    Min:
                  </label>
                  <select
                    :id="`pin-minimum-input-${p}`"
                    class="form-control form-select form-select-solid form-select-slim"
                    @change="onPinCountChange(p, 'minimum')"
                  >
                    <option
                      v-for="(option, o) in availableMinimumPinOptions"
                      :key="o"
                      :selected="model.$model[p].placement_minimum == option.id"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <!--END: Minimum Pin Select-->

                <!--START: Minimum Pin Select-->
                <div class="d-flex justify-content-start align-items-center">
                  <label
                    :for="`pin-maximum-input-${p}`"
                    class="fw-semibold mr-1 mb-0"
                    style="font-size:.875rem"
                  >
                    Max:
                  </label>
                  <select
                    :id="`pin-maximum-input-${p}`"
                    class="form-control form-select form-select-solid form-select-slim"
                    @change="onPinCountChange(p, 'maximum')"
                  >
                    <option
                      v-for="(option, o) in availableMaximumPinOptions"
                      :key="o"
                      :selected="model.$model[p].placement_maximum == option.id"
                      :disabled="model.$model[p].placement_minimum != null && option.id != null && option.id < model.$model[p].placement_minimum"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <!--END: Minimum Pin Select-->  
              </div>
            </div>
            <!--END: Pin Count Select-->

            <div class="pt-3">
              <div
                class="form-checkbox form-check-custom form-check-solid mb-2 form-check mr-4"
              >
                <input
                  :id="`text-response-checkbox-${p}`"
                  class="form-check-input h-15px w-15px"
                  type="checkbox"
                  :checked="model.$model[p].allow_text_response"
                  @change="
                    model.$model[p].allow_text_response = !model.$model[p].allow_text_response,
                    (typeof model.$model[p].text_response_prompt != 'string' || model.$model[p].text_response_prompt.length === 0) ? model.$model[p].text_response_prompt = 'Why does this interest you?' : null"
                />

                <label
                  class="form-check-label"
                  :for="`text-response-checkbox-${p}`"
                  style="font-size:.925rem !important"
                >
                  Ask for text response
                </label>
              </div>

              <textarea
                v-if="model.$model[p].allow_text_response"
                :for="`text-response-input-${p}`"
                class="form-control form-control-solid w-100"
                v-model="model.$model[p].text_response_prompt"
                rows="4"
              />
            </div>

          </div>
        </div>
        <hr class="mt-5" />
      </div>
    </template>

    <a
      v-if="availablePinTypes.length > 0"
      href="javascript:void(0)"
      class="d-flex justify-content-start align-items-center fw-semibold"
      style="font-size:.925rem; width:fit-content"
      @click="generateRandomPin"
    >
      <i class="fa fa-plus px-2" />
      Add a New Pin
    </a>
  </div>
</template>

<script>
import MarkupPin from "./markupPin.vue";

import {
  mapGetters
} from "vuex";

export default {
  name: "SPECIALMarkupPinInput",

  props: {
    id:             { type: String, required: true },
    model:          { type: Object, required: false },
    managementType: { type: String, required: true }
  },

  components: {
    MarkupPin
  },

  mounted() {
    if (this.managementType == "post") {
      this.addDefaultPin(32);
      this.addDefaultPin(33);
    }
  },

  methods: {
    addDefaultPin: function(pinType) {
      if (!this.availablePinTypes.includes(pinType) || this.managementType != 'post') {
        return;
      }

      let systemMarkupPinType = this.systemMarkupPinTypes.filter(function(markupPinType) {
        return markupPinType.id == pinType;
      });

      this.model.$model.push({
        id: null,
        type: "new",
        system_markup_pin_type_id: systemMarkupPinType[0].id,
        label: systemMarkupPinType[0].label,
        placement_minimum: null,
        placement_maximum: null,
        allow_text_response: 0,
        text_response_prompt: ""
      });
    },
    generateRandomPin: function() {
      const randomIndex = Math.floor(Math.random() * this.availablePinTypes.length);
      const pinTypeId = this.availablePinTypes[randomIndex];
      let markupPinType = this.systemMarkupPinTypes.filter(function(pinType) {
        return pinType.id == pinTypeId
      });

      if (markupPinType.length > 0) {
        markupPinType = markupPinType[0];
        this.model.$model.push({
          id: null,
          type: "new",
          system_markup_pin_type_id: markupPinType.id,
          label: markupPinType.label,
          placement_minimum: null,
          placement_maximum: null,
          allow_text_response: 0,
          text_response_prompt: ""
        });
      }
    },
    removePin: function(pinTypeId) {
      this.model.$model = this.model.$model.filter(function(pin) {
        return pin.system_markup_pin_type_id != pinTypeId
      });
    },
    editPin: function(pinTypeId, index) {
      let systemMarkupPinType = this.systemMarkupPinTypes.filter(function(markupPinType) {
        return markupPinType.id == pinTypeId;
      });

      if (systemMarkupPinType.length > 0) {
        systemMarkupPinType = systemMarkupPinType[0];

        this.model.$model[index].system_markup_pin_type_id = systemMarkupPinType.id;
        this.model.$model[index].label = systemMarkupPinType.label;
      }
    },
    onPinCountChange: function(index, type) {
      let elementId = `pin-${type}-input-${index}`;
      let newPinCount = document.getElementById(elementId).value
      this.model.$model[index][`placement_${type}`] = parseInt(newPinCount);

      if (
        this.model.$model[index].placement_minimum != null &&
        this.model.$model[index].placement_maximum != null &&
        this.model.$model[index].placement_maximum < this.model.$model[index].placement_minimum
      ) {
        this.model.$model[index].placement_maximum = this.model.$model[index].placement_minimum;
      }
    }
  },

  computed: {
    ...mapGetters([
      "systemMarkupPinTypes"
    ]),
    takenPinTypes: function() {
      return this.model.$model.map(obj => obj.system_markup_pin_type_id);
    },
    availablePinTypes: function() {
      let possiblePinTypeIds = [];

      while (possiblePinTypeIds.length < 37) {
        possiblePinTypeIds.push(possiblePinTypeIds.length + 1);
      }

      const uniqueToFirstArray = possiblePinTypeIds.filter(item => !this.takenPinTypes.includes(item));
      const uniqueToSecondArray = this.takenPinTypes.filter(item => !possiblePinTypeIds.includes(item));

      return uniqueToFirstArray.concat(uniqueToSecondArray);
    },
    availableMinimumPinOptions: function() {
      let options = [
        { id: null, label: "No Minimum" }
      ];

      [1,2,3,4,5,6,7,8,9,10].forEach(function(number) {
        options.push({ id: number, label: number })
      });

      return options;
    },
    availableMaximumPinOptions: function() {
      let options = [
        { id: null, label: "No Maximum" }
      ];

      [1,2,3,4,5,6,7,8,9,10].forEach(function(number) {
        options.push({ id: number, label: number })
      });

      return options;
    }
  }
}
</script>