import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_SURVEY_RESPONSE_INFO = "populateSurveyResponseInfo";
export const CLEAR_SURVEY_RESPONSE_INFO = "clearSurveyResponseInfo";
export const CLEAR_SURVEY_RESPONSE_ERRORS = "clearSurveyResponseErrors";

// mutation types
export const SET_SURVEY_RESPONSE_INFO = "setSurveyResponseInfo";
export const RESET_SURVEY_RESPONSE_INFO = "resetSurveyResponseInfo";
export const SET_SURVEY_RESPONSE_ERRORS = "setSurveyResponseErrors";
export const RESET_SURVEY_RESPONSE_ERRORS = "resetSurveyResponseErrors";

const state = {
  survey_response_errors: {},
  survey_response_info: null
};

const getters = {
  surveyResponseErrors(state) {
    return state.survey_response_errors;
  },
  surveyResponseInfo(state) {
    return state.survey_response_info;
  }
};

const actions = {
  [POPULATE_SURVEY_RESPONSE_INFO](context, payload) {

    let route = null;

    if (payload.questionId == null) {
      route = `api/v1/activities/${payload.activityId}/survey_response/${payload.activity_type}`
    } else {
      route = `api/v1/activities/${payload.activityId}/survey_response/${payload.activity_type}/${payload.questionId}`
    }

    return ApiService.get(route).then((res) => {
      const response = res.data;
      context.commit(SET_SURVEY_RESPONSE_INFO, response);
      return response;
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_SURVEY_RESPONSE_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CLEAR_SURVEY_RESPONSE_INFO](context) {
    context.commit(RESET_SURVEY_RESPONSE_INFO);
  },
  [CLEAR_SURVEY_RESPONSE_ERRORS](context) {
    context.commit(RESET_SURVEY_RESPONSE_ERRORS);
  }
}

const mutations = {
  [SET_SURVEY_RESPONSE_INFO](state, response) {
    state.survey_response_info = response;
  },
  [RESET_SURVEY_RESPONSE_INFO](state) {
    state.survey_response_info = null;
  },
  [SET_SURVEY_RESPONSE_ERRORS](state, errors) {
    state.survey_response_errors = errors;
  },
  [RESET_SURVEY_RESPONSE_ERRORS](state) {
    state.survey_response_errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};