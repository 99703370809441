import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_VIEW_ENGAGEMENTS_LIST =
  "populateActivityViewEngagementsList";
export const POPULATE_ACTIVITY_VIEW_ENGAGEMENTS_INFO =
  "populateActivityViewEngagementsInfo";
export const CREATE_VIEW_ENGAGEMENTS = "createViewEngagements";
export const DELETE_VIEW_ENGAGEMENTS = "deleteViewEngagements";
export const CLEAR_VIEW_ENGAGEMENTS_LIST = "clearViewEngagementsList";
export const CLEAR_VIEW_ENGAGEMENTS_INFO = "clearViewEngagementsInfo";
export const CLEAR_VIEW_ENGAGEMENTS_ERRORS = "clearViewEngagementsErrors";

// mutation types
export const SET_ACTIVITY_VIEW_ENGAGEMENTS_LIST =
  "setActivityViewEngagementsList";
export const RESET_ACTIVITY_VIEW_ENGAGEMENTS_LIST =
  "resetActivityViewEngagementsList";
export const SET_ACTIVITY_VIEW_ENGAGEMENTS_INFO =
  "setActivityViewEngagementsInfo";
export const RESET_ACTIVITY_VIEW_ENGAGEMENTS_INFO =
  "resetActivityViewEngagementsInfo";
export const ADD_ACTIVITY_VIEW_ENGAGEMENTS = "addActivityViewEngagements";
export const REMOVE_ACTIVITY_VIEW_ENGAGEMENTS = "removeActivityViewEngagements";
export const SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS =
  "setActivityViewEngagementsErrors";
export const RESET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS =
  "resetActivityViewEngagementsErrors";

const state = {
  activity_view_engagements_errors: {},
  activity_view_engagements_list: [],
  activity_view_engagements_info: []
};

const getters = {
  activityViewEngagementsErrors(state) {
    return state.activity_view_engagements_errors;
  },
  activityViewEngagementsList(state) {
    return state.activity_view_engagements_list;
  },
  activityViewEngagementsInfo(state) {
    return state.activity_view_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_VIEW_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_views",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_response_views;
        context.commit(SET_ACTIVITY_VIEW_ENGAGEMENTS_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_VIEW_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_views" +
        payload.activityViewEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_VIEW_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_VIEW_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_response_views",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_VIEW_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_VIEW_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_views" +
        payload.activityViewEngagementId
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_VIEW_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_VIEW_ENGAGEMENTS_LIST);
  },
  [CLEAR_VIEW_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_VIEW_ENGAGEMENTS_INFO);
  },
  [CLEAR_VIEW_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_VIEW_ENGAGEMENTS_LIST](state, activity_view_engagements_list) {
    state.activity_view_engagements_list = activity_view_engagements_list;
  },
  [RESET_ACTIVITY_VIEW_ENGAGEMENTS_LIST](state) {
    state.activity_view_engagements_list = [];
  },
  [SET_ACTIVITY_VIEW_ENGAGEMENTS_INFO](state, activity_view_engagements_info) {
    state.activity_view_engagements_info = activity_view_engagements_info;
  },
  [RESET_ACTIVITY_VIEW_ENGAGEMENTS_INFO](state) {
    state.activity_view_engagements_info = [];
  },
  [ADD_ACTIVITY_VIEW_ENGAGEMENTS](state, activity_view_engagements_info) {
    state.activity_view_engagements_list.push(activity_view_engagements_info);
  },
  [REMOVE_ACTIVITY_VIEW_ENGAGEMENTS](state, activityViewEngagementId) {
    state.activity_view_engagements_list = state.activity_view_engagements_list.filter(
      function(obj) {
        return obj.id !== activityViewEngagementId;
      }
    );
  },
  [SET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS](
    state,
    activity_view_engagements_errors
  ) {
    state.activity_view_engagements_errors = activity_view_engagements_errors;
  },
  [RESET_ACTIVITY_VIEW_ENGAGEMENTS_ERRORS](state) {
    state.activity_view_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
