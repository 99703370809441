<template>
  <b-form-group
    :id="id + '-group'"
  >
    <div id="notificationToggle" class="row mb-0">
      <InputGenericCheckbox
        class="mb-0 display:inline-block"
        id="input-notification-toggle"
        :model="notificationToggle"
        :submissionStates="submissionStates"
      >
      </InputGenericCheckbox>
      <div class="mb-0 py-1 d-flex justify-content-start">
        <p>
          {{ $t("FORMS.INPUTS.ACTIVITY_INITIAL_NOTIFICATION_TOGGLE_ONE") + " " }}
        </p>
        <v-select
          v-if="notificationToggle.$model == 1"
          id="notification-period"
          class="mx-3"
          :value="selectedNotificationPeriod"
          :items="options"
          :reduce="options => options.value"
          item-text="name"
          item-value="value"
          dense
          @input="initialNotificationPeriodChanged"
        ></v-select>
        <p v-if="notificationToggle.$model == 1">
          {{ " " + $t("FORMS.INPUTS.ACTIVITY_INITIAL_NOTIFICATION_TOGGLE_TWO") }}
        </p>
      </div>
    </div>
    <div
      id="notificationRepeatToggle"
      v-if="notificationToggle.$model == 1"
      class="row mb-0"
    >
      <InputGenericCheckbox
        v-if="notificationToggle.$model == 1"
        class="mb-0 display:inline-block"
        id="input-notification-toggle-2"
        :model="repeatNotificationToggle"
        :submissionStates="submissionStates"
      >
      </InputGenericCheckbox>
      <div class="mb-0 py-1 d-flex justify-content-start">
        <p v-if="notificationToggle.$model == 1">
          {{ $t("FORMS.INPUTS.ACTIVITY_NOTIFICATION_KEEP_REPEATING_ONE") + " " }}
        </p>
        <v-select
          v-if="notificationToggle.$model == 1 && repeatNotificationToggle.$model == 1"
          class="mx-3 period"
          id="notification-repeat-option"
          :value="selectedRepeatOption"
          :items="repeatOptions"
          :reduce="repeatOptions => repeatOptions.value"
          item-text="name"
          item-value="value"
          dense
          @input="notificationRepeatOptionChanged"
          :errorMessage="$t('FORMS.ERROR.ACTIVITY_NOTIFICATION_KEEP_REPEATING_CHOICE')"
        ></v-select>
        <p
          v-if="notificationToggle.$model == 1 && repeatNotificationToggle.$model == 1"
        >
          ,
        </p>
        <v-select
          v-if="notificationToggle.$model == 1 && repeatNotificationToggle.$model == 1"
          class="mx-3"
          id="notification-repeat-frequency"
          :value="selectedRepeatNotificationPeriod"
          :items="options"
          :reduce="options => options.value"
          item-text="name"
          item-value="value"
          dense
          @input="repeatNotificationPeriodChanged"
          :errorMessage="$t('FORMS.ERROR.ACTIVITY_NOTIFICATION_REPEAT_FREQUENCY')"
        ></v-select>
        <p
          v-if="notificationToggle.$model == 1 && repeatNotificationToggle.$model == 1"
        >
          {{ $t("FORMS.INPUTS.ACTIVITY_NOTIFICATION_KEEP_REPEATING_TWO") }}
        </p>
      </div>
    </div>
  </b-form-group>
</template>

<script>
import InputGenericCheckbox from "@/modules/together-helpers/components/generic-inputs/GenericCheckbox";
import ManageActivityHelper from "@/helpers/ManageActivityHelper";

export default {
  name: "InputActivityNotificationSettings",

  props: {
    id: { type: String, required: true },
    notificationToggle: { type: Object, required: true },
    repeatNotificationToggle: { type: Object, required: true },
    selectedNotificationPeriod: { type: Object, required: true },
    selectedRepeatOption: { type: Object, required: true },
    selectedRepeatNotificationPeriod: { type: Object, required: true },
    submissionStates: { type: Object, required: true }
  },

  components: {
    InputGenericCheckbox
  },
  
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    },
    initialNotificationPeriodChanged(value) {
      this.$emit('initial_notification_period_changed', value);
    },
    repeatNotificationPeriodChanged(value) {
      this.$emit('repeat_notification_period_changed', value);
    },
    notificationRepeatOptionChanged(value) {
      this.$emit('notification_repeat_option_changed', value);
    }
  },

  computed: {
    options: function() {
      return ManageActivityHelper.computedNotificationPeriods();
    },
    repeatOptions: function() {
      return ManageActivityHelper.computedRepeatOptions();
    }
  }
};
</script>
