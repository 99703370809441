import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_ROOMS_LIST = "populateProjectRoomsList";
export const CLEAR_PROJECT_ROOMS_LIST = "clearProjectRoomsList";
export const CLEAR_PROJECT_ROOMS_ERRORS = "clearProjectRoomsErrors";

// mutation types
export const SET_PROJECT_ROOMS_LIST = "setProjectRoomsList";
export const RESET_PROJECT_ROOMS_LIST = "resetProjectRoomsList";
export const SET_PROJECT_ROOMS_ERRORS = "setProjectRoomsErrors";
export const RESET_PROJECT_ROOMS_ERRORS = "resetProjectRoomsErrors";

const state = {
  project_rooms_list: [],
  errors: {}
};

const getters = {
  projectRooms(state) {
    return state.project_rooms_list;
  },
  projectRoomErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_ROOMS_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/project_room_setups", {
      params: payload.params
    })
    .then(res => {
      const projectRoomSetups = res.data.data._embedded.project_room_setups;
      context.commit(SET_PROJECT_ROOMS_LIST, projectRoomSetups);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_ROOMS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_PROJECT_ROOMS_LIST](context) {
    context.commit(RESET_PROJECT_ROOMS_LIST);
  },
  [CLEAR_PROJECT_ROOMS_ERRORS](context) {
    context.commit(RESET_PROJECT_ROOMS_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_ROOMS_LIST](state, projectRoomSetups) {
    state.project_rooms_list = projectRoomSetups;
  },
  [RESET_PROJECT_ROOMS_LIST](state) {
    state.project_rooms_list = {};
  },
  [SET_PROJECT_ROOMS_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_ROOMS_ERRORS](state) {
    state.errors = {};
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};