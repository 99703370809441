class ManageMarkupQuestion {

  //Suggests a key based on either a client or project name
  static topform(component) {
      return [
        {
          id: 1,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_GENERAL")}`,
          errors: component.tab1Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-10 px-5 mb-10",
                        id: 'input-question-publish-toggle',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.form.published : component.$v.editForm.published,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_PUBLISH")}`,
                      }
                    },
                    {
                      name: "InputGenericRichText",
                      properties: {
                        class: "col-xl-12 px-5",
                        id: 'input-question',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.form.question : component.$v.editForm.question,
                        label: `${component.$t("FORMS.INPUTS.QUESTION")}`,
                        error: `${component.$t("FORMS.GENERIC_ERRORS.REQUIRED")}`,
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_MARKUP_PIN_NUMBER")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericSelect",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-pin-answer-type',
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_PIN_ANSWER_TYPE")}`,
                        model: component.managementType == 'post' ? component.$v.form.pin_answer_type : component.$v.editForm.pin_answer_type,
                        submissionStates: component.submissionStates,
                        items: [
                          {
                            value: 0,
                            text: `${component.$t('FORMS.INPUTS.PIN_ANSWER_TYPE_UP_TO')}`
                          },
                          {
                            value: 1,
                            text: `${component.$t('FORMS.INPUTS.PIN_ANSWER_TYPE_TOTAL_OF')}`
                          }
                        ]
                      }
                    },
                    {
                      name: "InputGenericSelect",
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-pin-count',
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_PIN_COUNT")}`,
                        model: component.managementType == 'post' ? component.$v.form.pin_count : component.$v.editForm.pin_count,
                        submissionStates: component.submissionStates,
                        items: [
                          {
                            value: 1,
                            text: 1
                          },
                          {
                            value: 2,
                            text: 2
                          },
                          {
                            value: 3,
                            text: 3
                          },
                          {
                            value: 4,
                            text: 4
                          },
                          {
                            value: 5,
                            text: 5
                          },
                        ]
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_MARKUP_ADDITIONAL_RESPONSE")}`,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-5 px-5",
                        id: 'input-text-response-toggle',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.enable_text_response : component.$v.edit_enable_text_response,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_ASK_FOR_TEXT_RESPONSE")}`,
                      }
                    },
                    {
                      name: "InputGenericCheckbox",
                      show: component.managementType == 'post' ? 
                            component.enable_text_response == 1 :
                            component.edit_enable_text_response == 1,
                      properties: {
                        class: "col-xl-5 pl-0",
                        id: 'input-text-response-required-toggle',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.form.text_response_required : component.$v.editForm.text_response_required,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_RESPONSE_REQUIRED")}`,
                      }
                    }
                  ]
                },
                {
                  show: component.managementType == 'post' ? 
                        component.enable_text_response == 1 :
                        component.edit_enable_text_response == 1,
                  components: [
                    {
                      name: "InputGenericTextArea",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-text-response-prompt',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_TEXT_RESPONSE_PROMPT")}`,
                        placeholder: "e.g. " + `${component.$t('FORMS.INPUTS.ACTIVITY_TEXT_RESPONSE_PROMPT_PLACEHOLDER')}`,
                        model: component.managementType == 'post' ? component.$v.form.text_response_prompt : component.$v.editForm.text_response_prompt,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-5 px-5",
                        id: 'input-enable-sentiment-toggle',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.enable_sentiment : component.$v.edit_enable_sentiment,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_ASK_FOR_SENTIMENT")}`,
                      }
                    },
                    {
                      name: "InputGenericCheckbox",
                      show: component.managementType == 'post' ? 
                            component.enable_sentiment == 1 :
                            component.edit_enable_sentiment == 1,
                      properties: {
                        class: "col-xl-5 pl-0",
                        id: 'input-sentiment-required-toggle',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.form.sentiment_response_required : component.$v.editForm.sentiment_response_required,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_RESPONSE_REQUIRED")}`,
                      }
                    }
                  ]
                },
                {
                  show: component.managementType == 'post' ? 
                        component.enable_sentiment == 1 :
                        component.edit_enable_sentiment == 1,
                  components: [
                    {
                      name: "InputGenericTextArea",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-sentiment-response-prompt',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_SENTIMENT_RESPONSE_PROMPT")}`,
                        placeholder: "e.g. " + `${component.$t('FORMS.INPUTS.ACTIVITY_SENTIMENT_RESPONSE_PROMPT_PLACEHOLDER')}`,
                        model: component.managementType == 'post' ? component.$v.form.sentiment_response_prompt : component.$v.editForm.sentiment_response_prompt,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
  
export default ManageMarkupQuestion;