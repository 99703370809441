<template>
  <div class="card-title">
    <h3 class="card-label" :class="{ 'py-6' : tabCount == 1 }" :style="{ 'margin-bottom': tabCount == 1 ? '1px' : '0px' }">
      {{ calculatedTitle }}
      <small v-if="managementType == 'update'" class="ml-1">{{ subject | stripHTML }}</small>

      <!--begin::Conditional Label for status-->
      <span v-if="managementType == 'update'" :class="calculatedStatus.class">
        {{ calculatedStatus.text }}
      </span>
      <!--end::Conditional Label for status-->
    </h3>
    <!-- <span v-if="type == 'markup'" class="text-muted font-size-xs d-flex justify-content-start" variant="dark" v-b-tooltip.hover.top :title="$t('NAVIGATION.MARKUP_ACTIVITY.NO_MOBILE_TOOLTIPS')">
      <div class="custom-prohibit-mobile-icon w-20 mr-5">
        <i class="fa fa-ban"></i>
        <i class="fa fa-mobile-alt"></i>
      </div>
      <div class="w-80">
        {{$t("NAVIGATION.MARKUP_ACTIVITY.NO_MOBILE")}}
      </div>
    </span> -->
  </div>
</template>

<script>
export default {
  name: "InformativeLabel",

  props: {
    managementType: { type: String, required: true },
    type: { type: String, required: true },
    subject: { type: String, required: false },
    status: { type: String, required: false },
    tabCount: { type: Number, required: true },
    isActivity: { type: Boolean, required: true }
  },

  computed: {
    calculatedTitle: function() {
      return `${this.$t("FORM_HEADERS." + this.type.toUpperCase() + "." + this.managementType.toUpperCase())}`;
    },
    calculatedStatus: function() {
      if (this.status != undefined ) {
        if (this.isActivity) {
            switch (this.status) {
            case "draft":
              return {
                text: `${this.$t("SYSTEM.ACTIVITY_STAGE.DRAFT")}`,
                class: "label label-warning label-pill label-inline ml-1"
              };
            case "published":
              return {
                text: `${this.$t("GENERAL.STATUS.PUBLISHED")}`,
                class: "label label-warning label-pill label-inline ml-1"
              };
            case "active":
              return {
                text: `${this.$t("SYSTEM.ACTIVITY_STAGE.ACTIVE")}`,
                class: "label label-success label-pill label-inline ml-1"
              };
            case "closed":
              return {
                text: `${this.$t("SYSTEM.ACTIVITY_STAGE.CLOSED")}`,
                class: "label label-danger label-pill label-inline ml-1"
              };
            default:
              return {};
          }
        } else {
          switch (this.status) {
            case "published":
              return {
                text: `${this.$t("GENERAL.STATUS.PUBLISHED")}`,
                class: "label label-success label-pill label-inline ml-1"
              };
            case "unpublished":
              return {
                text: `${this.$t("GENERAL.STATUS.NOT_PUBLISHED")}`,
                class: "label label-warning label-pill label-inline ml-1"
              };
            case "blocked":
              return {
                text: `${this.$t("GENERAL.STATUS.BLOCKED")}`,
                class: "label label-danger label-pill label-inline ml-1"
              };
            case "invited":
              return {
                text: `${this.$t("GENERAL.STATUS.INVITED")}`,
                class: "label label-warning label-pill label-inline ml-1"
              };
            case "subscribed":
              return {
                text: `${this.$t("GENERAL.STATUS.SUBSCRIBED")}`,
                class: "label label-success label-pill label-inline ml-1"
              };
            case "pending invitation":
              return {
                text: `${this.$t("TABLES.PROJECT_USER_TOOLTIPS.NO_INVITE")}`,
                class: "label label-secondary label-pill label-inline ml-1"
              }
            case "system blocked":
              return {
                text: `${this.$t("GENERAL.STATUS.SYSTEM_BLOCKED")}`,
                class: "label label-dark label-pill label-inline ml-1"
              }
            default:
              return {};
          }
        };
      } else {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .custom-prohibit-mobile-icon{
    display: flex;
    position: relative;
    i.fa-ban{
      background: transparent;
      position: absolute;
      font-size: 3.1em;
      z-index: 2;
    }
    i.fa-mobile-alt {
    position: absolute;
    background: transparent;
    font-size: 1.7em !important;
    left: 50%;
    z-index: 1;
    top: 25%;
    }
  }
 </style>