import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_TEXT_ANALYSIS_SUMMARY = "populateTextAnalysisSummary";
export const POPULATE_TEXT_ANALYSIS_EXCLUSION = "populateTextAnalysisExclusion";
export const CLEAR_TEXT_ANALYSIS_SUMMARY = "clearTextAnalysisSummary";
export const CLEAR_TEXT_ANALYSIS_EXCLUSION = "clearTextAnalysisExclusion";
export const CLEAR_TEXT_ANALYSIS_ERRORS = "clearTextAnalysisErrors";

// mutation types
export const SET_TEXT_ANALYSIS = "setTextAnalysis";
export const RESET_TEXT_ANALYSIS = "resetTextAnalysis";
export const SET_TEXT_ANALYSIS_EXCLUSION = "setTextAnalysisExclusion";
export const RESET_TEXT_ANALYSIS_EXCLUSION = "resetTextAnalysisExclusion";
export const SET_TEXT_ANALYSIS_ERRORS = "setTextAnalysisErrors";
export const RESET_TEXT_ANALYSIS_ERRORS = "resetTextAnalysisErrors";

const state = {
  errors: {},
  text_analysis_summary: [],
  text_analysis_exclusion: []
};

const getters = {
  textAnalysisErrors(state) {
    return state.errors;
  },
  textAnalysisSummary(state) {
    return state.text_analysis_summary;
  },
  textAnalysisExclusion(state) {
    return state.text_analysis_exclusion;
  }
};

const actions = {
  [POPULATE_TEXT_ANALYSIS_SUMMARY](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/text_analysis", payload.params)
    .then(res => {
      const textAnalysis = res.data;
      return textAnalysis;
      //context.commit(SET_TEXT_ANALYSIS, textAnalysis);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_TEXT_ANALYSIS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_TEXT_ANALYSIS_EXCLUSION](context, projectId) {
    return ApiService.get("api/v1/projects/" + projectId + "/excluded_words")
    .then(res => {
      const excludedWords = res.data;
      context.commit(SET_TEXT_ANALYSIS_EXCLUSION, excludedWords);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_TEXT_ANALYSIS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_TEXT_ANALYSIS_SUMMARY](context) {
    context.commit(RESET_TEXT_ANALYSIS);
  },
  [CLEAR_TEXT_ANALYSIS_EXCLUSION](context) {
    context.commit(RESET_TEXT_ANALYSIS_EXCLUSION);
  },
  [CLEAR_TEXT_ANALYSIS_ERRORS](context) {
    context.commit(RESET_TEXT_ANALYSIS_ERRORS);
  }
};

const mutations = {
  [SET_TEXT_ANALYSIS](state, textAnalysis) {
    state.text_analysis_summary = textAnalysis;
  },
  [RESET_TEXT_ANALYSIS](state) {
    state.text_analysis_summary = [];
  },
  [SET_TEXT_ANALYSIS_EXCLUSION](state, excludedWords) {
    state.text_analysis_exclusion = excludedWords;
  },
  [RESET_TEXT_ANALYSIS_EXCLUSION](state) {
    state.text_analysis_exclusion = [];
  },
  [SET_TEXT_ANALYSIS_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_TEXT_ANALYSIS_ERRORS](state) {
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};