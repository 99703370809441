import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST =
  "populateActivitySentimentEngagementsList";
export const POPULATE_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO =
  "populateActivitySentimentEngagementsInfo";
export const CREATE_SENTIMENT_ENGAGEMENTS = "createSentimentEngagements";
export const UPDATE_SENTIMENT_ENGAGEMENTS = "updateSentimentEngagements";
export const DELETE_SENTIMENT_ENGAGEMENTS = "deleteSentimentEngagements";
export const CLEAR_SENTIMENT_ENGAGEMENTS_LIST = "clearSentimentEngagementsList";
export const CLEAR_SENTIMENT_ENGAGEMENTS_INFO = "clearSentimentEngagementsInfo";
export const CLEAR_SENTIMENT_ENGAGEMENTS_ERRORS =
  "clearSentimentEngagementsErrors";

// mutation types
export const SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST =
  "setActivitySentimentEngagementsList";
export const RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST =
  "resetActivitySentimentEngagementsList";
export const SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO =
  "setActivitySentimentEngagementsInfo";
export const RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO =
  "resetActivitySentimentEngagementsInfo";
export const ADD_ACTIVITY_SENTIMENT_ENGAGEMENTS =
  "addActivitySentimentEngagements";
export const UPDATE_ACTIVITY_SENTIMENT_ENGAGEMENTS =
  "updateActivitySentimentEngagements";
export const REMOVE_ACTIVITY_SENTIMENT_ENGAGEMENTS =
  "removeActivitySentimentEngagements";
export const SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS =
  "setActivitySentimentEngagementsErrors";
export const RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS =
  "resetActivitySentimentEngagementsErrors";

const state = {
  activity_sentiment_engagements_errors: {},
  activity_sentiment_engagements_list: [],
  activity_sentiment_engagements_info: []
};

const getters = {
  activitySentimentEngagementsErrors(state) {
    return state.activity_sentiment_engagements_errors;
  },
  activitySentimentEngagementsList(state) {
    return state.activity_sentiment_engagements_list;
  },
  activitySentimentEngagementsInfo(state) {
    return state.activity_sentiment_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_sentiments",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses =
          res.data.data._embedded.activity_sentiment_engagements;
        context.commit(SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_sentiments" +
        payload.activitySentimentEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_SENTIMENT_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_sentiments",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_SENTIMENT_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_SENTIMENT_ENGAGEMENTS](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_sentiments" +
        payload.activitySentimentEngagementId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_SENTIMENT_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_sentiments/" +
        payload.activitySentimentEngagementId
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_SENTIMENT_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST);
  },
  [CLEAR_SENTIMENT_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO);
  },
  [CLEAR_SENTIMENT_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST](
    state,
    activity_sentiment_engagements_list
  ) {
    state.activity_sentiment_engagements_list = activity_sentiment_engagements_list;
  },
  [RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_LIST](state) {
    state.activity_sentiment_engagements_list = [];
  },
  [SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO](
    state,
    activity_sentiment_engagements_info
  ) {
    state.activity_sentiment_engagements_info = activity_sentiment_engagements_info;
  },
  [RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_INFO](state) {
    state.activity_sentiment_engagements_info = [];
  },
  [ADD_ACTIVITY_SENTIMENT_ENGAGEMENTS](
    state,
    activity_sentiment_engagements_info
  ) {
    state.activity_sentiment_engagements_list.push(
      activity_sentiment_engagements_info
    );
  },
  [UPDATE_ACTIVITY_SENTIMENT_ENGAGEMENTS](
    state,
    activity_sentiment_engagements_info
  ) {
    let newActivitySentimentEngagementsArray = [];
    newActivitySentimentEngagementsArray.push(
      activity_sentiment_engagements_info
    );
    state.activity_sentiment_engagements_list = state.activity_sentiment_engagements_list.map(
      obj =>
        newActivitySentimentEngagementsArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_ACTIVITY_SENTIMENT_ENGAGEMENTS](
    state,
    activitySentimentEngagementId
  ) {
    state.activity_sentiment_engagements_list = state.activity_sentiment_engagements_list.filter(
      function(obj) {
        return obj.id !== activitySentimentEngagementId;
      }
    );
  },
  [SET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS](
    state,
    activity_sentiment_engagements_errors
  ) {
    state.activity_sentiment_engagements_errors = activity_sentiment_engagements_errors;
  },
  [RESET_ACTIVITY_SENTIMENT_ENGAGEMENTS_ERRORS](state) {
    state.activity_sentiment_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
