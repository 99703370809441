class HtmlEditor {

  constructor(newElement, selection, parent) {
    this.newElement = newElement;
    this.selection = selection;
    this.parent = parent;
  }

  insert() {
    if (this.selection.focusNode != undefined && this.selection.focusNode.parentNode.id == "editable-html") {
      if (this.selection.focusNode.nodeType == 3) {
        return this.#insertWithinText();
      }
    } else {
      return this.#insertAtEnd();
    }
  }

  #insertWithinText() {
    let elementToAdd = (this.newElement.tagName == "BR") ? this.newElement : this.newElement.$el;

    const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];
    let split = splitAt(this.selection.focusOffset, this.selection.focusNode.nodeValue);

    const leftNode = document.createTextNode(split[0]);
    const rightNode = document.createTextNode(split[1]);

    let newRightNode = this.parent.insertBefore(rightNode, this.selection.focusNode);
    let newMiddleNode = this.parent.insertBefore(elementToAdd, newRightNode);

    if (this.newElement.tagName == "BR") {
      newMiddleNode.insertAdjacentHTML("afterEnd","&zwnj;")
    }

    this.parent.insertBefore(leftNode, newMiddleNode); 

    this.selection.focusNode.remove();
    this.#placeCaretAtEnd();
    return this.instance;
  }

  #insertAtEnd() {
    let elementToAdd = (this.newElement.tagName == "BR") ? this.newElement : this.newElement.$el;
    let newNode = this.parent.appendChild(elementToAdd);

    if (this.newElement.tagName == "BR") {
      newNode.insertAdjacentHTML("afterEnd","&zwnj;")
    }

    this.#placeCaretAtEnd();

    return this.instance;
  }

  #placeCaretAtEnd() {
    let el = this.parent;
    el.focus();
    if (typeof window.getSelection != "undefined"
          && typeof document.createRange != "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
}
}
  
export default HtmlEditor;