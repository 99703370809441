<template>
  <table class="table center col-xl-12">
    <tbody>
      <tr
        v-on:mouseover="hovering = true"
        v-on:mouseleave="hovering = false"
        class="drag-page"
        :class="data.is_page_break ? 'bg-light' : null"
      >
        <td class="pl-1" style="width:45px">
          <a
            href="javascript:void(0)"
          >
            <i class="fas fa-grip-vertical text-dark-25 icon-lg mt-2 ml-2"></i>
          </a>
        </td>
        <template v-if="data.is_page_break">
          <td class="d-table-cell py-5 px-0" style="width:330px">
            {{ $t("TABLES.ACTIVITY_QUESTIONS.PAGE_BREAKS") }}
          </td>
          <td class="d-table-cell py-5 px-2 text-center" style="width:220px"></td>
          <td class="d-table-cell py-5 px-2 text-center" style="width:100px"></td>
          <td class="px-2 py-3 d-table-cell text-right" style="width:200px">
            <div v-if="hovering">
              <a
                href="javascript:void(0)"
                class="btn btn-icon btn-secondary btn-hover-danger btn-sm mx-1"
                @click="$emit('delete_pressed')"
                v-b-tooltip:hover.bottom.window
                :title="$t('TABLES.ACTIVITY_QUESTIONS.DELETE_PAGE_BREAK')"
              >
                <span class="svg-icon svg-icon-primary svg-icon-danger">
                  <inline-svg src="media/svg/icons/Home/Trash.svg" />
                </span>
              </a>
            </div>
          </td>
        </template>
        <template v-else>
          <td class="d-table-cell py-5 px-0" style="width:330px">
            <a
              v-if="data.system_answer_type_id != 8"
              href="javascript:void(0)"
              @click="$emit('edit_question_pressed', data)"
            >
              {{ data.question | stripHTML }}
            </a>

            <a
              v-else
              class="fitb"
              href="javascript:void(0)"
              @click="$emit('edit_question_pressed', data)"
              v-html="data.shortenedQuestion"
            >
            </a>
          </td>
          <td class="d-table-cell py-5 px-2 text-center" style="width:220px">
            <b-badge
              :variant="calculateVariant(data.system_answer_type_id)"
            >
              {{ calculatedAnswerType }}
            </b-badge>
          </td>
          <td v-if="computedChoiceCount == 0" class="d-table-cell py-5 px-2 text-center" style="width:100px">
            <b-badge>
              {{ $t('TABLES.ACTIVITY_QUESTIONS.NO_CHOICES_TYPE') }}
            </b-badge>
          </td>
          <td v-else class="px-2 py-3 d-table-cell py-5 px-2 text-center" style="width:100px"> 
            {{ computedChoiceCount }}
          </td>
          <td class="px-2 py-3 d-table-cell text-right" style="width:200px">
            <div v-if="hovering">
              <a
                href="javascript:void(0)"
                class="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
                @click="$emit('duplicate_question_pressed', data)"
                v-b-tooltip:hover.bottom.window
                :title="$t('TABLES.ACTIVITY_QUESTIONS.DUPLICATE')"
              >
                <span class="svg-icon svg-icon-primary">
                  <inline-svg src="media/svg/icons/General/Duplicate.svg" />
                </span>
              </a>
              <a
                href="javascript:void(0)"
                class="btn btn-icon btn-light btn-hover-danger btn-sm mx-1"
                @click="$emit('delete_pressed')"
                v-b-tooltip:hover.bottom.window
                :title="$t('TABLES.ACTIVITY_QUESTIONS.DELETE_QUESTION')"
              >
                <span class="svg-icon svg-icon-primary svg-icon-danger">
                  <inline-svg src="media/svg/icons/Home/Trash.svg" />
                </span>
              </a>
            </div>
          </td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ActivityQuestionsRow",
  

  props: {
    data: { type: Object, required: true },
    search: { type: String, required: true }
  },

  data() {
    return {
      hovering: false
    }
  },

  methods: {
    calculateVariant(answerTypeId) {
      let choiceTypes = [2,3,5];
      let variant = "";

      if (choiceTypes.includes(answerTypeId)) {
        // variant = "warning"
        variant = "info"
      } else {
        variant = "info"
      }

      return variant;
    }
  },

  computed: {
    ...mapGetters(["projectInfo"]),
    computedChoiceCount: function() {
      let count = 0;

      if (this.data.choices != undefined) {
        count = this.data.choices.length;
      };

      return count;
    },
    calculatedAnswerType: function() {
      let type = '';

      if (this.data.answer_type != undefined) {
        type = this.data.answer_type.name;
      } else {
        switch (this.data.system_answer_type_id) {
          case 1:
            type = 'Open-ended'
            break;
          case 2:
            type = 'Multiple choice'
            break;
          case 3:
            type = 'Single choice'
            break;
          case 4:
            type = 'Picture upload'
            break;
          case 5:
            type = 'Ranking'
            break;
          case 6:
            type = 'Video upload'
            break;
          case 7:
            type = 'Pin'
            break;
          case 8:
            type = "Fill in the blanks";
            break;
          case 9:
            type = "Media Upload";
            break;
          default:
            type = ''
            break;
        }
      }

      return type;
    }
  }
};
</script>
