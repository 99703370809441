<template>
  <div>
    <ErrorBox
      :errors="errors"
      @clear_errors="errors = {}"
    />

    <h3
      v-if="title != null"
      class="font-size-lg text-dark font-weight-bold mb-6 mt-3 px-0"
    >
      {{ title }}
    </h3>

    <div class="table-responsive">
      <table
        id="events_table"
        class="table table-head-custom table-vertical center table-hover"
        style="table-layout:auto; overflow: auto"
      >
        <thead>
          <tr>
            <th class="px-2 py-3">
              {{ $t('TABLES.EVENTS.DATE') }}
            </th>
            <th class="px-2 py-3 text-center">
              {{ $t('TABLES.EVENTS.TYPE') }}
            </th>
            <th class="px-2 py-3 text-left">
              {{ $t('TABLES.EVENT.COMMENTS') }}
            </th>
            <th class="px-2 py-3 text-center">
              {{ $t('TABLES.EVENTS.ALERT') }}
            </th>
          </tr>
        </thead>

        <tbody v-if="localEvents != null">
          <tr
            v-for="(event, e) in localEvents"
            :key="e"
          >
            <td class="align-middle">
              <GenericDatetimeDisplay
                :long="true"
                :date="event.created_at"
                :lastUpdated="false"
                :createdAt="true"
                :relative="false"
              >
              </GenericDatetimeDisplay>
            </td>
            <td class="text-center align-middle">
              <span
                class="label label-pill label-inline ml-3"
                :class="`label-light-${event.local_type.state}`"
                style="position:relative; top:-1px"
              >
                {{ event.local_type.label }}
              </span>
            </td>
            <td class="align-middle">
              <span class="font-weight-bold">
                {{ event.summary }}
              </span>
              <p class="mb-0">
                {{ event.description }}
              </p>
            </td>
            <td
              class="text-center align-middle"
            >
              <b-form-checkbox
                v-if="event.system_event_type_id >= 3"
                size="lg"
                :value="1"
                :unchecked-value="0"
                switch
                v-model="localEvents[e].is_active"
                :disabled="submitting == true"
                @change="onEventActiveToggle($event, event, e)"
              >
                <span class="font-size-base pl-1">
                  {{ (event.is_active) ? $t('TABLES.EVENTS.ON') : $t('TABLES.EVENTS.OFF') }}
                </span>
              </b-form-checkbox>
            </td>
          </tr>         
        </tbody>
      </table>

      <div
        v-if="localEvents == null"
        class="d-flex justify-content-center mx-0 pt-5"
      >
        <b-spinner
          variant="primary"
          label="Spinning"
          class="table-loader my-3"
        >
        </b-spinner>
      </div>
    </div>    
  </div>

</template>

<script>
import {
  mapActions
} from "vuex";

import ErrorBox from "@/view/components/ErrorBox";
import GenericDatetimeDisplay from "@/modules/together-helpers/components/generic-displays/GenericDatetimeDisplay"

export default {
  name: "EventsTable",

  props: {
    showFilter: { type: Boolean, required: false, default: false },
    title:      { type: String, required: false, default: null },
    events:     { type: Array, required: false, default: null }
  },

  data() {
    return {
      submitting: false,
      localEvents: null,
      errors: {}
    }
  },
  
  components: {
    ErrorBox,
    GenericDatetimeDisplay
  },

  mounted() {
    this.localEvents = this.events
  },

  methods: {
    onEventActiveToggle: function(newState, event, index) {
      this.submitting = true;
      let payload = {
        url: (event.project_id == null) ? `api/v1/events/${event.id}` : `api/v1/projects/${event.project_id}/events/${event.id}`,
        data: {
          is_active: newState
        }
      }

      this.updateEvent(payload).catch((error) => {
        this.localEvents[index].is_active = Number(!newState);
        this.errors = error.response.data;
      }).finally(() => {
        this.submitting = false;
      })
    },
    ...mapActions({
      updateEvent: "updateEvent"
    })
  }
}
</script>