import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

/* Generic */
import generic from "@/modules/together-helpers/vuex/generic.module";

/* System */
import asset from "./system/asset.module";
import auth from "@/modules/together-authentication/store/auth.module";
import htmlClass from "./system/htmlclass.module";
import config from "./system/config.module";
import breadcrumbs from "./system/breadcrumbs.module";
import system from "./system/system.module";
import menu from "./system/menu.module";
import i18n from "@/modules/together-helpers/components/generic-features/languages/vuex/i18n.module";
import generalSearch from "./system/general_search.module";
import helpscout from "./system/helpscout.module";
import zendesk from "@/modules/together-helpers/vuex/system/zendesk.module";
import notification from "@/modules/together-helpers/components/generic-features/notifications/vuex/notifications.module";
import version from "@/modules/together-helpers/vuex/system/version.module";

/* Core */
import client from "./core/client.module";
import user from "./core/user.module";
import notification_preferences from "./core/notification_preferences";
import publishing from "./core/publishing.module";
import userTimezone from "./core/user_timezone.module";
import event from "./core/event.module";

/* Project */
import project from "./project/project.module";
import projectSegments from "./project/project_segments.module";
import projectSegmentations from "./project/project_segmentations.module";
import projectTextAnalysis from "./project/project_text_analysis.module";
import projectProfiles from "./project/project_profiles.module";
import ProjectReports from "./project/project_reports.module";
import projectRooms from "./project/project_room.module";
import projectUsers from "./project/project_users.module";
import projectBasicProfileQuestions from "./project/project_basic_profile_questions.module";
import projectExtendedProfileQuestions from "./project/project_extended_profile_questions.module";
import projectTags from "./project/project_tags.module";
import projectCommunityOptions from "./project/project_community_options.module";
import projectModeratedWords from "./project/project_moderated_words.module";
import projectForums from "./project/project_forums.module";
import projectRoomSetups from "./project/project_room_setups.module";
import projectCommunications from "./project/project_communications.module";
import projectCommunicationPreview from "./project/project_communication_preview.module";
import projectBrands from "@/modules/together-helpers/vuex/project/project_brand.module";
import projectWidgets from "./project/project_widgets.module";
import projectTemplates from "@/modules/together-helpers/vuex/project/project_template.module";
import projectUserSample from "@/modules/together-helpers/vuex/project/project_user_sample.module";

/* Page */
import page from "./page/page.module";
import pageSection from "./page/page_section.module";

/* Activity */
import activity from "./activity/activity.module";
import diaryQuestion from "./activity/diary_question.module";
import surveyQuestion from "./activity/survey_question.module";
import pollQuestion from "./activity/poll_question.module";
import markupQuestion from "./activity/markup_question.module";
import diaryQuestionChoice from "./activity/diary_question_choice.module";
import surveyQuestionChoice from "./activity/survey_question_choice.module";
import surveyResponse from "./activity/survey_response.module";

/* Activity Engagement */
import sentimentEngagement from "@/modules/together-helpers/vuex/activity/engagement/activity_sentiment_engagements.module";
import replyEngagement from "@/modules/together-helpers/vuex/activity/engagement/activity_reply_engagements.module";
import favouriteEngagement from "./activity/activity_favourite_engagements.module";
import noteEngagement from "./activity/activity_note_engagements.module";
import tagEngagement from "./activity/activity_tag_engagements.module";
import activityViewEngagement from "./activity/activity_view_engagement.module";
import interaction from "@/modules/together-helpers/vuex/activity/interaction/interactions.module";

/* Activity Response Types */
import blogResponses from "./activity/activity_blog_responses.module";
import diaryResponses from "./activity/activity_diary_responses.module";
import discussionResponses from "./activity/activity_discussion_responses.module";
import surveyResponses from "./activity/activity_survey_responses.module";
import pictureBookResponses from "@/modules/together-helpers/vuex/activity/response/activity_picture_book_responses.module";
import ideastormResponses from "./activity/activity_ideastorm_responses.module";
import externalResponses from "./activity/activity_external_responses.module";
import markupResponses from "./activity/activity_markup_responses.module";
import pollResponses from "./activity/activity_poll_responses.module";
import responses from "@/modules/together-helpers/vuex/activity/response/responses.module";

/* Workflows */
import registerWorkflow from "@/modules/together-authentication/store/register_workflow.module";
import userImportWorkflow from "./forms/project_user_import.module";
import projectStream from "./project/project_stream.module";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    auth: {
      errors: state.auth.errors,
      user: state.auth.user,
      isAuthenticated: state.auth.isAuthenticated
    }
  })
});

export default new Vuex.Store({
  modules: {
    generic,
    asset,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    system,
    menu,
    i18n,
    generalSearch,
    helpscout,
    zendesk,
    client,
    user,
    userTimezone,
    event,
    notification,
    version,
    notification_preferences,
    publishing,
    project,
    projectSegments,
    projectSegmentations,
    projectTextAnalysis,
    projectProfiles,
    ProjectReports,
    projectRooms,
    projectUsers,
    projectBasicProfileQuestions,
    projectExtendedProfileQuestions,
    projectTags,
    projectCommunityOptions,
    projectModeratedWords,
    projectForums,
    activity,
    activityViewEngagement,
    interaction,
    diaryQuestion,
    surveyQuestion,
    pollQuestion,
    diaryQuestionChoice,
    surveyQuestionChoice,
    surveyResponse,
    markupQuestion,
    projectRoomSetups,
    projectCommunications,
    projectCommunicationPreview,
    projectBrands,
    projectWidgets,
    projectTemplates,
    projectUserSample,
    page,
    pageSection,
    sentimentEngagement,
    favouriteEngagement,
    replyEngagement,
    noteEngagement,
    tagEngagement,
    blogResponses,
    diaryResponses,
    discussionResponses,
    surveyResponses,
    pictureBookResponses,
    ideastormResponses,
    externalResponses,
    markupResponses,
    pollResponses,
    responses,
    registerWorkflow,
    userImportWorkflow,
    projectStream
  },
  plugins: [vuexLocal.plugin]
});
