import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_FORUM_LIST = "populateProjectForumList";
export const POPULATE_PROJECT_FORUM_INFO = "populateProjectForumInfo";
export const CLEAR_PROJECT_FORUM = "clearProjectForum";
export const CREATE_PROJECT_FORUM = "createProjectForum";
export const UPDATE_PROJECT_FORUM = "updateProjectForum";
export const DELETE_PROJECT_FORUM = "deleteProjectForum";
export const CLEAR_PROJECT_FORUM_ERRORS =
  "clearProjectForumErrors";

// mutation types
export const SET_PROJECT_FORUM_LIST = "setProjectForumList";
export const SET_PROJECT_FORUM = "setProjectForum";
export const RESET_PROJECT_FORUM = "resetProjectForum";
export const SET_PROJECT_FORUM_ERRORS = "setProjectForumErrors";
export const RESET_PROJECT_FORUM_ERRORS =
  "resetProjectForumErrors";

const state = {
  project_forum: {},
  project_forum_list: {},
  errors: {}
};

const getters = {
  projectForum(state) {
    return state.project_forum;
  },
  projectForumList(state) {
    return state.project_forum_list;
  },
  projectForumErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_FORUM_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/forums", { params: payload.params }
    )
      .then(res => {
        const projectForums = res.data.data._embedded.forums;
        context.commit(SET_PROJECT_FORUM_LIST, projectForums);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_FORUM_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_FORUM_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/forums/" + payload.id, {
      params: payload.params
    }).then(res => {
      const projectForum = res.data.data;
      context.commit(SET_PROJECT_FORUM, projectForum);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_FORUM_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CLEAR_PROJECT_FORUM](context) {
    context.commit(CLEAR_PROJECT_FORUM);
  },
  [CREATE_PROJECT_FORUM](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/forums",
      payload.data
    )
      .then(res => {
        const projectForum = res.data.data;
        context.commit(SET_PROJECT_FORUM, projectForum);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_FORUM_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_FORUM](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/forums/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(SET_PROJECT_FORUM, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_FORUM_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_FORUM](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/forums/" +
        payload.id
    )
      .then(() => {
        context.commit(CLEAR_PROJECT_FORUM);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_FORUM_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_FORUM_ERRORS](context) {
    context.commit(CLEAR_PROJECT_FORUM_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_FORUM](state, projectForum) {
    state.project_forum = projectForum;
  },
  [SET_PROJECT_FORUM_LIST](state, projectForums) {
    state.project_forum_list = projectForums;
  },
  [CLEAR_PROJECT_FORUM](state) {
    state.project_forum = {};
  },
  [SET_PROJECT_FORUM_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_FORUM_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
