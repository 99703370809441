import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_LIST = "populateActivityList";
export const POPULATE_ACTIVITY_QUESTION_LIST = "populateActivityQuestionList";
export const POPULATE_LEGACY_ACTIVITY_LIST = "populateLegacyActivityList";
export const UPDATE_ELEMENTS = "updateElements";
export const UPDATE_SEQUENCED_ACTIVITY_LIST = "updateSequencedActivityList";
export const POPULATE_EXTENDED_ACTIVITY_LIST = "populateExtendedActivityList";
export const POPULATE_ACTIVITY_INFO = "populateActivityInfo";
export const POPULATE_LEGACY_ACTIVITY_INFO = "populateLegacyActivityInfo";
export const POPULATE_MARKUP_ACTIVITY_INFO = "populateMarkUpActivityInfo";
export const POPULATE_PARENT_ACTIVITY_INFO = "populateParentActivityInfo";
export const POPULATE_ACTIVITY_STATS = "populateActivityStats";
export const CREATE_PROJECT_ACTIVITY = "createProjectActivity";
export const DUPLICATE_PROJECT_ACTIVITY_SEQUENCE = "duplicateProjectActivitySequence";
export const UPDATE_PROJECT_ACTIVITY = "updateProjectActivity";
export const UPDATE_PROJECT_ACTIVITY_SEQUENCE = "updateProjectActivitySequence";
export const DELETE_PROJECT_ACTIVITY = "deleteProjectActivity";
export const DELETE_PROJECT_ACTIVITY_SEQUENCE = "deleteProjectActivitySequence";
export const CLEAR_ACTIVITY_LIST = "clearActivityList";
export const CLEAR_ACTIVITY_INFO = "clearActivityInfo";
export const CLEAR_PARENT_ACTIVITY_INFO = "clearParentActivityInfo";
export const CLEAR_ACTIVITY_ERRORS = "clearActivityErrors";
export const CLEAR_PARENT_ACTIVITY_ERRORS = "clearActivityErrors";

// mutation types
export const SET_ACTIVITY_LIST = "setActivityList";
export const SET_ACTIVITY_QUESTION_LIST = "setActivityQuetionList";
export const SET_LEGACY_ACTIVITY_LIST = "setLegacyActivityList";
export const UPDATE_SEQUENCE_LIST = "updateSequenceList";
export const RESET_ACTIVITY_LIST = "resetActivityList";
export const SET_ACTIVITY_INFO = "setActivityInfo";
export const SET_PARENT_ACTIVITY_INFO = "setParentActivityInfo";
export const RESET_ACTIVITY_INFO = "resetActivityInfo";
export const RESET_PARENT_ACTIVITY_INFO = "resetParentActivityInfo";
export const SET_ACTIVITY_ERRORS = "setActivityErrors";
export const RESET_ACTIVITY_ERRORS = "resetActivityErrors";
export const SET_PARENT_ACTIVITY_ERRORS = "setParentActivityErrors";
export const RESET_PARENT_ACTIVITY_ERRORS = "resetParentActivityErrors";
export const SET_ACTIVITY_STATS = "setActivityStats";
export const RESET_ACTIVITY_STATS = "resetActivityStats";
export const ADD_NEW_ACTIVITY = "addNewActivity";
export const UPDATE_ACTIVITY = "updateActivity";
export const REMOVE_ACTIVITY = "removeActivity";

const state = {
  activity_errors: {},
  parent_activity_errors: {},
  activity_list: [],
  legacy_activity_list: [],
  activity_info: [],
  parent_activity_info: [],
  activity_stats: {}
};

const getters = {
  activityErrors(state) {
    return state.activity_errors;
  },
  activityList(state) {
    return state.activity_list;
  },
  legacyActivityList(state) {
    return state.legacy_activity_list;
  },
  activityInfo(state) {
    return state.activity_info;
  },
  parentActivityInfo(state) {
    return state.parent_activity_info;
  },
  activityStats(state) {
    return state.activity_stats;
  }
};

const actions = {
  [POPULATE_ACTIVITY_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities", {
      params: payload.params
    })
      .then((res) => {
        const activities = res.data.data._embedded.activities;
        context.commit(SET_ACTIVITY_LIST, activities);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_QUESTION_LIST](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activities_questions", {
      params: payload.params
    })
      .then((res) => {
        const result = { activityId: payload.activityId, data: res.data };
        context.commit(SET_ACTIVITY_QUESTION_LIST, result);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_LEGACY_ACTIVITY_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/legacy_activities", {
      params: payload.params
    })
      .then((res) => {
        const activities = res.data;
        context.commit(SET_LEGACY_ACTIVITY_LIST, activities);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_EXTENDED_ACTIVITY_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/extended_activities", {
      params: payload.params
    })
      .then((res) => {
        const activities = res.data.data._embedded.activities;
        context.commit(SET_ACTIVITY_LIST, activities);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_MARKUP_ACTIVITY_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId, {
      params: payload.params
    })
      .then((res) => {
        const activity = res.data.data;
        return activity;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_ELEMENTS](context, payload) {
    context.commit(UPDATE_SEQUENCE_LIST, payload);
  },
  [UPDATE_SEQUENCED_ACTIVITY_LIST](context, payload) {
    return ApiService.put("api/v1/activities/" + payload.activityId + "/sequenced_activities", payload.data).catch((error) => {
      if (error.response) {
        context.commit(SET_ACTIVITY_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [POPULATE_ACTIVITY_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId, {
      params: payload.params
    })
      .then((res) => {
        const activity = res.data.data;
        context.commit(SET_ACTIVITY_INFO, activity);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_LEGACY_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_LEGACY_ACTIVITY_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/legacy_activities/" + payload.activityId, {
      params: payload.params
    })
      .then((res) => {
        const activity = res.data;
        context.commit(SET_ACTIVITY_INFO, activity);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_PARENT_ACTIVITY_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId, {
      params: payload.params
    })
      .then((res) => {
        const activity = res.data.data;
        context.commit(SET_PARENT_ACTIVITY_INFO, activity);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_PARENT_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_STATS](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/activity_stats", {
      params: {}
    }).then((res) => {
      const activityStats = res.data;
      context.commit(SET_ACTIVITY_STATS, activityStats);
    });
  },
  [CREATE_PROJECT_ACTIVITY](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/activities", payload.data)
      .then((res) => {
        context.commit(ADD_NEW_ACTIVITY, res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DUPLICATE_PROJECT_ACTIVITY_SEQUENCE](context, payload) {
    return ApiService.post("api/v1/activities/" + payload.activityId + "/activity_sequences", payload.data).then(() => {});
  },
  [UPDATE_PROJECT_ACTIVITY](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId, payload.data)
      .then((res) => {
        context.commit(UPDATE_ACTIVITY, res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_ACTIVITY_SEQUENCE](context, payload) {
    return ApiService.put("api/v1/activities/" + payload.activityId + "/activity_sequences", payload.data).then(() => {});
  },
  [DELETE_PROJECT_ACTIVITY](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + "/activities/" + payload.activityId)
      .then({})
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_ACTIVITY_SEQUENCE](context, payload) {
    return ApiService.delete("api/v1/activities/" + payload.activityId + "/activity_sequences").then(() => {});
  },
  [CLEAR_ACTIVITY_LIST](context) {
    context.commit(RESET_ACTIVITY_LIST);
  },
  [CLEAR_ACTIVITY_INFO](context) {
    context.commit(RESET_ACTIVITY_INFO);
  },
  [CLEAR_PARENT_ACTIVITY_INFO](context) {
    context.commit(RESET_PARENT_ACTIVITY_INFO);
  },
  [CLEAR_ACTIVITY_ERRORS](context) {
    context.commit(RESET_ACTIVITY_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_LIST](state, activities) {
    state.activity_list = activities;
  },
  [SET_ACTIVITY_QUESTION_LIST](state, activities_questions) {
    state.activity_list.forEach((activity) => {
      if (activity.id == activities_questions.activityId) {
        activity.child_questions = activities_questions.data;
      }
    });
  },
  [SET_LEGACY_ACTIVITY_LIST](state, legacyActivities) {
    state.legacy_activity_list = legacyActivities;
  },
  [UPDATE_SEQUENCE_LIST](state, payload) {
    state.activity_list.forEach((activity) => {
      if (activity.id == payload.activityId) {
        activity.child_activities = payload.data;
      }
    });
  },
  [RESET_ACTIVITY_LIST](state) {
    state.activity_list = [];
  },
  [SET_ACTIVITY_INFO](state, activity) {
    state.activity_info = activity;
  },
  [RESET_ACTIVITY_INFO](state) {
    state.activity_info = [];
  },
  [SET_PARENT_ACTIVITY_INFO](state, activity) {
    state.parent_activity_info = activity;
  },
  [RESET_PARENT_ACTIVITY_INFO](state) {
    state.parent_activity_info = [];
  },
  [ADD_NEW_ACTIVITY](state, activity) {
    state.activity_list.push(activity);
  },
  [UPDATE_ACTIVITY](state, activity) {
    let newActivityArray = [];
    newActivityArray.push(activity);
    state.activity_list = state.activity_list.map((obj) => newActivityArray.find((o) => o.id === obj.id) || obj);
  },
  [REMOVE_ACTIVITY](state, activityId) {
    let parentActivityId = null;

    state.activity_list.forEach((activity) => {
      if (activity.id == activityId) {
        parentActivityId = activity.sequence_parent;
      }
    });

    state.activity_list.forEach((activity) => {
      if (activity.id == parentActivityId) {
        activity.sequence_children--;
      }
    });

    state.activity_list = state.activity_list.filter(function (obj) {
      return obj.id !== activityId;
    });
  },
  [SET_ACTIVITY_ERRORS](state, errors) {
    state.activity_errors = errors;
  },
  [RESET_ACTIVITY_ERRORS](state) {
    state.activity_errors = {};
  },
  [SET_PARENT_ACTIVITY_ERRORS](state, errors) {
    state.parent_activity_errors = errors;
  },
  [RESET_PARENT_ACTIVITY_ERRORS](state) {
    state.parent_activity_errors = {};
  },
  [SET_ACTIVITY_STATS](state, activityStats) {
    state.activity_stats = activityStats;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
