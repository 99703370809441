import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_SYS_ADMINISTRATOR_LIST = "populateSysAdministratorList";
export const POPULATE_CLIENT_USER_LIST = "populateClientUserList";
export const CLEAR_USER_LIST = "clearUserList";
export const POPULATE_SYSTEM_ADMINISTRATOR_INFO = "populateUserInfo";
export const POPULATE_CLIENT_USER_INFO = "populateClientUserInfo";
export const CLEAR_USER_INFO = "clearUserInfo";
export const CREATE_SYS_ADMINISTRATOR = "createSysAdministrator";
export const CREATE_CLIENT_USER = "createClientUser";
export const CLEAR_USER_ERRORS = "clearUserErrors";
export const UPDATE_SYS_ADMINISTRATOR = "updateSysAdministrator";
export const UPDATE_CLIENT_USER = "updateClientUser";
export const PRELOAD_USER = "preloadUser";
export const ACTIVATE_USER = "activateUser";
export const SUSPEND_USER = "suspendUser";
export const REVOKE_SYS_ADMINISTRATOR_INVITE = "revokeSysAdministratorInvite";
export const REVOKE_CLIENT_USER_INVITE = "revokeClientUserInvite";
export const SEND_USER_RESET = "sendUserReset";
export const RESEND_USER_INVITE = "resendUserInvite";
export const RESEND_USER_INVITE_BULK = "resendUserInviteBulk";
export const BULK_DELETE_USERS = "bulkDeleteUsers";

// mutation types
export const SET_USER_LIST = "setUserList";
export const RESET_USER_LIST = "resetUserList";
export const SET_USER_INFO = "setUserInfo";
export const RESET_USER_INFO = "resetUserInfo";
export const SET_USER_ERRORS = "setUserErrors";
export const RESET_USER_ERRORS = "resetUserErrors";
export const SET_USER_PRELOAD = "setUserPreload";
export const RESET_USER_PRELOAD = "resetUserPreload";
export const UPDATE_USER_IN_LIST = "updateUserInList";
export const REMOVE_USER_IN_LIST = "removeUserInList";

const state = {
  errors: {},
  user_list: [],
  user_info: {},
  user_preload: {
    id: null
  }
};

const getters = {
  userErrors(state) {
    return state.errors;
  },
  userList(state) {
    return state.user_list;
  },
  userInfo(state) {
    return state.user_info;
  }
};

const actions = {
  [POPULATE_SYS_ADMINISTRATOR_LIST](context, payload) {
    return ApiService.get("api/v1/admin_users", payload)
      .then(res => {
        const users = res.data.data._embedded.users;
        context.commit(SET_USER_LIST, users);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_CLIENT_USER_LIST](context, payload) {
    return ApiService.get("api/v1/clients/" + payload.clientId + "/users", {
      params: payload.params
    })
      .then(res => {
        const users = res.data.data._embedded.users;
        context.commit(SET_USER_LIST, users);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_USER_LIST](context) {
    context.commit(CLEAR_USER_LIST);
  },
  [POPULATE_SYSTEM_ADMINISTRATOR_INFO](context, userId) {
    return ApiService.get("api/v1/admin_users/" + userId)
      .then(res => {
        const user = res.data.data;
        context.commit(SET_USER_INFO, user);
        context.commit(RESET_USER_PRELOAD);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_CLIENT_USER_INFO](context, payload) {
    return ApiService.get("api/v1/clients/" + payload.clientId + "/users/" + payload.userId)
      .then(res => {
        const user = res.data.data;
        context.commit(SET_USER_INFO, user);
        context.commit(RESET_USER_PRELOAD);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_USER_INFO](context) {
    context.commit(RESET_USER_INFO);
  },
  [CREATE_SYS_ADMINISTRATOR](context, payload) {
    return ApiService.post("api/v1/admin_users", payload)
      .then(res => {
        context.commit(SET_USER_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_CLIENT_USER](context, payload) {
    return ApiService.post("api/v1/clients/" + payload.clientId + "/users", payload.data)
      .then(res => {
        context.commit(SET_USER_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_USER_ERRORS](context) {
    context.commit(RESET_USER_ERRORS);
  },
  [UPDATE_SYS_ADMINISTRATOR](context, payload) {
    return ApiService.put("api/v1/admin_users/" + payload.userId, payload.data)
      .then(res => {
        context.commit(SET_USER_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_CLIENT_USER](context, payload) {
    return ApiService.put("api/v1/clients/" + payload.clientId + "/users/" + payload.userId, payload.data)
      .then(res => {
        context.commit(SET_USER_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_USER_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [PRELOAD_USER](context, userId) {
    context.commit(SET_USER_PRELOAD, userId);
  },
  [ACTIVATE_USER](context, payload) {
    return ApiService.put("api/v1/user_suspension/" + payload.userId, {
      suspended: 0
    })
    .then(res => {
      const update = {
        user: res.data.data,
        arrayPos: payload.arrayPos
      }
      context.commit(UPDATE_USER_IN_LIST, update);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [SUSPEND_USER](context, payload) {
    return ApiService.put("api/v1/user_suspension/" + payload.userId, {
      suspended: 1
    })
    .then(res => {
      const update = {
        user: res.data.data,
        arrayPos: payload.arrayPos
      }
      context.commit(UPDATE_USER_IN_LIST, update);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [REVOKE_SYS_ADMINISTRATOR_INVITE](context, payload) {
    return ApiService.delete("api/v1/admin_users/" + payload.userId)
    .then(() => {
      context.commit(REMOVE_USER_IN_LIST, payload.userId);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [REVOKE_CLIENT_USER_INVITE](context, payload) {
    return ApiService.delete("api/v1/clients/" + payload.clientId + "/users/" + payload.userId)
    .then(() => {
      context.commit(REMOVE_USER_IN_LIST, payload.userId);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [SEND_USER_RESET](context, userEmail) {
    return ApiService.post('auth/request-reset', {
      'email': userEmail,
      'origin': 'https://togthr.fun' //We may not need this
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [RESEND_USER_INVITE](context, payload) {
    return ApiService.post('auth/resend-invite', payload)
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [RESEND_USER_INVITE_BULK](context, payload) {
    return ApiService.post('auth/resend-invite-bulk', payload)
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [BULK_DELETE_USERS](context, payload) {
    return ApiService.post('api/v1/projects/' + payload.projectId + '/bulk_delete_users', payload)
    .catch(error => {
      if (error.response) {
        context.commit(SET_USER_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  }
};

const mutations = {
  [SET_USER_LIST](state, users) {
    state.user_list = users;
  },
  [RESET_USER_LIST](state) {
    state.user_list = [];
  },
  [SET_USER_INFO](state, user) {
    state.user_info = user;
  },
  [RESET_USER_INFO](state) {
    state.user_info = {};
  },
  [SET_USER_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_USER_ERRORS](state) {
    state.errors = {};
  },
  [SET_USER_PRELOAD](state, userId) {
    state.user_preload.id = userId;
  },
  [RESET_USER_PRELOAD](state) {
    state.user_preload.id = null;
  },
  [UPDATE_USER_IN_LIST](state, update) {
    state.user_list[update.arrayPos].suspended = update.user.suspended;
  },
  [REMOVE_USER_IN_LIST](state, userId) {
    state.user_list = state.user_list.filter(function( user ) {
      return user.id !== userId;
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
