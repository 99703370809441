<template>
  <tr
    v-if="!input_active && editIndex == null"
  >
    <td></td>
    <td colspan="3" class="px-0 py-3 d-table-cell">
      <a
        href="javascript:void(0)"
        class="text-dark-65 text-hover-primary mb-1 font-size-sm"
        @click="enableInput()"
      >
        <i class="fas fa-plus-circle font-size-sm mr-2"></i>
        {{ $t('GENERAL.BUTTONS.ADD_CHOICE') }}
      </a>
    </td>
  </tr>
  <tr
    v-else-if="input_active == true && editIndex == null"
    class="form-inline-row"
  >
    <td></td>
    <td colspan="1" class="px-2 py-3 ml-0 mr-2 d-table-cell">
      <div>
        <b-form inline v-on:submit.prevent="emitChoice()">
          <b-input
            class="mb-2 mr-sm-2 mb-sm-0 form-control-solid w-100 text-left"
            :class="{ 'is-invalid' : input_error == 1 }"
            :placeholder="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.CHOICE')"
            v-model="choice.choice"
            @keydown="input_error = false"
          ></b-input>
        </b-form>
      </div>
    </td>
    <td class="px-2">
      <InputGenericNestedDropdown
        id="input-places-in"
        :model="$v.choice.project_segment_id"
        :label="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.PLACES')"
        :groups="projectSegmentations"
        :options="projectSegments"
        groupLabel="name"
        optionLabel="name"
        :slim="true"
        optionForeign="project_segmentation_id"
        labelDisplay="0"
        :placeholder="$t('TABLES.EXTENDED_PROFILE_QUESTIONS.PLACES_PLACEHOLDER')"
      >
      </InputGenericNestedDropdown>
    </td>
    <td class="px-2 pt-4 text-right">
      <b-button
        variant="outline-primary"
        class="py-1 pl-3 pr-2 mr-2"
        @click="emitChoice()"
      >
        <i class="fas fa-check font-size-sm"></i>
      </b-button>
      <b-button
        variant="outline-danger"
        class="py-1 pl-3 pr-2"
        @click="disableSegmentInput()"
      >
        <i class="fas fa-times font-size-sm"></i>
      </b-button>
    </td>
  </tr>
</template>

<script>
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import InputGenericNestedDropdown from "@/view/components/form-inputs/GenericNestedDropdown";

export default {
  mixins: [validationMixin],
  
  name: "AddExtendedProfileQuestionRow",

  props: ['currentChoices', 'editIndex'],

  data() {
    return {
      input_active: false,
      input_error: 0,
      choice: {
        choice: null,
        project_segment_id: null,
        segmentName: null
      },
    };
  },

  validations: {
    pre_populate_toggle: {},
    choice: {
      choice: {},
      project_segment_id: {}
    }
  },

  components: {
    InputGenericNestedDropdown
  },

  methods: {
    enableInput() {
      this.input_active = true;
    },
    emitChoice() {
      if(this.choice.choice.length > 0) {
        if(this.currentChoices.filter(x => x.choice.toLowerCase() === this.choice.choice.toLowerCase()).length == 0) {
          if (this.choice.project_segment_id != null) {
            this.choice.segmentName = this.projectSegments.filter(x => x.id === this.choice.project_segment_id)[0].name;
          }
          this.$emit('choice_added',this.choice);
          this.resetInput();
          this.input_active = false;        
        } else {
          this.input_error = 1;
        }
      }
    },
    disableSegmentInput() {
      this.resetInput();
      this.input_active = false;
    },
    resetInput() {
      this.choice = {
        choice: null,
        project_segment_id: null
      };
    }
  },

  computed: {
    ...mapGetters([
      "projectSegmentations",
      "projectSegments",
    ]),
  }
}
</script>
