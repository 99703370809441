import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/clients/view",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/clients",
          name: "clients",
          redirect: "/clients/view",
          component: () => import("@/view/layout/Clients.vue"),
          children: [
            {
              path: "add",
              name: "add_client",
              component: () => import("@/view/pages/client_management/Manage.vue"),
              meta: { requiresAuth: true, scope: "core" }
            },
            {
              path: "view",
              name: "view_clients",
              component: () => import("@/view/pages/client_management/View.vue"),
              meta: { requiresAuth: true, scope: "core" }
            }
          ]
        },
        {
          path: "/client",
          name: "client",
          redirect: "/client/projects",
          component: () => import("@/view/layout/Client.vue"),
          children: [
            {
              path: "projects",
              name: "client_projects",
              component: () => import("@/view/pages/client_management/Projects.vue"),
              meta: { requiresAuth: true, scope: "client" }
            },
            {
              path: "edit",
              name: "edit_client",
              component: () => import("@/view/pages/client_management/Manage.vue"),
              meta: { requiresAuth: true, scope: "client" }
            },
            {
              path: "users",
              name: "client_users",
              component: () => import("@/view/pages/user_management/client_user/View.vue"),
              meta: { requiresAuth: true, scope: "client" }
            },
            {
              path: "add_user",
              name: "add_client_user",
              component: () => import("@/view/pages/user_management/client_user/Manage.vue"),
              meta: { requiresAuth: true, scope: "client" }
            },
            {
              path: "edit_user/:id",
              name: "edit_client_user",
              component: () => import("@/view/pages/user_management/client_user/Manage.vue"),
              meta: { requiresAuth: true, scope: "client" }
            },
            {
              path: "add_project",
              name: "add_client_project",
              component: () =>
                import("@/view/pages/project_management/Manage.vue"),
              meta: { requiresAuth: true, scope: 'client' }
            },
            {
              path: "templates",
              name: "view_project_templates",
              component: () =>
                import("@/view/pages/client/templates/View.vue"),
              meta: { requiresAuth: true, scope: 'client' }
            }
          ]
        },
        {
          path: "/users",
          name: "users",
          redirect: "/users/view",
          component: () => import("@/view/layout/Users.vue"),
          children: [
            {
              path: "add",
              name: "add_user",
              component: () => import("@/view/pages/user_management/system_administrator/Manage.vue"),
              meta: { requiresAuth: true, scope: "core" }
            },
            {
              path: "edit/:id",
              name: "edit_user",
              component: () => import("@/view/pages/user_management/system_administrator/Manage.vue"),
              meta: { requiresAuth: true, scope: "core" }
            },
            {
              path: "view",
              name: "view_users",
              component: () => import("@/view/pages/user_management/system_administrator/View.vue"),
              meta: { requiresAuth: true, scope: "core" }
            }
          ]
        },
        {
          path: "/templates",
          name: "templates",
          redirect: "/templates/view",
          component: () => import("@/view/layout/Templates.vue"),
          children: [
            {
              path: "view",
              name: "view_system_templates",
              component: () => import("@/view/pages/core/templates/View.vue"),
              meta: { requiresAuth: true, scope: "core" }
            }
          ]
        },
        {
          path: "/project",
          name: "project",
          redirect: "/project/view",
          component: () => import("@/view/layout/Project.vue"),
          children: [
            {
              path: "view",
              name: "view_project",
              component: () => import("@/view/pages/project_management/View.vue"),
              meta: { requiresAuth: true, scope: "project" }
            },
            {
              path: "edit/:id",
              name: "edit_project",
              component: () =>
                import("@/view/pages/project_management/Manage.vue"),
              meta: { requiresAuth: true, scope: 'project' }
            },
            {
              path: "edit_template/:id",
              name: "edit_template",
              component: () =>
                import("@/view/pages/project/template/Edit.vue"),
              meta: { requiresAuth: true, scope: 'project' }
            },
            {
              path: "setup",
              name: "setup_project",
              component: () => import("@/view/pages/project_management/Setup.vue"),
              meta: { requiresAuth: true, scope: "project" },
              children: [
                {
                  path: "segmentations",
                  name: "setup_segmentations",
                  component: () => import("@/view/pages/project_management/setup/Segmentations.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "basic_profiling",
                  name: "setup_basic_profiling",
                  component: () => import("@/view/pages/project_management/setup/BasicProfiling.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "extended_profiling",
                  name: "setup_extended_profiling",
                  component: () => import("@/view/pages/project_management/setup/ExtendedProfiling.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "widgets",
                  name: "setup_widgets",
                  component: () => import("@/view/pages/project_management/setup/Widgets.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "meta",
                  name: "setup_meta",
                  component: () => import("@/view/pages/project_management/setup/MetaInfo.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "theme/:tab?",
                  name: "setup_theme",
                  component: () => import("@/view/pages/project_management/setup/Theme.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "community_options",
                  name: "setup_community_options",
                  component: () => import("@/view/pages/project_management/setup/CommunityOptions.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_project_forum/:projectForum",
                  name: "edit_project_forum",
                  component: () => import("@/view/pages/project_management/setup/ManageProjectForum.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "rooms",
                  name: "setup_rooms",
                  component: () => import("@/view/pages/project_management/setup/Rooms.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_extended_profiling",
                  name: "add_extended_profiling",
                  component: () => import("@/view/pages/project_management/setup/extended_profiling/Manage.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_extended_profiling/:id",
                  name: "edit_extended_profiling",
                  component: () =>
                    import(
                      "@/view/pages/project_management/setup/extended_profiling/Manage.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
                {
                  path: "add_widget",
                  name: "add_widget",
                  component: () => 
                    import(
                      "@/view/pages/project_management/setup/widgets/Manage.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
                {
                  path: "edit_widget/:id",
                  name: "edit_widget",
                  component: () => 
                    import(
                      "@/view/pages/project_management/setup/widgets/Manage.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                }
              ]
            },
            {
              path: "users/add",
              name: "add_project_user",
              component: () => import("@/view/pages/user_management/project_user/Manage.vue"),
              meta: { requiresAuth: true, scope: "project" }
            },
            {
              path: "users/import/:type",
              name: "import_users",
              component: () => import("@/view/pages/user_management/project_user/Import.vue"),
              meta: { requiresAuth: true, scope: "project" }
            },
            {
              path: "users/edit/:id",
              name: "edit_project_user",
              component: () => import("@/view/pages/user_management/project_user/Manage.vue"),
              meta: { requiresAuth: true, scope: "project" }
            },
            {
              path: "users/:segment?/:status?",
              name: "project_users",
              component: () => import("@/view/pages/user_management/project_user/View.vue"),
              meta: { requiresAuth: true, scope: "project" }
            },
            {
              path: "activities",
              name: "project_activities",
              redirect: "/project/activities/view",
              component: () => import("@/view/pages/project_management/Activities.vue"),
              meta: { requiresAuth: true, scope: "project" },
              children: [
                {
                  path: "view",
                  name: "view_activities",
                  component: () => import("@/view/pages/project_management/activities/View.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_blog/:activity?",
                  name: "add_blog",
                  component: () => import("@/view/pages/project_management/activities/ManageBlog.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_blog/:activity",
                  name: "edit_blog",
                  component: () => import("@/view/pages/project_management/activities/ManageBlog.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_blog/:activity",
                  name: "duplicate_blog",
                  component: () => import("@/view/pages/project_management/activities/ManageBlog.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_diary/:activity?",
                  name: "add_diary",
                  component: () => import("@/view/pages/project_management/activities/ManageDiary.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_diary/:activity",
                  name: "edit_diary",
                  component: () => import("@/view/pages/project_management/activities/ManageDiary.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_diary/:activity",
                  name: "duplicate_diary",
                  component: () => import("@/view/pages/project_management/activities/ManageDiary.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_discussion/:activity?",
                  name: "add_discussion",
                  component: () => import("@/view/pages/project_management/activities/ManageDiscussion.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_discussion/:activity",
                  name: "edit_discussion",
                  component: () => import("@/view/pages/project_management/activities/ManageDiscussion.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_discussion/:activity",
                  name: "duplicate_discussion",
                  component: () => import("@/view/pages/project_management/activities/ManageDiscussion.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_survey/:activity?",
                  name: "add_survey",
                  component: () =>
                    import(
                      "@/view/pages/project_management/activities/ManageSurvey.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
                {
                  path: "edit_survey/:activity",
                  name: "edit_survey",
                  component: () =>
                    import(
                      "@/view/pages/project_management/activities/ManageSurvey.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
                {
                  path: "duplicate_survey/:activity",
                  name: "duplicate_survey",
                  component: () => import("@/view/pages/project_management/activities/ManageSurvey.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_picture_book/:activity?",
                  name: "add_picture_book",
                  component: () => import("@/view/pages/project_management/activities/ManagePictureBook.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_picture_book/:activity",
                  name: "edit_picture_book",
                  component: () => import("@/view/pages/project_management/activities/ManagePictureBook.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_picture_book/:activity",
                  name: "duplicate_picture_book",
                  component: () => import("@/view/pages/project_management/activities/ManagePictureBook.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_ideastorm/:activity?",
                  name: "add_ideastorm",
                  component: () => import("@/view/pages/project_management/activities/ManageIdeastorm.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_ideastorm/:activity",
                  name: "edit_ideastorm",
                  component: () => import("@/view/pages/project_management/activities/ManageIdeastorm.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_ideastorm/:activity",
                  name: "duplicate_ideastorm",
                  component: () => import("@/view/pages/project_management/activities/ManageIdeastorm.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_external",
                  name: "add_external",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_external/:activity",
                  name: "edit_external",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_external/:activity",
                  name: "duplicate_external",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_markup/:activity?",
                  name: "add_markup",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkup.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_markup/:activity",
                  name: "edit_markup",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkup.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_markup/:activity",
                  name: "duplicate_markup",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkup.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_markup_legacy/:activity?",
                  name: "add_markup_legacy",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkupLegacy.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_markup_legacy/:activity",
                  name: "edit_markup_legacy",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkupLegacy.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_markup_legacy/:activity",
                  name: "duplicate_markup_legacy",
                  component: () => import("@/view/pages/project_management/activities/ManageMarkupLegacy.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_poll",
                  name: "add_poll",
                  component: () => import("@/view/pages/project_management/activities/ManagePoll.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_poll/:activity",
                  name: "edit_poll",
                  component: () => import("@/view/pages/project_management/activities/ManagePoll.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_screen_recording",
                  name: "add_screen_recording",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_screen_recording/:activity",
                  name: "edit_screen_recording",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "duplicate_screen_recording/:activity",
                  name: "duplicate_screen_recording",
                  component: () => import("@/view/pages/project_management/activities/ManageExternal.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
              ]
            },
            {
              path: "pages",
              name: "project_pages",
              redirect: "/project/pages/summary",
              component: () => import("@/view/pages/project_management/Pages.vue"),
              meta: { requiresAuth: true, scope: "project" },
              children: [
                {
                  path: "summary",
                  name: "pages_summary",
                  component: () => import("@/view/pages/project_management/pages/Summary.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add",
                  name: "add_page",
                  component: () => import("@/view/pages/project_management/pages/Manage.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit/:page",
                  name: "edit_page",
                  component: () => import("@/view/pages/project_management/pages/Manage.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "add_section/:page",
                  name: "add_page_section",
                  component: () => import("@/view/pages/project_management/pages/ManageSection.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "edit_section/:page/:section",
                  name: "edit_page_section",
                  component: () => import("@/view/pages/project_management/pages/ManageSection.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                }
              ]
            },
            {
              path: "communicate",
              name: "project_communicate",
              component: () => import("@/view/pages/project_management/Communicate.vue"),
              meta: { requiresAuth: true, scope: "project" },
              children: [
                {
                  path: "view",
                  name: "communicate_summary",
                  component: () =>
                    import(
                      "@/view/pages/project_management/communicate/View.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
                {
                  path: "manage/:id?",
                  name: "manage_communication",
                  component: () =>
                    import(
                      "@/view/pages/project_management/communicate/Manage.vue"
                    ),
                  meta: { requiresAuth: true, scope: 'project' }
                },
              ]
            },
            {
              path: "review",
              name: "project_review",
              component: () => import("@/view/pages/project_management/Review.vue"),
              meta: { requiresAuth: true, scope: "project" },
              children: [
                {
                  path: "engagement",
                  name: "activity_engagement",
                  component: () => import("@/view/pages/project_management/review/ActivityEngagement.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "user_engagement",
                  name: "user_engagement",
                  component: () => import("@/view/pages/project_management/review/UserEngagement.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "stream/:id?",
                  name: "stream",
                  component: () => import("@/view/pages/project_management/review/Stream.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                },
                {
                  path: "text_analysis",
                  name: "text_analysis",
                  component: () => import("@/view/pages/project_management/review/TextAnalysis.vue"),
                  meta: { requiresAuth: true, scope: "project" }
                }
              ]
            },
            {
              path: "reports",
              name: "project_reports",
              component: () => import("@/view/pages/project_management/Reports.vue"),
              meta: { requiresAuth: true, scope: "project" }
            }
          ]
        },
        {
          path: "/user/:id",
          name: "user",
          redirect: "/user/:id/profile",
          component: () => import("@/view/layout/User.vue"),
          children: [
            {
              path: "profile",
              name: "user_profile",
              component: () => import("@/view/pages/user_management/Profile.vue"),
              meta: { requiresAuth: true }
            }
          ]
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/modules/together-authentication/layout/Auth2.vue"),
      children: [
        {
          path: "/login/:key?/:ps?/:pid?/:guid?/:variables?",
          name: "login",
          component: () => import("@/modules/together-authentication/pages/auth2/Login.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/register/:token",
          name: "register",
          component: () => import("@/modules/together-authentication/pages/auth2/Register.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/request_reset/:origin?",
          name: "request_reset",
          component: () => import("@/modules/together-authentication/pages/auth2/Request.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/reset_password/:token",
          name: "reset_password",
          component: () => import("@/modules/together-authentication/pages/auth2/Change.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/terms_and_conditions",
          name: "terms_and_conditions",
          component: () => import("@/modules/together-authentication/pages/auth2/Terms.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/privacy",
          name: "privacy",
          component: () => import("@/modules/together-authentication/pages/auth2/Privacy.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        },
        {
          path: "/help",
          name: "help",
          component: () => import("@/modules/together-authentication/pages/auth2/Help.vue"),
          meta: { requiresAuth: false, scope: "auth" }
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
