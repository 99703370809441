<template>
  <div
    ref="editable-slot"
    :id="`editable-slot-${slotNumber}`"
    class="fitb d-inline-flex mx-1"
    :contenteditable="false"
  >
    <div
      class="fitb-form d-flex justify-content-between align-items-center"
      :class="{ 'fitb-invalid' : submissionAttempted && isInvalid }"
    >
      <b-input-group class="mt-0">
        <b-form-input
          :id="`${slotNumber}-slot-placeholder`"
          v-model="slot.placeholder"
          :placeholder="slotPlaceholder"
          class="open-ended px-2"
          @keydown="formatInput($event)"
        >
        </b-form-input>
        <template #append>
          <b-dropdown class="single-choice px-1">
            <b-dropdown-form>
              <b-form-group @submit.stop.prevent>
                <draggable @end="reorderChoices" handle=".choice-reorder">
                  <template v-for="(choice, c) in slot.choices">
                    <div :key="c" class="fitb-form d-flex justify-content-between align-items-center">
                      <a
                        class="d-flex align-items-center"
                        href="javascript:void(0)"
                      >
                        <i class="choice-reorder fas fa-grip-vertical icon-md mx-2"></i>
                      </a>
                      <b-form-input
                        :ref="`choice-input-${c}`"
                        v-model="slot.choices[c]['choice']"
                        @update="clearEmptyChoice($event, c)"
                        class="choice-dropdown-input"
                      >
                      </b-form-input>
                    </div>
                  </template>
                </draggable>
                <div class="fitb-form add-choice d-flex justify-content-between align-items-center">
                  <i class="fas fa-plus icon-sm mx-2"></i>
                  <b-form-input
                    ref="choice-input-new"
                    v-model="newChoice"
                    :placeholder="choicePlaceholder"
                    class="choice-dropdown-input"
                    @update="newChoiceAdded($event)"
                  >
                  </b-form-input>
                </div>
              </b-form-group>
            </b-dropdown-form>
          </b-dropdown>
        </template>
      </b-input-group>
      <a
        class="d-flex align-items-center"
        href="javascript:void(0)"
        @click="deleteSlot"
      >
        <i class="fas fa-times invalid-indicator-icon icon-sm mx-2"></i>
      </a>
    </div>

    <b-tooltip
      v-if="submissionAttempted && isInvalid"
      :target="`editable-slot-${slotNumber}`"
      triggers="hover"
      placement="right"
    >
      {{ scTooltip }}
    </b-tooltip>
    &zwnj;
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: "fitbInputSingleChoice",
  
  props: {
    slotNumber: { type: Number, required: true },
    slotPlaceholder: { type: String, required: false, default: "Enter Placeholder" },
    choicePlaceholder: { type: String, required: false, default: "Add Choice" },
    initialPlaceholder: { type: String, required: false },
    initialChoices: { required: false },
    managementType: { type: String, required: true },
    scTooltip: { type: String, required: true }
  },

  data() {
    return {
      short_code: "SC",
      slot: {
        type: "SingleChoice",
        placeholder: "",
        choices: {}
      },
      newChoice: "",
      newChoiceCount: 1,
      submissionAttempted: false
    }
  },

  components: {
    draggable
  },

  methods: {
    clearEmptyChoice: function(text, choiceNumber) {
      if (text.length == 0) {
        delete this.slot.choices[choiceNumber];
        this.$refs['choice-input-new'].focus();
        this.forceChoiceUpdate();
      }
    },
    newChoiceAdded: function(text) {
      if (text.length > 0) {
        let nextChoice = this.newChoiceCount;
        this.slot.choices[nextChoice] = {
          choice: text,
          id: null
        };
        const self = this;
        this.forceChoiceUpdate().then(() => {
          self.$refs[`choice-input-${nextChoice}`][0].focus();
          self.newChoice = "";
          self.newChoiceCount++;
        })
      }
    },
    forceChoiceUpdate: async function() {
      this.$forceUpdate();
      await this.$nextTick();
    },
    deleteSlot: function() {
      document.getElementById(`editable-slot-${this.slotNumber}`).remove();
    },
    reorderChoices: function(evt) {
      let oldPosition = evt.oldIndex;
      let newPosition = evt.newIndex;

      let oldAttribute = parseInt(Object.keys(this.slot.choices)[oldPosition]);
      let newAttribute = parseInt(Object.keys(this.slot.choices)[newPosition]);

      let oldValue = this.slot.choices[oldAttribute];
      let newValue = this.slot.choices[newAttribute];

      this.slot.choices[oldAttribute] = newValue;
      this.slot.choices[newAttribute] = oldValue;
    },
    getData: function() {
      return this.slot;
    },
    formatInput: function (value) {
      if (!/[a-zA-Z0-9_ \s]/.test(value.key)) {
        this.ignoredValue = value.key ? value.key : "";
        value.preventDefault();
      }
    }
  },

  watch: {
    initialPlaceholder: {
      handler(newPlaceholder) {
        if (newPlaceholder != undefined) {
          this.slot.placeholder = newPlaceholder          
        }
      },
      immediate: true
    },
    initialChoices: {
      handler(newChoices) {
        const self = this;
        if (typeof newChoices != "undefined") {
          if (!Array.isArray(newChoices)) {
            newChoices = Object.values(newChoices);
          }
          if (Array.isArray(newChoices)) {
            newChoices.forEach(function (choice) {
              self.slot.choices[self.newChoiceCount] = {
                choice: choice.choice,
                id: choice.id
              };
              self.newChoiceCount++;
            })
          }
        }
      },
      immediate: true
    }
  },

  computed: {
    choicesArray: function() {
      return Object.keys(this.slot.choices);
    },
    hasDuplicateChoices: function() {
      let choices = Object.values(this.slot.choices);
      let uniqueSlotChoices = choices.filter(
        (obj, index) =>
          choices.findIndex((item) => item.choice === obj.choice) === index
      );
      return (uniqueSlotChoices.length < choices.length);
    },
    isInvalid: function() {
      this.newChoiceCount;
      return (this.slot.placeholder.length == 0 || Object.keys(this.slot.choices).length < 2 || this.hasDuplicateChoices) ? true : false;
    }
  }
}
</script>