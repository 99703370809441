<template>
  <div>
    <template v-for="(group) in settingsStructure">
      <div
        v-if="shouldDisplayGroup(group)"
        :key="group.id"
        class="col-6 px-0"
      >
        <h3
          class="font-size-lg text-dark font-weight-bold mb-6 mt-10 px-0"  
        >
          {{ $t(`FORMS.SECTION_HEADERS.ACTIVITY_QUESTION_SETTINGS_GROUP_${group.id}`) }}
        </h3>
        <GenericCheckboxGroup
          :id="`activity-question-settings-group-${group.id}`"
          :options="group.settings"
          :model="model"
          :submissionStates="submissionStates"
          :stateValidation="false"
        >
        </GenericCheckboxGroup>
      </div>
    </template>
    <div class="custom-validation-failure mb-7" v-if="model.$error">
      {{ $t('FORMS.GENERIC_ERRORS.REQUIRED') }}
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import GenericCheckboxGroup from "@/modules/together-helpers/components/generic-inputs/GenericCheckboxGroup";

export default {
  name: "ActivityQuestionSettings",

  props: {
    submissionStates: { type: Object, required: true },
    eliglbeQuestionSettings: { type: Array, required: true },
    model: { type: Object, required: true }
  },

  components: {
    GenericCheckboxGroup
  },

  methods: {
    shouldDisplayGroup: function(group) {
      return group.id != 2 || (group.id == 2 && this.model.$model.includes(2))
    } 
  },

  computed: {
    ...mapGetters([
      "systemActivityQuestionSettings"
    ]),
    componentId: function() {
      return (this.group != undefined) ? `activity-question-settings` : `activity-question-settings-group-${this.group}`
    },
    settingsStructure: function() {
      let returnStructure = [];
      let objectStructure = {};

      const self = this;
      this.eliglbeQuestionSettings.forEach(function(setting) {
        if (objectStructure[setting.group] == undefined) {
          objectStructure[setting.group] = [];
        }
        
        objectStructure[setting.group].push({ value: setting.id, text: `${self.$t(setting.translation)}` });
      })

      for (const property in objectStructure) {
        returnStructure.push({ id: `${property}`, settings: objectStructure[property] });
      }

      return returnStructure;
    }
  }
}
</script>