import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_EXTERNAL_RESPONSES_LIST = "populateActivityExternalRepsonsesList";
export const POPULATE_ACTIVITY_EXTERNAL_RESPONSES_INFO = "populateActivityExternalRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_EXTERNAL_RESPONSES_INFO = "populateActivityAdditionalExternalRepsonsesInfo";
export const POPULATE_ACTIVITY_EXTERNAL_RESPONSES_FILTER = "getActivityExternalResponsesFilter";
export const CREATE_EXTERNAL_RESPONSES = "createExternalRepsonses";
export const CREATE_EXTERNAL_RESPONSES_VOTE = "createExternalRepsonsesVote";
export const UPDATE_EXTERNAL_RESPONSES = "updateExternalRepsonses";
export const DELETE_EXTERNAL_RESPONSES = "deleteExternalRepsonses";
export const CLEAR_EXTERNAL_RESPONSES_LIST = "clearExternalRepsonsesList";
export const CLEAR_EXTERNAL_RESPONSES_INFO = "clearExternalRepsonsesInfo";
export const CLEAR_EXTERNAL_RESPONSES_ERRORS = "clearExternalRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_EXTERNAL_RESPONSES_LIST = "setActivityExternalRepsonsesList";
export const RESET_ACTIVITY_EXTERNAL_RESPONSES_LIST = "resetActivityExternalRepsonsesList";
export const SET_ACTIVITY_EXTERNAL_RESPONSES_INFO = "setActivityExternalRepsonsesInfo";
export const SET_ACTIVITY_EXTERNAL_RESPONSES_FILTER = "setActivityExternalResponsesFilter";
export const RESET_ACTIVITY_EXTERNAL_RESPONSES_FILTER = "resetActivityExternalResponsesFilter";
export const RESET_ACTIVITY_EXTERNAL_RESPONSES_INFO = "resetActivityExternalRepsonsesInfo";
export const APPEND_ACTIVITY_EXTERNAL_RESPONSES_LIST = "appendActivityExternalRepsonsesInfo";
export const ADD_ACTIVITY_EXTERNAL_RESPONSES = "addActivityExternalRepsonses";
export const UPDATE_ACTIVITY_EXTERNAL_RESPONSES = "updateActivityExternalRepsonses";
export const REMOVE_ACTIVITY_EXTERNAL_RESPONSES = "removeActivityExternalRepsonses";
export const SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS = "setActivityExternalRepsonsesErrors";
export const RESET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS = "resetActivityExternalRepsonsesErrors";

const state = {
  activity_external_responses_errors: {},
  activity_external_responses_list: [],
  activity_external_responses_info: [],
  activity_external_responses_filter: ""
};

const getters = {
  activityExternalRepsonsesErrors(state) {
    return state.activity_external_responses_errors;
  },
  activityExternalRepsonsesList(state) {
    return state.activity_external_responses_list;
  },
  activityExternalRepsonsesInfo(state) {
    return state.activity_external_responses_info;
  },
  activityExternalResponsesFilter(state) {
    return state.activity_external_responses_filter;
  }
};

const actions = {
  [POPULATE_ACTIVITY_EXTERNAL_RESPONSES_LIST](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_external_responses", {
      params: payload.params
    })
      .then((res) => {
        const responses = res.data.data._embedded.activity_external_responses;
        context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_LIST, responses);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_EXTERNAL_RESPONSES_INFO](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_external_responses" + payload.activityExternalResponseId, {
      params: payload.params
    })
      .then((res) => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_INFO, response);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_EXTERNAL_RESPONSES_INFO](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_external_responses", {
      params: payload.params
    })
      .then((res) => {
        const responses = res.data.data._embedded.activity_external_responses;
        context.commit(APPEND_ACTIVITY_EXTERNAL_RESPONSES_LIST, payload, responses);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_EXTERNAL_RESPONSES_FILTER](context, payload) {
    if (payload) {
      return context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_FILTER, payload);
    }
  },
  [CREATE_EXTERNAL_RESPONSES](context, payload) {
    return ApiService.post("api/v1/activities/" + payload.activityId + "/activity_external_responses", payload.data)
      .then((res) => {
        context.commit(ADD_ACTIVITY_EXTERNAL_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_EXTERNAL_RESPONSES_VOTE](context, payload) {
    return ApiService.post("api/v1/activity_external_responses/" + payload.activityExternalResponseId + "/activity_external_votes", payload.data)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_EXTERNAL_RESPONSES](context, payload) {
    return ApiService.put("api/v1/activities/" + payload.activityId + "/activity_external_responses" + payload.activityExternalResponseId, payload.data)
      .then((res) => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_INFO, response);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_EXTERNAL_RESPONSES](context, payload) {
    return ApiService.delete("api/v1/activities/" + payload.activity_id + "/activity_external_responses/" + payload.id)
      .then((res) => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch((error) => {
        if (error.response) {
          context.commit(SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_EXTERNAL_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_EXTERNAL_RESPONSES_LIST);
  },
  [CLEAR_EXTERNAL_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_EXTERNAL_RESPONSES_INFO);
  },
  [CLEAR_EXTERNAL_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_EXTERNAL_RESPONSES_LIST](state, activity_external_responses_list) {
    state.activity_external_responses_list = activity_external_responses_list;
  },
  [RESET_ACTIVITY_EXTERNAL_RESPONSES_LIST](state) {
    state.activity_external_responses_list = [];
  },
  [SET_ACTIVITY_EXTERNAL_RESPONSES_INFO](state, activity_external_responses_info) {
    state.activity_external_responses_info = activity_external_responses_info;
  },
  [RESET_ACTIVITY_EXTERNAL_RESPONSES_INFO](state) {
    state.activity_external_responses_info = [];
  },
  [SET_ACTIVITY_EXTERNAL_RESPONSES_FILTER](state, filter) {
    state.activity_external_responses_filter = filter;
  },
  [RESET_ACTIVITY_EXTERNAL_RESPONSES_FILTER](state) {
    state.activity_external_responses_filter = "";
  },
  [ADD_ACTIVITY_EXTERNAL_RESPONSES](state, activity_external_responses_info) {
    state.activity_external_responses_list.push(activity_external_responses_info);
  },
  [UPDATE_ACTIVITY_EXTERNAL_RESPONSES](state, activity_external_responses_info) {
    let newActivityExternalRepsonsesArray = [];
    newActivityExternalRepsonsesArray.push(activity_external_responses_info);
    state.activity_external_responses_list = state.activity_external_responses_list.map((obj) => newActivityExternalRepsonsesArray.find((o) => o.id === obj.id) || obj);
  },
  [APPEND_ACTIVITY_EXTERNAL_RESPONSES_LIST](state, payload, activity_external_responses_additional_list) {
    let responseToIndex = state.activity_external_responses_list.findIndex((p) => p.id == payload.responding_to); // to be update
    if (responseToIndex !== -1) {
      state.activity_external_responses_list = state.activity_external_responses_list.splice(responseToIndex, 0, activity_external_responses_additional_list);
    } else {
      state.activity_external_responses_list.push(activity_external_responses_additional_list);
    }
  },
  [REMOVE_ACTIVITY_EXTERNAL_RESPONSES](state, activityExternalResponseId) {
    state.activity_external_responses_list = state.activity_external_responses_list.filter(function(obj) {
      return obj.id !== activityExternalResponseId;
    });
  },
  [SET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS](state, activity_external_responses_errors) {
    state.activity_external_responses_errors = activity_external_responses_errors;
  },
  [RESET_ACTIVITY_EXTERNAL_RESPONSES_ERRORS](state) {
    state.activity_external_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
