<template>
  <div class="row">
    <div class="border-0">
      <div class="card-title d-flex w-100">
        <h6>{{ $t("FORMS.INPUTS.ACTIVITY_CATEGORY_TITLE") + "*" }}</h6>
      </div>
      <p class="mb-10"><span v-html="description"></span></p>
      <div v-if="model.length == 0" class="d-flex justify-content-center w-100">
        <a href="javascript:void(0)" @click="add(model.length)">
          <b-alert class="bg-hover-secondary" show variant="light">
            {{ $t('TABLES.ACTIVITY_MESSAGES.NO_CATEGORIES') }}
          </b-alert>
        </a>
      </div>
    </div>

    <b-form-group id="input-activity-category-group" class="col-xl-12 px-0">
      <li class="list-group-item d-flex justify-content-start align-items-start" v-for="(element, idx) in model" :key="idx">
        <div class="w-5">
          <span class="text pl-2">{{ idx + 1 }}</span>
        </div>
        <input
          :ref="'input-' + idx"
          type="text"
          class="form-control ml-5"
          @keyup.enter="add(idx + 1)"
          v-model="element.name"
        />
        <i class="fa fa-times close" @click="removeAt(idx)"></i>
      </li>
    </b-form-group>
    <div v-if="model.length != 0" class="d-flex justify-content-center w-100">
      <a href="javascript:void(0)" @click="add(model.length)">
        <b-alert class="bg-hover-secondary" show variant="light">
          {{ $t('SETUP.SEGMENTATIONS.ADD_ANOTHER') }}
        </b-alert>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivityCategories",

  props: {
    model: { type: Array, required: true }
  },
  
  computed: {
    description() {
      let desc = this.$t("FORMS.INPUTS.ACTIVITY_CATEGORY_DESC");
      let replaceHere = this.$t("FORMS.INPUTS.ACTIVITY_CATEGORY_DESC_LINK");

      return desc.replace(
        replaceHere,
        '<a href="/" target="_blank">' + replaceHere + "</a>"
      );
    }
  },

  methods: {
    removeAt(idx) {
      this.model.splice(idx, 1);
    },
    add: function(idx = 0) {
      this.model.push({ name: "" });

      this.focusInput(idx);
    },
    focusInput(idx) {
      setTimeout(() => this.$refs['input-' + idx][0].focus(), 100);
    }
  }
};
</script>

<style scoped>
.text,
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  margin-left: auto;
  order: 2;
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900 !important;
}

input {
  display: inline-block;
  width: 50%;
}
</style>
