import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_TAG_ENGAGEMENTS_LIST =
  "populateActivityTagEngagementsList";
export const POPULATE_LEGACY_TAG_ENGAGEMENTS_LIST =
  "populateLegacyTagEngagementsList";
export const POPULATE_ACTIVITY_TAG_ENGAGEMENTS_INFO =
  "populateActivityTagEngagementsInfo";
export const CREATE_TAG_ENGAGEMENTS = "createTagEngagements";
export const UPDATE_TAG_ENGAGEMENTS = "updateTagEngagements";
export const DELETE_TAG_ENGAGEMENTS = "deleteTagEngagements";
export const CLEAR_TAG_ENGAGEMENTS_LIST = "clearTagEngagementsList";
export const CLEAR_TAG_ENGAGEMENTS_INFO = "clearTagEngagementsInfo";
export const CLEAR_TAG_ENGAGEMENTS_ERRORS = "clearTagEngagementsErrors";

// mutation types
export const SET_ACTIVITY_TAG_ENGAGEMENTS_LIST =
  "setActivityTagEngagementsList";
export const SET_LEGACY_TAG_ENGAGEMENTS_LIST =
  "setLegacyTagEngagementsList";
export const RESET_ACTIVITY_TAG_ENGAGEMENTS_LIST =
  "resetActivityTagEngagementsList";
export const SET_ACTIVITY_TAG_ENGAGEMENTS_INFO =
  "setActivityTagEngagementsInfo";
export const RESET_ACTIVITY_TAG_ENGAGEMENTS_INFO =
  "resetActivityTagEngagementsInfo";
export const ADD_ACTIVITY_TAG_ENGAGEMENTS = "addActivityTagEngagements";
export const UPDATE_ACTIVITY_TAG_ENGAGEMENTS = "updateActivityTagEngagements";
export const REMOVE_ACTIVITY_TAG_ENGAGEMENTS = "removeActivityTagEngagements";
export const SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS =
  "setActivityTagEngagementsErrors";
export const RESET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS =
  "resetActivityTagEngagementsErrors";

const state = {
  activity_tag_engagements_errors: {},
  activity_tag_engagements_list: [],
  legacy_tag_engagements_list: [],
  activity_tag_engagements_info: []
};

const getters = {
  activityTagEngagementsErrors(state) {
    return state.activity_tag_engagements_errors;
  },
  activityTagEngagementsList(state) {
    return state.activity_tag_engagements_list;
  },
  legacyTagEngagementsList(state) {
    return state.legacy_tag_engagements_list;
  },
  activityTagEngagementsInfo(state) {
    return state.activity_tag_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_TAG_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_tags",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_response_tags;
        context.commit(SET_ACTIVITY_TAG_ENGAGEMENTS_LIST, responses);
        return responses;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_LEGACY_TAG_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId + "/legacy_response_tags",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data;
        context.commit(SET_ACTIVITY_TAG_ENGAGEMENTS_LIST, responses);
        return responses;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_TAG_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_tags/" +
        payload.activityTagEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_TAG_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_TAG_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_response_tags",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_TAG_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_TAG_ENGAGEMENTS](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_tags" +
        payload.activityTagEngagementId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_TAG_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_TAG_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_tags/" +
        payload.activityTagEngagementId
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_TAG_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_TAG_ENGAGEMENTS_LIST);
  },
  [CLEAR_TAG_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_TAG_ENGAGEMENTS_INFO);
  },
  [CLEAR_TAG_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_TAG_ENGAGEMENTS_LIST](state, activity_tag_engagements_list) {
    state.activity_tag_engagements_list = activity_tag_engagements_list;
  },
  [SET_LEGACY_TAG_ENGAGEMENTS_LIST](state, legacy_tag_engagements_list) {
    state.legacy_tag_engagements_list = legacy_tag_engagements_list;
  },
  [RESET_ACTIVITY_TAG_ENGAGEMENTS_LIST](state) {
    state.activity_tag_engagements_list = [];
  },
  [SET_ACTIVITY_TAG_ENGAGEMENTS_INFO](state, activity_tag_engagements_info) {
    state.activity_tag_engagements_info = activity_tag_engagements_info;
  },
  [RESET_ACTIVITY_TAG_ENGAGEMENTS_INFO](state) {
    state.activity_tag_engagements_info = [];
  },
  [ADD_ACTIVITY_TAG_ENGAGEMENTS](state, activity_tag_engagements_info) {
    state.activity_tag_engagements_list.push(activity_tag_engagements_info);
  },
  [UPDATE_ACTIVITY_TAG_ENGAGEMENTS](state, activity_tag_engagements_info) {
    let newActivityTagEngagementsArray = [];
    newActivityTagEngagementsArray.push(activity_tag_engagements_info);
    state.activity_tag_engagements_list = state.activity_tag_engagements_list.map(
      obj => newActivityTagEngagementsArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_ACTIVITY_TAG_ENGAGEMENTS](state, activityTagEngagementId) {
    state.activity_tag_engagements_list = state.activity_tag_engagements_list.filter(
      function(obj) {
        return obj.id !== activityTagEngagementId;
      }
    );
  },
  [SET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS](
    state,
    activity_tag_engagements_errors
  ) {
    state.activity_tag_engagements_errors = activity_tag_engagements_errors;
  },
  [RESET_ACTIVITY_TAG_ENGAGEMENTS_ERRORS](state) {
    state.activity_tag_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
