const state = {
  form: {
    stage: 1,
    finishedUploads: 0,
    batch: {
      systemProjectRole: 1,
      systemProjectUserSample: 1,
      segments: [],
      users: ""
    },
    newUser: {
      email: "",
      system_project_role_id: 1,
      system_project_user_sample_id: 1,
      project_segments: [],
      name: "invited_user",
      suspended: 1
    },
    processedUsers: []
  }
};

const getters = {
  importWorkflowForm(state) {
    return state.form;
  }
};

const actions = {};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};