import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_BLOG_RESPONSES_LIST =
  "populateActivityBlogRepsonsesList";
export const POPULATE_ACTIVITY_BLOG_RESPONSES_INFO =
  "populateActivityBlogRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_BLOG_RESPONSES_INFO =
  "populateActivityAdditionalBlogRepsonsesInfo";
export const CREATE_BLOG_RESPONSES = "createBlogRepsonses";
export const UPDATE_BLOG_RESPONSES = "updateBlogRepsonses";
export const DELETE_BLOG_RESPONSES = "deleteBlogRepsonses";
export const CLEAR_BLOG_RESPONSES_LIST = "clearBlogRepsonsesList";
export const CLEAR_BLOG_RESPONSES_INFO = "clearBlogRepsonsesInfo";
export const CLEAR_BLOG_RESPONSES_ERRORS = "clearBlogRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_BLOG_RESPONSES_LIST = "setActivityBlogRepsonsesList";
export const RESET_ACTIVITY_BLOG_RESPONSES_LIST =
  "resetActivityBlogRepsonsesList";
export const SET_ACTIVITY_BLOG_RESPONSES_INFO = "setActivityBlogRepsonsesInfo";
export const RESET_ACTIVITY_BLOG_RESPONSES_INFO =
  "resetActivityBlogRepsonsesInfo";
export const APPEND_ACTIVITY_BLOG_RESPONSES_LIST =
  "appendActivityBlogRepsonsesInfo";
export const ADD_ACTIVITY_BLOG_RESPONSES = "addActivityBlogRepsonses";
export const UPDATE_ACTIVITY_BLOG_RESPONSES = "updateActivityBlogRepsonses";
export const REMOVE_ACTIVITY_BLOG_RESPONSES = "removeActivityBlogRepsonses";
export const SET_ACTIVITY_BLOG_RESPONSES_ERRORS =
  "setActivityBlogRepsonsesErrors";
export const RESET_ACTIVITY_BLOG_RESPONSES_ERRORS =
  "resetActivityBlogRepsonsesErrors";

const state = {
  activity_blog_responses_errors: {},
  activity_blog_responses_list: [],
  activity_blog_responses_info: []
};

const getters = {
  activityBlogRepsonsesErrors(state) {
    return state.activity_blog_responses_errors;
  },
  activityBlogRepsonsesList(state) {
    return state.activity_blog_responses_list;
  },
  activityBlogRepsonsesInfo(state) {
    return state.activity_blog_responses_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_BLOG_RESPONSES_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_blog_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_blog_responses;
        context.commit(SET_ACTIVITY_BLOG_RESPONSES_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_BLOG_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_blog_responses" +
        payload.activityBlogResponseId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_BLOG_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_BLOG_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_blog_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_blog_responses;
        context.commit(APPEND_ACTIVITY_BLOG_RESPONSES_LIST, payload, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_BLOG_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_blog_responses",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_BLOG_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_BLOG_RESPONSES](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_blog_responses" +
        payload.activityBlogResponseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_BLOG_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_BLOG_RESPONSES](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_blog_responses/" +
        payload.id
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_BLOG_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_BLOG_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_BLOG_RESPONSES_LIST);
  },
  [CLEAR_BLOG_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_BLOG_RESPONSES_INFO);
  },
  [CLEAR_BLOG_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_BLOG_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_BLOG_RESPONSES_LIST](state, activity_blog_responses_list) {
    state.activity_blog_responses_list = activity_blog_responses_list;
  },
  [RESET_ACTIVITY_BLOG_RESPONSES_LIST](state) {
    state.activity_blog_responses_list = [];
  },
  [SET_ACTIVITY_BLOG_RESPONSES_INFO](state, activity_blog_responses_info) {
    state.activity_blog_responses_info = activity_blog_responses_info;
  },
  [RESET_ACTIVITY_BLOG_RESPONSES_INFO](state) {
    state.activity_blog_responses_info = [];
  },
  [ADD_ACTIVITY_BLOG_RESPONSES](state, activity_blog_responses_info) {
    state.activity_blog_responses_list.push(activity_blog_responses_info);
  },
  [UPDATE_ACTIVITY_BLOG_RESPONSES](state, activity_blog_responses_info) {
    let newActivityBlogRepsonsesArray = [];
    newActivityBlogRepsonsesArray.push(activity_blog_responses_info);
    state.activity_blog_responses_list = state.activity_blog_responses_list.map(
      obj => newActivityBlogRepsonsesArray.find(o => o.id === obj.id) || obj
    );
  },
  [APPEND_ACTIVITY_BLOG_RESPONSES_LIST](
    state,
    payload,
    activity_blog_responses_additional_list
  ) {
    let responseToIndex = state.activity_blog_responses_list.findIndex(
      p => p.id == payload.responding_to
    ); // to be update
    if (responseToIndex !== -1) {
      state.activity_blog_responses_list = state.activity_blog_responses_list.splice(
        responseToIndex,
        0,
        activity_blog_responses_additional_list
      );
    } else {
      state.activity_blog_responses_list.push(
        activity_blog_responses_additional_list
      );
    }
  },
  [REMOVE_ACTIVITY_BLOG_RESPONSES](state, activityBlogResponseId) {
    state.activity_blog_responses_list = state.activity_blog_responses_list.filter(
      function(obj) {
        return obj.id !== activityBlogResponseId;
      }
    );
  },
  [SET_ACTIVITY_BLOG_RESPONSES_ERRORS](state, activity_blog_responses_errors) {
    state.activity_blog_responses_errors = activity_blog_responses_errors;
  },
  [RESET_ACTIVITY_BLOG_RESPONSES_ERRORS](state) {
    state.activity_blog_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
