import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "populateActivityReplyEngagementsList";
export const POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "populateActivityReplyEngagementsInfo";
export const CREATE_REPLY_ENGAGEMENTS = "createReplyEngagements";
export const UPDATE_REPLY_ENGAGEMENTS = "updateReplyEngagements";
export const DELETE_REPLY_ENGAGEMENTS = "deleteReplyEngagements";
export const CLEAR_REPLY_ENGAGEMENTS_LIST = "clearReplyEngagementsList";
export const CLEAR_REPLY_ENGAGEMENTS_INFO = "clearReplyEngagementsInfo";
export const CLEAR_REPLY_ENGAGEMENTS_ERRORS = "clearReplyEngagementsErrors";

// mutation types
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "setActivityReplyEngagementsList";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST =
  "resetActivityReplyEngagementsList";
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "setActivityReplyEngagementsInfo";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO =
  "resetActivityReplyEngagementsInfo";
export const ADD_ACTIVITY_REPLY_ENGAGEMENTS = "addActivityReplyEngagements";
export const UPDATE_ACTIVITY_REPLY_ENGAGEMENTS = "updateActivityReplyEngagements";
export const REMOVE_ACTIVITY_REPLY_ENGAGEMENTS = "removeActivityReplyEngagements";
export const SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS =
  "setActivityReplyEngagementsErrors";
export const RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS =
  "resetActivityReplyEngagementsErrors";

const state = {
  activity_reply_engagements_errors: {},
  activity_reply_engagements_list: [],
  activity_reply_engagements_info: []
};

const getters = {
  activityReplyEngagementsErrors(state) {
    return state.activity_reply_engagements_errors;
  },
  activityReplyEngagementsList(state) {
    return state.activity_reply_engagements_list;
  },
  activityReplyEngagementsInfo(state) {
    return state.activity_reply_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_reply_engagements;
        context.commit(SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_REPLY_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_replies" +
        payload.activityReplyEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_response_replies",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_REPLY_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_replies/" +
        payload.activityReplyEngagementId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_REPLY_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_replies/" +
        payload.id
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_REPLY_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST);
  },
  [CLEAR_REPLY_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO);
  },
  [CLEAR_REPLY_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_LIST](state, activity_reply_engagements_list) {
    state.activity_reply_engagements_list = activity_reply_engagements_list;
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_LIST](state) {
    state.activity_reply_engagements_list = [];
  },
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_INFO](state, activity_reply_engagements_info) {
    state.activity_reply_engagements_info = activity_reply_engagements_info;
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_INFO](state) {
    state.activity_reply_engagements_info = [];
  },
  [ADD_ACTIVITY_REPLY_ENGAGEMENTS](state, activity_reply_engagements_info) {
    state.activity_reply_engagements_list.push(activity_reply_engagements_info);
  },
  [UPDATE_ACTIVITY_REPLY_ENGAGEMENTS](state, activity_reply_engagements_info) {
    let newActivityReplyEngagementsArray = [];
    newActivityReplyEngagementsArray.push(activity_reply_engagements_info);
    state.activity_reply_engagements_list = state.activity_reply_engagements_list.map(
      obj => newActivityReplyEngagementsArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_ACTIVITY_REPLY_ENGAGEMENTS](state, activityReplyEngagementId) {
    state.activity_reply_engagements_list = state.activity_reply_engagements_list.filter(
      function(obj) {
        return obj.id !== activityReplyEngagementId;
      }
    );
  },
  [SET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS](
    state,
    activity_reply_engagements_errors
  ) {
    state.activity_reply_engagements_errors = activity_reply_engagements_errors;
  },
  [RESET_ACTIVITY_REPLY_ENGAGEMENTS_ERRORS](state) {
    state.activity_reply_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
