import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_WIDGETS = "populateProjectWidgets";
export const POPULATE_PROJECT_WIDGET = "populateProjectWidget";
export const CREATE_PROJECT_WIDGET = "createProjectWidget";
export const UPDATE_PROJECT_WIDGET = "updateProjectWidget";
export const DELETE_PROJECT_WIDGET = "deleteProjectWidget";
export const UPDATE_PROJECT_WIDGET_ORDER = "UpdateProjectWidgetOrder";
export const CLEAR_PROJECT_WIDGET_LIST = "clearProjectWidgetList";
export const CLEAR_PROJECT_WIDGET_INFO = "clearProjectWidgetInfo";
export const CLEAR_PROJECT_WIDGET_ERRORS = "clearProjectWidgetErrors";

// mutation types
export const SET_PROJECT_WIDGET_LIST = "setProjectWidgetList";
export const RESET_PROJECT_WIDGET_LIST = "resetProjectWidgetList";
export const SET_PROJECT_WIDGET_INFO = "setProjectWidgetInfo";
export const RESET_PROJECT_WIDGET_INFO = "resetProjectWidgetInfo";
export const SET_PROJECT_WIDGET_ERRORS = "setProjectWidgetErrors";
export const RESET_PROJECT_WIDGET_ERRORS = "resetProjectWidgetErrors";
export const ADD_NEW_PROJECT_WIDGET = "addNewProjectWidget";
export const CHANGE_PROJECT_WIDGET = "changeProjectWidget";
export const REMOVE_PROJECT_WIDGET = "removeProjectWidget";


const state = {
  project_widget_list: [],
  project_widget_info: {},
  errors: {}
};

const getters = {
  projectWidgets(state) {
    return state.project_widget_list;
  },
  projectWidget(state) {
    return state.project_widget_info;
  },
  projectWidgetErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_WIDGETS](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/project_widgets", { params: payload.params }
    )
      .then(res => {
        const projectWidgets = res.data.data._embedded.project_widgets;
        context.commit(SET_PROJECT_WIDGET_LIST, projectWidgets);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PROJECT_WIDGET](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/project_widgets/" + payload.id, { params: payload.params }
    )
      .then(res => {
        const projectWidget = res.data.data;
        context.commit(SET_PROJECT_WIDGET_INFO, projectWidget);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_PROJECT_WIDGET](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + '/project_widgets', payload.data)
      .then(res => {
        context.commit(ADD_NEW_PROJECT_WIDGET, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_WIDGET](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/project_widgets/" + payload.id, payload.data)
      .then(res => {
        context.commit(CHANGE_PROJECT_WIDGET, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_WIDGET](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + "/project_widgets/" + payload.id)
      .then(res => {
        context.commit(REMOVE_PROJECT_WIDGET, payload.id);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_WIDGET_ORDER](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/order/widget_order", payload.data)
      .then(res => {
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_WIDGET_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_WIDGET_INFO](context) {
    context.commit(RESET_PROJECT_WIDGET_INFO);
  },
  [CLEAR_PROJECT_WIDGET_LIST](context) {
    context.commit(RESET_PROJECT_WIDGET_LIST);
  },
  [CLEAR_PROJECT_WIDGET_ERRORS](context) {
    context.commit(RESET_PROJECT_WIDGET_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_WIDGET_LIST](state, project_widgets) {
    state.project_widget_list = project_widgets;
  },
  [RESET_PROJECT_WIDGET_LIST](state) {
    state.project_widget_list = [];
  },
  [SET_PROJECT_WIDGET_INFO](state, project_widget) {
    state.project_widget_info = project_widget;
  },
  [RESET_PROJECT_WIDGET_INFO](state) {
    state.project_widget_info = [];
  },
  [SET_PROJECT_WIDGET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_WIDGET_ERRORS](state) {
    state.errors = {};
  },
  [ADD_NEW_PROJECT_WIDGET](state, project_widget) {
    state.project_widget_list.push(project_widget);
  },
  [CHANGE_PROJECT_WIDGET](state, project_widget) {
    let newProjectWidgetArray = [];
    newProjectWidgetArray.push(project_widget);
    state.project_widget_list = state.project_widget_list.map(obj => newProjectWidgetArray.find(o => o.id === obj.id) || obj);
  },
  [REMOVE_PROJECT_WIDGET](state, projectWidgetId) {
    state.project_widget_list = state.project_widget_list.filter(function( obj ) {
      return obj.id !== projectWidgetId;
    });
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};