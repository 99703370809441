<template>
  <div>
    <div class="segment-title d-flex py-5">
      <span class="fix-width w-50"></span>
      <span
        v-show="hasChoices"
        v-if="computedDropzoneShow"
        class="fix-width w-70 text-center"
        :class="systemAnswerTypeId == 5 ? 'pr-10' : null"
        >{{ $t("TABLES.ACTIVITY_QUESTIONS.STIMULI") }}</span
      >
      <span
        v-show="systemAnswerTypeId != 5 && hasChoices"
        class="fix-width w-50 pr-10"
        >{{ $t("FORMS.INPUTS.ACTIVITY_POLLS_CHOICES_TITLE") }}</span
      >
    </div>
    <b-form-group
      id="input-activity-choices-group"
      v-show="hasChoices"
      class="col-xl-12 px-0"
    >
      <draggable tag="ul" :list="computedChoices" class="list-group" handle=".handle">
        <li
          class="list-group-item d-flex justify-content-start align-items-start"
          v-for="(element, idx) in computedChoices"
          :key="idx"
        >
          <i class="fa fa-align-justify handle"></i>
          <div class="w-5">
            <span class="text mx-5">{{ idx + 1 }}.</span>
          </div>
          <input
            :ref="'input-' + idx"
            type="text"
            class="form-control w-30"
            v-model="element.choice"
            @keyup.enter="add(idx + 1)"
          />
          <div
            class="overflow-y-hidden px-10 d-flex justify-content-start w-70 flex-wrap"
            v-if="computedDropzoneShow"
          >
            <IntegratedDropzone
              class="col-xl-12 ml-10"
              :assetReservation="assetReservationData"
              :idx="idx"
              :projectAssets="element.stimulus_assets"
              :initialPreviews="element.previews"
              :wholeWidth="true"
              :element="element"
              :removable="true"
              @remove_project_asset="removeProjectAsset($event, idx)"
              @asset_reservation_created="element.stimuli.push($event)"
              @asset_reservation_deleted="removeAssetReservation($event, idx)"
              @previews_updated="element.previews = $event"
            >
            </IntegratedDropzone>
          </div>
          <b-form-select
            v-if="systemAnswerTypeId != 5"
            inline
            class="w-40 mx-10"
            v-model="element.project_segment_id"
            :options="prepareSegments(segments)"
          ></b-form-select>
          <i class="fa fa-times close" @click="removeAt(idx)"></i>
        </li>
      </draggable>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ computedError }}
    </div>
    <div v-if="hasChoices" class="d-flex justify-content-center w-100">
      <a v-if="hasChoices" href="javascript:void(0)" @click="add((computedChoices.length))">
        <b-alert class="bg-hover-secondary" show variant="light">
          {{ $t('GENERAL.BUTTONS.ADD_CHOICE') }}
        </b-alert>
      </a>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import IntegratedDropzone from "@/modules/together-helpers/components/generic-inputs/GenericIntegratedDropzone";

import { mapGetters } from "vuex";

export default {
  name: "ActivityQuestionChoices",

  props: {
    model: { type: Object, required: true },
    segments: { type: Array, required: true },
    projectId: { type: Number, required: true },
    systemAnswerTypeId: { type: Number, required: true },
    noStimuli: { type: Boolean, required: false },
    error: { type: String, required: false }
  },

  components: {
    draggable,
    IntegratedDropzone
  },

  mounted() {
    if (this.computedChoices.length == 0) {
      this.add();
    }
  },

  methods: {
    removeAt(idx) {
      this.computedChoices.splice(idx, 1);
    },
    add(idx = 0) {
      this.computedChoices.push({
        id: null,
        choice: "",
        project_segment_id: null,
        stimuli: [],
        previews: []
      });

      this.focusInput(idx);
    },
    focusInput(idx) {
      setTimeout(() => this.$refs['input-' + idx][0].focus(), 100);
    },
    prepareSegments(segments) {
      let options = [
        {
          value: null,
          text: this.$t("FORMS.INPUTS.ACTIVITY_POLLS_CHOICES_PLACEHOLDER")
        }
      ];
      segments.forEach(seg => {
        let grouped_options = [];
        seg.project_segments.forEach(segment => {
          grouped_options.push({
            value: segment.id,
            text: segment.name
          });
        });

        options.push({ label: seg.name, options: grouped_options });
      });
      return options;
    },
    removeProjectAsset($event, idx) {
      this.computedChoices[idx].stimuli_remove.push($event);

      let stimulusAsset = this.computedChoices[idx].stimulus_assets.find(
        stimulusAsset => stimulusAsset.id === $event
      );
      const index = this.computedChoices[idx].stimulus_assets.indexOf(stimulusAsset);

      if (index > -1) {
        this.computedChoices[idx].stimulus_assets.splice(index, 1);
      }
    },
    removeAssetReservation($event, idx) {
      this.computedChoices[idx].stimuli = this.computedChoices[idx].stimuli.filter(function(stim) {
        return stim !== $event;
      });
      this.computedChoices[idx].previews = this.computedChoices[idx].previews.filter(function(stim) {
        return stim.assetReservationId !== $event;
      });
    }
  },

  computed: {
    ...mapGetters(["assetErrors"]),
    computedChoices: function() {
      return this.model.$model;
    },
    computedDropzoneShow: function() {
      if (this.noStimuli != undefined) {
        if (this.noStimuli == true) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    computedError: function() {
      if (this.error != undefined) {
        return this.error;
      } else {
        return "";
      }
    },
    assetReservationData: function() {
      let data = {
        scope: "activity",
        data: {
          directory: "choice_stimuli",
          projectId: this.projectId,
          new: 1
        }
      };
      return data;
    },
    hasChoices() {
      if (this.computedChoices.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style scoped>
.text,
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  margin-left: auto;
  order: 2;
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 900 !important;
}

input {
  display: inline-block;
  width: 50%;
}
.overflow-y-hidden::-webkit-scrollbar {
  display: none;
}
.overflow-y-hidden {
  max-height: 450px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
