import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_MODERATED_WORD_LIST = "populateProjectModeratedWordList";
export const CLEAR_PROJECT_MODERATED_WORD = "clearProjectModeratedWord";
export const CREATE_PROJECT_MODERATED_WORD = "createProjectModeratedWord";
export const UPDATE_PROJECT_MODERATED_WORD = "updateProjectModeratedWord";
export const DELETE_PROJECT_MODERATED_WORD = "deleteProjectModeratedWord";
export const CLEAR_PROJECT_MODERATED_WORD_ERRORS =
  "clearProjectModeratedWordErrors";

// mutation types
export const SET_PROJECT_MODERATED_WORD_LIST = "setProjectModeratedWordList";
export const SET_PROJECT_MODERATED_WORD = "setProjectModeratedWord";
export const RESET_PROJECT_MODERATED_WORD = "resetProjectModeratedWord";
export const SET_PROJECT_MODERATED_WORD_ERRORS = "setProjectModeratedWordErrors";
export const RESET_PROJECT_MODERATED_WORD_ERRORS =
  "resetProjectModeratedWordErrors";

const state = {
  project_moderated_word: {},
  project_moderated_word_list: {},
  errors: {}
};

const getters = {
  projectModeratedWord(state) {
    return state.project_moderated_word;
  },
  projectModeratedWordList(state) {
    return state.project_moderated_word_list;
  },
  projectModeratedWordErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_MODERATED_WORD_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_moderated_words", {
          params: payload.params
        }
    )
      .then(res => {
        const projectModeratedWords = res.data.data._embedded.project_moderated_words;
        context.commit(SET_PROJECT_MODERATED_WORD_LIST, projectModeratedWords);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_MODERATED_WORD_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_MODERATED_WORD](context) {
    context.commit(CLEAR_PROJECT_MODERATED_WORD);
  },
  [CREATE_PROJECT_MODERATED_WORD](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/project_moderated_words",
      payload.data
    )
      .then(res => {
        const projectModeratedWord = res.data.data;
        context.commit(SET_PROJECT_MODERATED_WORD, projectModeratedWord);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_MODERATED_WORD_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_MODERATED_WORD](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/project_moderated_words/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(SET_PROJECT_MODERATED_WORD, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_MODERATED_WORD_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_MODERATED_WORD](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/project_moderated_words/" +
        payload.id
    )
      .then(() => {
        context.commit(CLEAR_PROJECT_MODERATED_WORD);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_MODERATED_WORD_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_MODERATED_WORD_ERRORS](context) {
    context.commit(CLEAR_PROJECT_MODERATED_WORD_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_MODERATED_WORD](state, projectModeratedWord) {
    state.project_moderated_word = projectModeratedWord;
  },
  [SET_PROJECT_MODERATED_WORD_LIST](state, projectModeratedWords) {
    state.project_moderated_word_list = projectModeratedWords;
  },
  [CLEAR_PROJECT_MODERATED_WORD](state) {
    state.project_moderated_word = {};
  },
  [SET_PROJECT_MODERATED_WORD_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_MODERATED_WORD_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
