import VuexHelpers from "../../helpers/VuexHelpers";

export const BULK_UPDATE_PROJECT_USER_SAMPLE = "bulkUpdateProjectUserSample";

const state = {};

const getters = {};

const actions = {
  [BULK_UPDATE_PROJECT_USER_SAMPLE](context, payload) {
    let url = `api/v1/projects/${payload.projectId}/bulk_change_user_source`;
    return VuexHelpers.create("ProjectTemplate", context, url, payload, false);
  }
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};