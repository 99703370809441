import VuexHelpers from "@/modules/together-helpers/helpers/VuexHelpers";

// action types
export const UPDATE_EVENT = "updateEvent";

// mutation types
export const SET_EVENT_LIST     = "setEventList";
export const SET_EVENT_ERRORS   = "setEventErrors";
export const RESET_EVENT        = "resetEvent";

const state = {
  event_list: null,
  user_event_list: null,
  event_errors: null,
};

const getters = {
  eventList(state) {
    return state.event_list;
  },
  userEventList(state) {
    return state.user_event_list;
  },
  eventErrors(state) {
    return state.event_errors;
  }
};

const actions = {
  [UPDATE_EVENT](context, payload) {
    let url = payload.url;
    return VuexHelpers.update("Event", context, url, payload);
  }
}

const mutations = {
  [SET_EVENT_LIST](state, event_list = null) {
    state.event_list = event_list;
  },
  [SET_EVENT_ERRORS](state, errors = null) {
    state.event_errors = errors;
  },
  [RESET_EVENT](state, updatedEvent) {
    VuexHelpers.reset(state, 'event', updatedEvent);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};