class ManageDiaryQuestion {

  //Suggests a key based on either a client or project name
  static topform(component) {
      return [
        {
          id: 1,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_GENERAL")}`,
          errors: component.tab1Errors,
          sections: [
            {
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericSelect",
                      on: {
                        show_special_html_editor: (value) => {
                          value == "8" ? (component.enable_fill_in_the_blanks = true) : (component.enable_fill_in_the_blanks = false);
                        }
                      },
                      properties: {
                        class: "col-xl-4 px-5 mb-0",
                        id: 'input-question-answer-type',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_SUMMARY")}`,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_POLL_ANSWER_TYPE")}`,
                        model: component.managementType == 'post' ? component.$v.form.system_answer_type_id : component.$v.editForm.system_answer_type_id,
                        submissionStates: component.submissionStates,
                        enableHandlerForFITBEditor: true,
                        items: [
                          {
                            value: 1,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_OE')}`
                          },
                          {
                            value: 2,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_MC')}`
                          },
                          {
                            value: 3,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_SC')}`
                          },
                          {
                            value: 8,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_FI')}`
                          },
                          {
                            value: 9,
                            text: `${component.$t('FORMS.INPUTS.ACTIVITY_ANSWER_TYPE_MU')}`
                          }
                        ]
                      }
                    }
                  ]
                },
                {
                  components: [
                    {
                      name: "InputGenericRichText",
                      show: (component.managementType == "post" && component.form.system_answer_type_id != 8) || (component.managementType == "update" && component.editForm.system_answer_type_id != 8),
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-question',
                        label: `${component.$t("FORMS.INPUTS.QUESTION")}`,
                        model: component.managementType == 'post' ? component.$v.form.question : component.$v.editForm.question,
                        submissionStates: component.submissionStates,
                        error: `${component.$t("FORMS.GENERIC_ERRORS.REQUIRED")}`,
                      }
                    },
                    {
                      name: "fitbEditor",
                      show: (component.managementType == "post" && component.form.system_answer_type_id == 8) || (component.managementType == "update" && component.editForm.system_answer_type_id == 8),
                      properties: {
                        ref: "input-question-fitb",
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-question-fitb',
                        label: `${component.$t("FORMS.INPUTS.QUESTION")}*`,
                        submissionAttempted: component.submissionAttempted,
                        model: component.model,
                        managementType: component.managementType,
                        index: component.editIndex
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_STIMULI")}`,
              rows: [
                {
                  components: [
                    {
                      name: "IntegratedDropzone",
                      on: {
                        asset_reservation_created: (value) => { component.$refs["diary-question-topform"].$emit('asset_reservation_created', value) },
                        asset_reservation_deleted: (value) => { component.$refs["diary-question-topform"].$emit('asset_reservation_deleted', value) },
                        remove_project_asset: (value) => { component.$refs["diary-question-topform"].$emit('remove_project_asset', value) },
                        previews_updated: (value) => { component.$refs["diary-question-topform"].$emit('previews_updated', value) },
                      },
                      properties: {
                        class: "col-xl-12 px-3 mb-0",
                        assetReservation: component.assetReservationData,
                        idx: 1,
                        projectAssets: component.managementType == 'update' ? component.editForm.stimulus_assets : [],
                        initialPreviews: component.managementType == 'post' ? component.form.previews : component.editForm.previews,
                        wholeWidth: true,
                        removable: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              show: component.eligibleQuestionSettings.length > 0,
              rows: [
                {
                  components: [
                    {
                      name: "ActivityQuestionSettings",
                      properties: {
                        class: "row col-xl-12 px-5 mx-0 mb-0",
                        submissionStates: component.submissionStates,
                        eliglbeQuestionSettings: component.eligibleQuestionSettings,
                        model: component.managementType == 'post' ? component.$v.form.settings : component.$v.editForm.settings,
                      }
                    }
                  ]
                }
              ]
            },
            {
              title: `${component.$t("FORMS.SECTION_HEADERS.ACTIVITY_ADDITIONAL_OPTIONS")}`,
              show: component.managementType == 'post' ? component.form.system_answer_type_id == 1 : component.editForm.system_answer_type_id == 1,
              rows: [
                {
                  components: [
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-allow-attachments',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.form.attachments_allowed : component.$v.editForm.attachments_allowed,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_ATTACHMENTS_ALLOWED")}`,
                      }
                    },
                    {
                      name: "InputGenericCheckbox",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-pre-populate-response',
                        submissionStates: component.submissionStates,
                        model: component.managementType == 'post' ? component.$v.prepopulate_toggle : component.$v.edit_prepopulate_toggle,
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_PREPOPULATE_RESPONSE")}`,
                      }
                    }
                  ]
                },
                {
                  show: component.managementType == 'post' ? component.prepopulate_toggle == 1 : component.edit_prepopulate_toggle == 1,
                  components: [
                    {
                      name: "InputGenericTextArea",
                      properties: {
                        class: "col-xl-12 px-5 mb-0",
                        id: 'input-pre-populate-response-text',
                        label: `${component.$t("FORMS.INPUTS.ACTIVITY_PREPOPULATED_RESPONSE")}`,
                        model: component.managementType == 'post' ? component.$v.form.prepopulated_response : component.$v.editForm.prepopulated_response,
                        submissionStates: component.submissionStates
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          id: 2,
          name: `${component.$t("FORMS.INPUTS.ACTIVITY_CHOICES")}`,
          show: component.managementType == 'post' ?
                component.form.system_answer_type_id == 2 || component.form.system_answer_type_id == 3 : 
                component.editForm.system_answer_type_id == 2 || component.editForm.system_answer_type_id == 3,
          errors: component.tab2Errors,
          sections: [
            {
              rows: [
                {
                  width: 'fluid',
                  components: [
                    {
                      name: "InputActivityQuestionChoices",
                      properties: {
                        class: "col-xl-12 px-5 mb-10",
                        id: 'input-question-choices',
                        model: component.managementType == 'post' ? component.$v.form.choices : component.$v.editForm.choices,
                        segments: component.projectSegmentations,
                        systemAnswerTypeId: component.form.system_answer_type_id,
                        projectId: component.projectInfo.id,
                        submissionStates: component.submissionStates,
                        error: `${component.$t("FORMS.ERROR.ACTIVITY_QUESTION_CHOICES")}`, 
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
  
export default ManageDiaryQuestion;