class ManageActivityHelper {
  static computedNotificationPeriods() {
    return [
      { name: "1 day", value: 1 },
      { name: "2 days", value: 2 },
      { name: "3 days", value: 3 },
      { name: "4 days", value: 4 },
      { name: "5 days", value: 5 },
      { name: "6 days", value: 6 },
      { name: "7 days", value: 7 }
    ];
  }

  static computedRepeatOptions() {
    return [
      { name: "once", value: 0 },
      { name: "repeatedly", value: 1 }
    ];
  }

  static sequencedActivity(activity, pageType, sequence_parent) {
    if (activity != undefined && pageType == "add") {
      return true;
    } else if (pageType == "edit" && sequence_parent != null) {
      return true;
    } else {
      return false;
    }
  }

  static editingActivity(activity, pageType) {
    if (activity != undefined && pageType == "edit") {
      return true;
    } else {
      return false;
    }
  }

  static duplicatingActivity(activity, pageType) {
    if (activity != undefined && pageType == "duplicate") {
      return true;
    } else {
      return false;
    }
  }

  static duplicatingSequencedActivity(duplicating, sequence_parent) {
    if (sequence_parent != undefined && duplicating == true) {
      return true;
    } else {
      return false;
    }
  }

  static scheduledActivity(isSequencedActivity, sequence_option_id, isDuplicateSequencedActivity) {
    if (isSequencedActivity || isDuplicateSequencedActivity) {
      if (sequence_option_id != 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  static initialNotificationToggled(notification_toggle) {
    if (notification_toggle == 1) {
      return true;
    } else {
      return false;
    }
  }

  static repeatNotificationToggled(repeat_notification_toggle) {
    if (repeat_notification_toggle == 1) {
      return true;
    } else {
      return false;
    }
  }

  static initialVoteNotificationToggled(auto_notification_toggle) {
    if (auto_notification_toggle == 1) {
      return true;
    } else {
      return false;
    }
  }

  static repeatVoteNotificationToggled(auto_repeat_notification_toggle) {
    if (auto_repeat_notification_toggle == 1) {
      return true;
    } else {
      return false;
    }
  }

  static prepopulateToggled(pre_populate_toggle) {
    if (pre_populate_toggle == 1) {
      return true;
    } else {
      return false;
    }
  }

  static sequenceableActivity(type, index) {
    let arr = type.find(e => {
      return e.id == index;
    });
    if (arr) {
      return Boolean(arr.can_sequence);
    } else {
      return false;
    }
  }
}

export default ManageActivityHelper;
