import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_GENERAL_SEARCH = "populateGeneralSearch";
export const CLEAR_GENERAL_SEARCH = "clearGeneralSearch";
export const CLEAR_GENERAL_SEARCH_ERRORS = "clearGeneralSearchErrors";

// mutation types
export const SET_GENERAL_SEARCH = "setGeneralSearch";
export const RESET_GENERAL_SEARCH = "resetGeneralSearch";
export const SET_GENERAL_SEARCH_ERRORS = "setGeneralSearchErrors";
export const RESET_GENERAL_SEARCH_ERRORS = "resetGeneralSearchErrors";

const state = {
  general_search: {},
  errors: {}
};

const getters = {
  generalSearchResults(state) {
    return state.general_search;
  },
  generalSearchErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_GENERAL_SEARCH](context, payload) {
    return ApiService.get("api/v1/search/", {
      params: payload.params
    }).then(res => {
        const results = res.data;
        context.commit(SET_GENERAL_SEARCH, results);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_GENERAL_SEARCH_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_GENERAL_SEARCH](context) {
    context.commit(RESET_GENERAL_SEARCH);
  },
  [CLEAR_GENERAL_SEARCH_ERRORS](context) {
    context.commit(RESET_GENERAL_SEARCH_ERRORS);
  }
};

const mutations = {
  [SET_GENERAL_SEARCH](state, results) {
    state.general_search = results;
  },
  [RESET_GENERAL_SEARCH](state) {
    state.general_search = {};
  },
  [SET_GENERAL_SEARCH_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_GENERAL_SEARCH_ERRORS](state) {
    state.errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};