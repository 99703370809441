<template>
  <div>
    <div class="d-flex justify-content-between mb-10">
      <div class="form-group mb-0 col-xl-6 px-0">
        <input
          id="question_table_search"
          v-model="search"
          type="text"
          :placeholder="$t('GENERAL.INPUT.SEARCH')"
          class="form-control form-control-solid h-auto py-3 px-5 w-75"
        />
      </div>
      <div class="justify-content-end">
        <a
          class=" btn btn-sm btn-outline-primary mr-5"
          href="javascript:void(0)"
          v-if="activityType == 'survey' && model.length > 0"
          @click="onAddPageBreak"
        >
          {{ $t('ACTIVITY.BUTTONS.ADD_PAGE_BREAKS') }}
        </a>
        <a class="btn btn-sm btn-outline-primary" href="javascript:void(0)" @click="onAddQuestion">
          {{ $t('ACTIVITY.BUTTONS.ADD_QUESTION') }}
        </a>
      </div>
    </div>
    <div class="table-responsive mb-0">
      <table
        id="activity_questions_table"
        class="table table-head-custom table-vertical center table-hover col-xl-12"
        style="table-layout:auto; overflow: auto"
      >
        <thead>
          <tr>
            <th class="px-2 py-3" style="width:45px"></th>
            <th
              class="px-2 py-3"
              style="width:400px"
            >
              {{ $t('TABLES.ACTIVITY_QUESTIONS.NAME') }}
            </th>
            <th
              class="px-2 py-3 text-center"
              style="width:200px"
            >
              {{ $t('TABLES.ACTIVITY_QUESTIONS.TYPE') }}
            </th>
            <th
              class="px-2 py-3 text-center"
              style="width:160px"
            >
              {{ $t('TABLES.ACTIVITY_QUESTIONS.CHOICES') }}
            </th>
            <th
              class="px-2 py-3 text-right"
              style="width:200px"
            >
              {{ $t('TABLES.ACTIVITY_QUESTIONS.ACTIONS') }}
            </th>    
          </tr>
        </thead>
        <tbody>
          <tr v-if="model.length == 0">
            <td class="px-2 text-center" colspan="5">
              <span class="text-dark-65 font-weight-bold d-block py-3">
                {{ $t('TABLES.ACTIVITY_QUESTIONS.NO_QUESTIONS_TEXT') }}
                <a 
                  href="javascript:void(0)"
                  class="text-primary"
                  @click="onAddQuestion"
                >
                  {{ $t('TABLES.ACTIVITY_QUESTIONS.NO_QUESTIONS_LINK') }}
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <draggable :list="model" class="table-responsive table-hover" handle=".drag-page">
      <template v-for="(question,idx) in model">
        <ActivityQuestionsRow
          v-if="questionInSearch(question)"
          :key="idx"
          :data="question"
          :search="search"
          @edit_question_pressed="onEditQuestion(question, idx)"
          @duplicate_question_pressed="onDuplicateQuestion(question)"
          @delete_pressed="onDelete(idx)"
        >
        </ActivityQuestionsRow>
      </template>
    </draggable>
    <div class="col-xl-12">
      <b-modal
        ref="question-modal"
        size="xl"
        body-class="p-0"
        hide-footer
        hide-header
        @hide="onHide"
      >
        <!-- start:: QUESTION TOPFORM -->
        <Topform
          :ref="activityType + '-question-topform'"
          :managementType="managementType"
          :pageType="activityType + '_question'"
          :name="managementType == 'update' ? questionInfo.question : null"
          :errors="computedErrors"
          :submissionStates="submissionStates"
          parentPage="/project/activities/view"
          :modal="true"
          :tabs="computedTabs"
          :validation="computedValidation"
          :loadingComplete="true"
          :fluid="true"
          @asset_reservation_created="managementType == 'post' ? form.stimuli.push($event) : editForm.stimuli.push($event)"
          @asset_reservation_deleted="onAssetReservationDeleted($event)"
          @remove_project_asset="managementType == 'update' ? editForm.stimuli_remove.push($event) : null"
          @previews_updated="managementType == 'post' ? form.previews = $event : null"
          @submit="onSaveQuestion"
          @cancel="onCancelQuestion"
        >
        </Topform>
        <!-- end::QUESTION TOPFORM -->
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, requiredIf, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import ActivityQuestionsRow from "@/view/content/activities/ActivityQuestionsRow";

//Topform Imports
import DiaryTopformConfig from "@/core/config/topform/ManageDiaryQuestion.js";
import SurveyTopformConfig from "@/core/config/topform/ManageSurveyQuestion.js";
import MarkupTopformConfig from "@/core/config/topform/ManageMarkupQuestion.js";

export default {
  mixins: [validationMixin],
  
  name: "ActivityQuestions",

  props: {
    model: { type: Array, required: true },
    submissionStates: { type: Object, required: true },
    projectSegmentations: { type: Array, required: true }
    // page: { type: Number, required: true },
    // page_length: { type: Number, required: true }
  },

  data() {
    return {
      editIndex: null,
      search: "",
      managementType: 'post',
      submissionAttempted: {
        state: false,
        attempts: 0
      },
      questionInfo: {
        question: null
      },
      prepopulate_toggle: 0,
      edit_prepopulate_toggle: 0,
      enable_text_response: 0,
      edit_enable_text_response: 0,
      enable_fill_in_the_blanks: false,
      enable_sentiment: 0,
      edit_enable_sentiment: 0,
      segmentationBreakdown: [],
      error: {
        active: false,
        message: ""
      },
      form_reset: {},
      form: {
        system_answer_type_id: 1,
        question: null,
        prepopulated_response: null,
        attachments_allowed: 1,
        pin_answer_type: 0,
        pin_count: 1,
        published: 0,
        text_response_required: 0,
        text_response_prompt: null,
        sentiment_response_required: 0,
        sentiment_response_prompt: null,
        choices: [],
        stimuli: [],
        previews: [],
        settings: []
      },
      editForm: {
        system_answer_type_id: 1,
        question: null,
        prepopulated_response: null,
        attachments_allowed: 0,
        pin_answer_type: 0,
        pin_count: 1,
        published: 0,
        text_response_required: 0,
        text_response_prompt: null,
        sentiment_response_required: 0,
        sentiment_response_prompt: null,
        choices: [],
        stimuli: [],
        stimuli_remove: [],
        stimulus_assets: [],
        previews: [],
        settings: []
      }
    };
  },

  validations() {
    return {
      enable_text_response: {
        required
      },
      enable_sentiment: {
        required
      },
      edit_enable_text_response: {
        required
      },
      edit_enable_sentiment: {
        required
      },
      prepopulate_toggle: {},
      edit_prepopulate_toggle: {},
      form: {
        system_answer_type_id: {
          required
        },
        question: {
          required: requiredIf(function() {
            return this.form.system_answer_type_id != 8;
          })
        },
        published: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        pin_answer_type: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        pin_count: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        text_response_required: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        sentiment_response_required: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        text_response_prompt: {},
        sentiment_response_prompt: {},
        prepopulated_response: {},
        attachments_allowed: {
          required: requiredIf(function() {
            return this.form.system_answer_type_id == 1;
          })
        },
        choices: {
          required: requiredIf(function() {
            return this.choiceTypes.includes(this.form.system_answer_type_id);
          }),
          minLength: minLength(this.choiceTypes.includes(this.form.system_answer_type_id) ? 2 : 0),
          $each: {
            choice: {
              requiredIf: requiredIf((obj) => obj.stimuli.length == 0 && this.choiceTypes.includes(this.form.system_answer_type_id))
            },
            stimuli: {
              requiredIf: requiredIf((obj) => obj.choice.length == 0 && this.choiceTypes.includes(this.form.system_answer_type_id))
            }
          }
        },
        settings: {
          minLength: minLength(this.form.system_answer_type_id == 9 ? 1 : 0)
        }
      },
      editForm: {
        system_answer_type_id: {
          required
        },
        question: {
          required
        },
        published: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        pin_answer_type: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        pin_count: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        text_response_required: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        sentiment_response_required: {
          required: requiredIf(function() {
            return this.activityType == "markup";
          })
        },
        text_response_prompt: {},
        sentiment_response_prompt: {},
        prepopulated_response: {},
        attachments_allowed: {
          required: requiredIf(function() {
            return this.editForm.system_answer_type_id == 1;
          })
        },
        choices: {
          required: requiredIf(function() {
            return this.choiceTypes.includes(this.editForm.system_answer_type_id);
          }),
          minLength: minLength(this.choiceTypes.includes(this.form.system_answer_type_id) ? 2 : 0),
          $each: {
            choice: {
              requiredIf: requiredIf((obj) => 
                obj.stimulus_assets != undefined ? 
                obj.stimuli.length == 0 && obj.stimulus_assets.length == 0 && this.choiceTypes.includes(this.editForm.system_answer_type_id) : 
                obj.stimuli.length == 0 && this.choiceTypes.includes(this.editForm.system_answer_type_id)
              )
            },
            stimuli: {
              requiredIf: requiredIf((obj) => 
                obj.stimulus_assets != undefined ? 
                obj.choice.length == 0 && obj.stimulus_assets.length == 0 && this.choiceTypes.includes(this.editForm.system_answer_type_id): 
                obj.choice.length == 0 && this.choiceTypes.includes(this.editForm.system_answer_type_id))
            }
          }
        },
        settings: {
          minLength: minLength(this.editForm.system_answer_type_id == 9 ? 1 : 0)
        }
      }
    }
  },

  components: {
    ActivityQuestionsRow,
    draggable
  },

  mounted() {
    if (this.managementType == "post") {
      let defaultSettings = this.systemActivityQuestionSettings.filter(function(setting) {
        return setting.default == true;
      })

      this.form.settings = defaultSettings.map(a => a.value);
    }

    this.form_reset = JSON.parse(JSON.stringify(this.form));
    this.edit_form_reset = JSON.parse(JSON.stringify(this.editForm));
  },

  methods: {
    questionInSearch: function(element) {
      if (!element.is_page_break) {
        let question = element.question.toLowerCase();
        let type = "";

        if (element.answer_type != undefined) {
          type = element.answer_type.name.toLowerCase();
        } else {
          switch (element.system_answer_type_id) {
            case 1:
              type = 'open-ended'
              break;
            case 2:
              type = 'multiple choice'
              break;
            case 3:
              type = 'single choice'
              break;
            case 4:
              type = 'picture upload'
              break;
            case 5:
              type = 'ranking'
              break;
            case 6:
              type = 'video upload'
              break;
            case 7:
              type = 'pin'
              break;
            default:
              type = ''
              break;
          }
        }
    
        if (
          question.includes(this.search.toLowerCase()) ||
          type.includes(this.search.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.search == "" || this.search == null) {
          return true;
        } else {
          return false;
        };
      }
    },
    onAssetReservationDeleted(event) {
      if (this.managementType == 'post') {
        this.form.stimuli = this.form.stimuli.filter(function(stim) {
          return stim !== event;
        });

        this.form.previews = this.form.previews.filter(function(preview) {
          return preview.assetReservationId !== event;
        });
      } else {
        this.editForm.stimuli = this.editForm.stimuli.filter(function(stim) {
          return stim !== event;
        });

        this.editForm.previews = this.editForm.previews.filter(function(preview) {
          return preview.assetReservationId !== event;
        });
      }
    },
    onAddQuestion() {
      this.managementType = "post";
      this.questionInfo.question = null;
      if (this.activityType == "markup") {
        this.form.system_answer_type_id = 7;
      }
      this.$refs["question-modal"].show(); 
    },
    onEditQuestion(question, idx) {
      this.submissionAttempted = {
        state: false,
        attempts: 0
      };

      this.editForm = JSON.parse(JSON.stringify(this.edit_form_reset));
      this.questionInfo.question = null;
      this.managementType = "update";
      for (const [key, value] of Object.entries(question)) {
        if (key in this.editForm) {
          if (key != 'choices') {
            this.editForm[key] = value;
          }
        }
      };

      if ('choices' in question) {
        question.choices.forEach(choice => {
          this.editForm.choices.push({
            id: choice.id != undefined ? choice.id : null,
            choice: choice.choice,
            project_segment_id: choice.project_segment_id,
            stimuli: choice.stimuli,
            stimuli_remove: [],
            stimulus_assets: choice.stimulus_assets,
            previews: choice.previews
          });
        });
      }

      if (this.editForm.system_answer_type_id == 1) {
        if (this.editForm.prepopulated_response != null) {
          this.edit_prepopulate_toggle = 1;
        }
      }

      if (this.editForm.system_answer_type_id == 7) {
        if (this.editForm.text_response_prompt != null || this.editForm.text_response_required == 1) {
          this.edit_enable_text_response = 1;
        } else {
          this.edit_enable_text_response = 0;
        }

        if (this.editForm.sentiment_response_prompt != null || this.editForm.sentiment_response_required == 1) {
          this.edit_enable_sentiment = 1;
        } else {
          this.edit_enable_sentiment = 0;
        }
      }

      this.editIndex = idx;

      this.questionInfo.question = question.shortenedQuestion;

      this.$refs["question-modal"].show();
    },
    onHide(bvModalEvt) {
      if ((bvModalEvt.trigger == "backdrop" || bvModalEvt.trigger == "esc") && this.managementType == "update") {
        bvModalEvt.preventDefault();
        this.handleEditHide();
      }
    },
    handleEditHide() {
      this.$swal
      .fire({
        title: `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.TITLE")}`,
        text: `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.MESSAGE")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
      })
      .then(result => {
        if (result.isConfirmed) {
          this.$nextTick(() => {
            this.$refs["question-modal"].hide();
          })
        } else {
          return;
        }
      });
    },
    onCancelQuestion() {
      this.$swal
      .fire({
        title: this.managementType == 'post' ? 
          `${this.$t("SWEETALERTS.ADD_QUESTION_CANCEL_CHALLENGE.TITLE")}` : 
          `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.TITLE")}`,
        text: this.managementType == 'post' ?
          `${this.$t("SWEETALERTS.ADD_QUESTION_CANCEL_CHALLENGE.MESSAGE")}` :
          `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.MESSAGE")}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.managementType == 'post' ?
          `${this.$t("SWEETALERTS.ADD_QUESTION_CANCEL_CHALLENGE.CONFIRM")}` :
          `${this.$t("SWEETALERTS.EDIT_QUESTION_CANCEL_CHALLENGE.CONFIRM")}`,
        cancelButtonText: `${this.$t("GENERAL.TEXT.CANCEL")}`
      })
      .then(result => {
        if (result.isConfirmed) {
          this.$refs["question-modal"].hide();
        }
      });
    },
    onSaveQuestion() {
      this.submissionAttempted = {
        state: true,
        attempts: (this.submissionAttempted.attempts++)
      };

      if (this.managementType == 'post') {
        this.savePost();
      } else {
        if (this.saveUpdate()) {
          this.$refs["question-modal"].hide();
        }
      };
    },
    savePost: function() {
      if (this.form.system_answer_type_id == 8) {
        let fitbEditor = this.$refs[`${this.activityType}-question-topform`].$refs['input-question-fitb'];
        let question = fitbEditor.generateQuestion();
        let choices = fitbEditor.generateChoices();

        if (fitbEditor.inputValid()) {
          let payload = {
            route: "api/v1/proposed_fitb_string",
            params: {
              question: question,
              choices: choices
            }
          }

          const self = this;
          this.$store.dispatch(GENERIC_RESOURCE,payload).then((res) => {
            self.form.prepopulated_response = null;
            self.form.attachments_allowed   = 0;
            self.form.question              = question;
            self.form.choices               = choices;
            self.form.arrayQuestion         = res.array_question;
            self.form.shortenedQuestion     = res.short_question;
            
            self.closePostForm();
          }).finally(() => {
            self.$refs["question-modal"].hide();
          });
        } else {
          return false;
        }
      } else {
        if (!this.choiceTypes.includes(this.form.system_answer_type_id)) {
          this.form.choices = [];
        };

        if (this.form.system_answer_type_id != 1) {
          this.form.prepopulated_response = null;
          this.form.attachments_allowed = 0;

          if (this.form.system_answer_type_id == 7) {
            if (this.enable_text_response == 0) {
              this.form.text_response_prompt = null;
              this.form.text_response_required = 0;
            };

            if (this.enable_sentiment == 0) {
              this.form.sentiment_response_prompt = null;
              this.form.sentiment_response_required = 0;
            };
          }
        } else {
          if (this.prepopulate_toggle == 0) {
            this.form.prepopulated_response = null;
          };
        }

        this.closePostForm();
        this.$refs["question-modal"].hide();
      }
    },
    saveUpdate: function() {
      if (this.editForm.system_answer_type_id == 8) {
        let fitbEditor = this.$refs[`${this.activityType}-question-topform`].$refs['input-question-fitb'];
        let question = fitbEditor.generateQuestion();
        let choices = fitbEditor.generateChoices();

        if (fitbEditor.inputValid()) {
          let payload = {
            route: "api/v1/proposed_fitb_string",
            params: {
              question: question,
              choices: choices
            }
          }

          const self = this;
          this.$store.dispatch(GENERIC_RESOURCE,payload).then((res) => {
            self.editForm.prepopulated_response = null;
            self.editForm.attachments_allowed   = 0;
            self.editForm.question              = question;
            self.editForm.choices               = choices;
            self.editForm.arrayQuestion         = res.array_question;
            self.editForm.shortenedQuestion     = res.short_question;

            for (const [key, value] of Object.entries(this.editForm)) {
              if (key in this.model[this.editIndex]) {
                this.model[this.editIndex][key] = value;
              }
            }   
          })
        } else {
          return false;
        }
      } else {
        if (!this.choiceTypes.includes(this.editForm.system_answer_type_id)) {
          this.editForm.choices = [];
        };

        if (this.editForm.system_answer_type_id != 1) {
          this.editForm.prepopulated_response = null;
          this.editForm.attachments_allowed = 0;

          if (this.editForm.system_answer_type_id == 7) {
            if (this.edit_enable_text_response == 0) {
              this.editForm.text_response_prompt = null;
              this.editForm.text_response_required = 0;
            };
            
            if (this.edit_enable_sentiment== 0) {
              this.editForm.sentiment_response_prompt = null;
              this.editForm.sentiment_response_required = 0;
            };
          }
        } else {
          if (this.edit_prepopulate_toggle == 0) {
            this.editForm.prepopulated_response = null;
          };
        }

        this.editForm.choices.forEach(choice => {
          if (choice.stimuli_remove != undefined) {
            choice.stimuli_remove.forEach(assetId => {
              choice.stimulus_assets = choice.stimulus_assets.filter(function(asset) {
                return asset.id !== assetId;
              });
            })
          }
        });

        this.editForm.stimuli_remove.forEach(assetId => {
          this.editForm.stimulus_assets = this.editForm.stimulus_assets.filter(function(asset) {
            return asset.id !== assetId;
          });
        });

        for (const [key, value] of Object.entries(this.editForm)) {
          if (key in this.model[this.editIndex]) {
            this.model[this.editIndex][key] = value;
          }
        }        
      }
      
      return true;
    },
    closePostForm: function() {
      this.model.push(this.form);
      // here be dragons - this causes an error message with vue 2 editor - but only on pre_pop response -> as far as I'm aware it isn't a problem?
      this.form = JSON.parse(JSON.stringify(this.form_reset));
      this.prepopulate_toggle = 0;
      this.enable_text_response = 0;
      this.enable_sentiment = 0;

      this.submissionAttempted = {
        state: false,
        attempts: 0
      };
    },
    onDuplicateQuestion(oldQuestion) {
      let newQuestion = {
        system_answer_type_id: oldQuestion.system_answer_type_id,
        question: oldQuestion.question,
        prepopulated_response: oldQuestion.prepopulated_response,
        attachments_allowed: oldQuestion.attachments_allowed,
        pin_answer_type: oldQuestion.pin_answer_type,
        pin_count: oldQuestion.pin_count,
        published: oldQuestion.published,
        text_response_required: oldQuestion.text_response_required,
        text_response_prompt: oldQuestion.text_response_prompt,
        sentiment_response_required: oldQuestion.sentiment_response_required,
        sentiment_response_prompt: oldQuestion.sentiment_response_prompt,
        stimuli: [],
        stimuli_remove: [],
        stimulus_assets: [],
        previews: [],
        settings: oldQuestion.settings
      };

      // here be dragons - for now we completely ignore stimuli  - need a solution to duplicating stimuli that don't have assets yet.
      // if (oldQuestion.stimulus_assets != undefined) {
      //   oldQuestion.stimulus_assets.forEach(asset => {
      //     newQuestion.stimulus_assets.push(asset);
      //   });
      // }

      let newChoices = [];

      if (this.choiceTypes.includes(oldQuestion.system_answer_type_id)) {
        oldQuestion.choices.forEach(oldChoice => {

          // if (oldChoice.stimulus_assets != undefined) {
          //   oldChoice.stimulus_assets.forEach(asset => {
          //     duplicateChoiceAssets.push(asset);
          //   });
          // }

          newChoices.push({
            'choice': oldChoice.choice,
            'project_segment_id': oldChoice.project_segment_id,
            'id': null,
            'previews': [],
            'stimuli': []
          })
        });
      }

      if (oldQuestion.system_answer_type_id == 8) {
        newChoices = oldQuestion.choices;
        newQuestion['shortenedQuestion'] = oldQuestion['shortenedQuestion'];
        newQuestion['arrayQuestion'] = oldQuestion['arrayQuestion'];
      }

      newQuestion['choices'] = newChoices;

      this.model.push(newQuestion); 
    },
    onDelete(idx) {
      this.model.splice(idx, 1);
    },
    onAddPageBreak() {
      this.model.push({ id: null, is_page_break: true });
    },
    saRemoveVideoTypeSettings: function() {
      let form = (this.managementType == "post") ? this.form : this.editForm;

      let screenRecordIndex = form.settings.indexOf(5);

      if (screenRecordIndex >= 0) {
        form.settings.splice(screenRecordIndex, 1);
      }

      let webcamIndex = form.settings.indexOf(6);

      if (webcamIndex >= 0) {
        form.settings.splice(webcamIndex, 1);
      }
    }
  },

  watch: {
    "relevantForm.settings": {
      handler(newSettings) {
        if (!newSettings.includes(2) && (newSettings.includes(5) || newSettings.includes(6))) {
          this.saRemoveVideoTypeSettings();
        }
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters([
      "projectInfo",
      "diaryQuestionErrors",
      "surveyQuestionErrors",
      "markupQuestionErrors",
      "systemActivityQuestionSettings"
    ]),
    assetReservationData: function() {
      let data = {
        scope: 'activity',
        data: {
          directory: 'stimuli',
          projectId: this.projectInfo.id,
          new: 1
        }
      };
      return data;
    },
    activityType: function() {
      if (this.$route.name == "edit_diary" || this.$route.name == "add_diary" || this.$route.name == "duplicate_diary" ) {
        return 'diary'
      } else if (this.$route.name == "edit_survey" || this.$route.name == "add_survey" || this.$route.name == "duplicate_survey" ) {
        return 'survey'
      } else if (this.$route.name == "edit_markup_legacy" || this.$route.name == "add_markup_legacy" || this.$route.name == "duplicate_markup_legacy" ) {
        return 'markup'
      } else {
        return null;
      }
    },
    requiredFormChoiceLength: function() {
      if (this.form.system_answer_type_id == 2 || this.form.system_answer_type_id == 3 || this.form.system_answer_type_id == 5) {
        return 2;
      } else {
        return 0;
      };
    },
    requiredEditFormChoiceLength: function() {
      if (this.editForm.system_answer_type_id == 2 || this.editForm.system_answer_type_id == 3 || this.editForm.system_answer_type_id == 5) {
        return 2;
      } else {
        return 0;
      };
    },
    computedValidation: function() {
      if (this.managementType == "post") {
        return this.$v.form;
      } else {
        return this.$v.editForm;
      };
    },
    computedTabs: function() {
      if (this.activityType == "diary") {
        return DiaryTopformConfig.topform(this);
      } else if (this.activityType == "survey") {
        return SurveyTopformConfig.topform(this);
      } else if (this.activityType == "markup") {
        return MarkupTopformConfig.topform(this);
      } else {
        return null;
      }
    },
    tab1Errors: function() {
      if (this.managementType == "post") {
        if (
          this.$v.form.system_answer_type_id.$anyError ||
          this.$v.form.question.$anyError ||
          this.$v.form.published.$anyError ||
          this.$v.form.pin_answer_type.$anyError ||
          this.$v.form.pin_count.$anyError ||
          this.$v.form.text_response_required.$anyError ||
          this.$v.form.sentiment_response_required.$anyError ||
          this.$v.form.text_response_prompt.$anyError ||
          this.$v.form.sentiment_response_prompt.$anyError ||
          this.$v.form.prepopulated_response.$anyError ||
          this.$v.form.attachments_allowed.$anyError
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.$v.editForm.system_answer_type_id.$anyError ||
          this.$v.editForm.question.$anyError ||
          this.$v.editForm.published.$anyError ||
          this.$v.editForm.pin_answer_type.$anyError ||
          this.$v.editForm.pin_count.$anyError ||
          this.$v.editForm.text_response_required.$anyError ||
          this.$v.editForm.sentiment_response_required.$anyError ||
          this.$v.editForm.text_response_prompt.$anyError ||
          this.$v.editForm.sentiment_response_prompt.$anyError ||
          this.$v.editForm.prepopulated_response.$anyError ||
          this.$v.editForm.attachments_allowed.$anyError
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    tab2Errors: function() {
      if (this.managementType == "post") {
        if (this.$v.form.choices.$anyError) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.$v.editForm.choices.$anyError) {
          return true;
        } else {
          return false;
        }
      }
    },
    choiceTypes: function() {
      return [2,3,5];
    },
    eligibleQuestionSettings: function() {
      let answerTypeId = (this.managementType == "post") ? this.form.system_answer_type_id : this.editForm.system_answer_type_id;
      return this.systemActivityQuestionSettings.filter(function(setting) {
        return setting.system_answer_type_id.includes(answerTypeId);
      })
    },
    relevantForm: function() {
      return (this.managementType == "post") ? this.form : this.editForm;
    },
    computedErrors: function() {
      if (this.activityType == "diary") {
        return this.diaryQuestionErrors;
      } else if (this.activityType == "survey") {
        return this.surveyQuestionErrors;
      } else if (this.activityType == "markup") {
        return this.markupQuestionErrors;
      } else {
        return null;
      }
    }
  }
};
</script>
