import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import router from "@/router.js";
import store from "@/core/services/store";
import { LOGOUT, RESET, REFRESH_TOKEN } from "@/modules/together-authentication/store/auth.module";

axios.interceptors.request.use(function(config) {
  let access_token = getCookie('access_token');
  config.headers.Accept = "application/json";
  config.headers.Authorization = "Bearer " + access_token;
  return config;
});

axios.interceptors.response.use(
  function(response) {
    // Call was successful, don't do anything special.
    return response;
  },
  function(error) {
    let refresh_token = getCookie('refresh_token');

    switch (error.response.status) {
      case 401: // Not logged in
        if (refresh_token != 'null' && refresh_token != "") {
          store.dispatch(REFRESH_TOKEN, refresh_token)
            .finally(() => {
              location.reload();
            })
        } else {
          if (!window.location.href.includes("/#/register")) {
            store.dispatch(RESET);
            if (router.currentRoute == undefined || (router.currentRoute != undefined && router.currentRoute.name != 'login' )) {
              router.push({ name: "login" });   
            }    
          }
        }
        break;
      case 403: // Email Verification Required
        router.push({ name: "verify" });
        break;
      case 419: // Session expired
        store.dispatch(RESET);
        router.push({ name: "login" });
        break;
      case 503: // Down for maintenance
        store.dispatch(LOGOUT);
        router.push({ name: "login" });
        break;
      case 500:
        alert("Oops, something went wrong!  The team have been notified.");
        break;
      default:
        // Allow individual requests to handle other errors
        return Promise.reject(error);
    }
  }
);

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param params
   * @param slug
   * @returns {*}
   */
  get(resource, params, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default ApiService;
