<template>
  <div>
    <b-dropdown v-if="computedPosition == 'right'" id="dropdown-1" size="sm" text="Export" variant="outline-primary" right>
      <template v-for="(type, i) in dataTypes">
        <b-dropdown-item :key="i" @click="exportChart(type)">{{ type.toUpperCase() }}</b-dropdown-item>
      </template>
    </b-dropdown>

    <b-dropdown v-if="computedPosition == 'left'" id="dropdown-1" size="sm" text="Export" variant="outline-primary" left>
      <template v-for="(type, i) in dataTypes">
        <b-dropdown-item :key="i" @click="exportChart(type)">{{ type.toUpperCase() }}</b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import { mapGetters } from  "vuex";

export default {
  name: "AMChartExport",

  props: {
    position: { type: String, required: false },
    dataTypes: { type: Array, required: true },
    index: { type: Number, required: false, default: 0 },
    title: { type: String, required: false, default: "Export" },
    chart: { type: Object, required: false }
  },

  methods: {
    exportChart: function(type) {
      if (this.chart != undefined) {
        this.chart.exporting.filePrefix = this.title;
        this.chart.exporting.export(type);
      } else {
        this.streamCharts[this.index].exporting.filePrefix = this.title;
        this.streamCharts[this.index].exporting.export(type);        
      }
    }
  },

  computed: {
    ...mapGetters([
      'streamCharts'
    ]),
    computedPosition: function() {
      if (this.position != undefined) {
        return this.position;
      } else {
        return "left"
      }
    }
  }
};
</script>
