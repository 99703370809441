import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_SURVEY_RESPONSES_LIST =
  "populateActivitySurveyRepsonsesList";
export const POPULATE_ACTIVITY_SURVEY_RESPONSES_INFO =
  "populateActivitySurveyRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_SURVEY_RESPONSES_INFO =
  "populateActivityAdditionalSurveyRepsonsesInfo";
export const CREATE_SURVEY_RESPONSES = "createSurveyRepsonses";
export const UPDATE_SURVEY_RESPONSES = "updateSurveyRepsonses";
export const DELETE_SURVEY_RESPONSES = "deleteSurveyRepsonses";
export const CLEAR_SURVEY_RESPONSES_LIST = "clearSurveyRepsonsesList";
export const CLEAR_SURVEY_RESPONSES_INFO = "clearSurveyRepsonsesInfo";
export const CLEAR_SURVEY_RESPONSES_ERRORS = "clearSurveyRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_SURVEY_RESPONSES_LIST =
  "setActivitySurveyRepsonsesList";
export const RESET_ACTIVITY_SURVEY_RESPONSES_LIST =
  "resetActivitySurveyRepsonsesList";
export const SET_ACTIVITY_SURVEY_RESPONSES_INFO =
  "setActivitySurveyRepsonsesInfo";
export const RESET_ACTIVITY_SURVEY_RESPONSES_INFO =
  "resetActivitySurveyRepsonsesInfo";
export const APPEND_ACTIVITY_SURVEY_RESPONSES_LIST =
  "appendActivitySurveyRepsonsesInfo";
export const ADD_ACTIVITY_SURVEY_RESPONSES = "addActivitySurveyRepsonses";
export const UPDATE_ACTIVITY_SURVEY_RESPONSES = "updateActivitySurveyRepsonses";
export const REMOVE_ACTIVITY_SURVEY_RESPONSES = "removeActivitySurveyRepsonses";
export const SET_ACTIVITY_SURVEY_RESPONSES_ERRORS =
  "setActivitySurveyRepsonsesErrors";
export const RESET_ACTIVITY_SURVEY_RESPONSES_ERRORS =
  "resetActivitySurveyRepsonsesErrors";

const state = {
  activity_survey_responses_errors: {},
  activity_survey_responses_list: [],
  activity_survey_responses_info: []
};

const getters = {
  activitySurveyRepsonsesErrors(state) {
    return state.activity_survey_responses_errors;
  },
  activitySurveyRepsonsesList(state) {
    return state.activity_survey_responses_list;
  },
  activitySurveyRepsonsesInfo(state) {
    return state.activity_survey_responses_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_SURVEY_RESPONSES_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_survey_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_survey_responses;
        context.commit(SET_ACTIVITY_SURVEY_RESPONSES_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_SURVEY_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_survey_responses" +
        payload.activitySurveyResponseId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_SURVEY_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_SURVEY_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_survey_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_survey_responses;
        context.commit(
          APPEND_ACTIVITY_SURVEY_RESPONSES_LIST,
          payload,
          responses
        );
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_SURVEY_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_survey_responses",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_SURVEY_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_SURVEY_RESPONSES](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_survey_responses" +
        payload.activitySurveyResponseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_SURVEY_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_SURVEY_RESPONSES](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_survey_responses/" +
        payload.id
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_SURVEY_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_SURVEY_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_SURVEY_RESPONSES_LIST);
  },
  [CLEAR_SURVEY_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_SURVEY_RESPONSES_INFO);
  },
  [CLEAR_SURVEY_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_SURVEY_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_SURVEY_RESPONSES_LIST](state, activity_survey_responses_list) {
    state.activity_survey_responses_list = activity_survey_responses_list;
  },
  [RESET_ACTIVITY_SURVEY_RESPONSES_LIST](state) {
    state.activity_survey_responses_list = [];
  },
  [SET_ACTIVITY_SURVEY_RESPONSES_INFO](state, activity_survey_responses_info) {
    state.activity_survey_responses_info = activity_survey_responses_info;
  },
  [RESET_ACTIVITY_SURVEY_RESPONSES_INFO](state) {
    state.activity_survey_responses_info = [];
  },
  [ADD_ACTIVITY_SURVEY_RESPONSES](state, activity_survey_responses_info) {
    state.activity_survey_responses_list.push(activity_survey_responses_info);
  },
  [UPDATE_ACTIVITY_SURVEY_RESPONSES](state, activity_survey_responses_info) {
    let newActivitySurveyRepsonsesArray = [];
    newActivitySurveyRepsonsesArray.push(activity_survey_responses_info);
    state.activity_survey_responses_list = state.activity_survey_responses_list.map(
      obj => newActivitySurveyRepsonsesArray.find(o => o.id === obj.id) || obj
    );
  },
  [APPEND_ACTIVITY_SURVEY_RESPONSES_LIST](
    state,
    payload,
    activity_survey_responses_additional_list
  ) {
    let responseToIndex = state.activity_survey_responses_list.findIndex(
      p => p.id == payload.responding_to
    ); // to be update
    if (responseToIndex !== -1) {
      state.activity_survey_responses_list = state.activity_survey_responses_list.splice(
        responseToIndex,
        0,
        activity_survey_responses_additional_list
      );
    } else {
      state.activity_survey_responses_list.push(
        activity_survey_responses_additional_list
      );
    }
  },
  [REMOVE_ACTIVITY_SURVEY_RESPONSES](state, activitySurveyResponseId) {
    state.activity_survey_responses_list = state.activity_survey_responses_list.filter(
      function(obj) {
        return obj.id !== activitySurveyResponseId;
      }
    );
  },
  [SET_ACTIVITY_SURVEY_RESPONSES_ERRORS](
    state,
    activity_survey_responses_errors
  ) {
    state.activity_survey_responses_errors = activity_survey_responses_errors;
  },
  [RESET_ACTIVITY_SURVEY_RESPONSES_ERRORS](state) {
    state.activity_survey_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
