import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_SEGMENT_LIST = "populateProjectSegmentList";
export const CREATE_PROJECT_SEGMENT = "createProjectSegment";
export const UPDATE_PROJECT_SEGMENT = "updateProjectSegment";
export const DELETE_PROJECT_SEGMENT = "deleteProjectSegment";
export const CLEAR_PROJECT_SEGMENT_LIST = "clearProjectSegmentList";
export const CLEAR_PROJECT_SEGMENT_ERRORS = "clearProjectSegmentErrors";
export const BULK_ASSIGN_USERS = "bulkAssignedUsers";
export const BULK_UNASSIGN_USERS = "bulkUnassignUsers";

// mutation types
export const SET_PROJECT_SEGMENT_LIST = "setProjectSegmentList";
export const RESET_PROJECT_SEGMENT_LIST = "resetProjectSegmentList";
export const SET_PROJECT_SEGMENT_ERRORS = "setProjectSegmentErrors";
export const RESET_PROJECT_SEGMENT_ERRORS = "resetProjectSegmentErrors";
export const ADD_NEW_SEGMENT = "addNewSegment";
export const UPDATE_SEGMENT = "updateSegment";
export const REMOVE_SEGMENT = "removeSegment";

const state = {
  project_segment_list: [],
  errors: {}
};

const getters = {
  projectSegments(state) {
    return state.project_segment_list;
  },
  projectSegmentErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_SEGMENT_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/project_segments", { params: payload.params }
    )
      .then(res => {
        const projectSegments = res.data.data._embedded.project_segments;
        context.commit(SET_PROJECT_SEGMENT_LIST, projectSegments);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_PROJECT_SEGMENT](context, payload) {
    return ApiService.post("api/v1/project_segmentations/" + payload.segmentationId + '/project_segments', payload.data)
      .then(res => {
        context.commit(ADD_NEW_SEGMENT, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_SEGMENT](context, payload) {
    return ApiService.put("api/v1/project_segmentations/" + payload.segmentationId + "/project_segments/" + payload.segmentId, payload.data)
      .then(res => {
        context.commit(UPDATE_SEGMENT, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_SEGMENT](context, payload) {
    return ApiService.delete("api/v1/project_segmentations/" + payload.segmentationId + '/project_segments/' + payload.segmentId)
      .then(res => {
        context.commit(REMOVE_SEGMENT, payload.segmentId);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_SEGMENT_LIST](context) {
    context.commit(RESET_PROJECT_SEGMENT_LIST);
  },
  [CLEAR_PROJECT_SEGMENT_ERRORS](context) {
    context.commit(RESET_PROJECT_SEGMENT_ERRORS);
  },
  [BULK_ASSIGN_USERS](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/bulk_assign_segments", payload)
      .then(res => {
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [BULK_UNASSIGN_USERS](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + "/bulk_unassign_segments", payload)
      .then(res => {
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_SEGMENT_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  }
}

const mutations = {
  [SET_PROJECT_SEGMENT_LIST](state, project_segments) {
    state.project_segment_list = project_segments;
  },
  [RESET_PROJECT_SEGMENT_LIST](state) {
    state.project_segment_list = {};
  },
  [SET_PROJECT_SEGMENT_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_SEGMENT_ERRORS](state) {
    state.errors = {};
  },
  [ADD_NEW_SEGMENT](state, project_segment) {
    state.project_segment_list.push(project_segment);
  },
  [UPDATE_SEGMENT](state, project_segment) {
    let newProjectSegmentArray = [];
    newProjectSegmentArray.push(project_segment);
    state.project_segment_list = state.project_segment_list.map(obj => newProjectSegmentArray.find(o => o.id === obj.id) || obj);
  },
  [REMOVE_SEGMENT](state, projectSegmentId) {
    state.project_segment_list = state.project_segment_list.filter(function( obj ) {
      return obj.id !== projectSegmentId;
    });
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};