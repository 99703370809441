<template>
  <div class="table-responsive">
    <table
      :id="id + '-table'"
      class="table table-head-custom table-vertical center table-hover mt-5 w-100"
      style="table-layout:auto"
    >
      <thead>
        <tr>
          <th style="width:25px"></th>
          <th class="px-2 py-3" style="width:200px">
            {{ $t('TABLES.EXTENDED_PROFILE_QUESTIONS.CHOICE') }}
          </th>
          <th
            class="px-2 py-3 text-left"
            style="width:200px"
          >
            {{ $t('TABLES.EXTENDED_PROFILE_QUESTIONS.PLACES') }}
          </th>
          <th
            class="px-2 py-3 text-right"
            style="width:80px"
          >
          </th>                    
        </tr>
      </thead>
      <tbody>
        <template v-for="(choice, i) in choices">
          <ExtendedProfileQuestionChoiceRow
            :key="i"
            :data="choice"
            :index="i"
            :editIndex="editIndex"
            :currentChoices="choices"
            @choice_removed="$emit('choice_removed', $event)"
            @choice_updated="$emit('choice_updated', $event)"
            @enableRowEdit="editIndex = $event"
            @disableRowEdit="editIndex = null"
          >
          </ExtendedProfileQuestionChoiceRow>
        </template>
        <AddExtendedProfileQuestionChoiceRow
          :currentChoices="choices"
          :editIndex="editIndex"
          @choice_added="$emit('choice_added', $event)"
        >
        </AddExtendedProfileQuestionChoiceRow>
      </tbody>
    </table>
    <div class="custom-validation-failure mb-7" v-if="validationTriggered">
      {{ error }}
    </div>
  </div>
</template>

<script>
import AddExtendedProfileQuestionChoiceRow from "@/view/content/projects/AddExtendedProfileQuestionRow";
import ExtendedProfileQuestionChoiceRow from "@/view/content/projects/ExtendedProfileQuestionChoiceRow";

export default {
  name: "ExtendedProfileQuestionTable",

  props: {
    choices: { type: Array, required: true },
    id: { type: String, required: true },
    error: { type: String, required: true },
    validationTriggered: { type: Boolean, required: true }
  },

  data() {
    return {
      editIndex: null,
    }
  },

  components: {
    AddExtendedProfileQuestionChoiceRow,
    ExtendedProfileQuestionChoiceRow,
  }
};
</script>
