import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PROJECT_ROOM_SETUP_LIST = "populateProjectRoomSetupList";
export const CLEAR_PROJECT_ROOM_SETUP = "clearProjectRoomSetup";
export const CREATE_PROJECT_ROOM_SETUP = "createProjectRoomSetup";
export const UPDATE_PROJECT_ROOM_SETUP = "updateProjectRoomSetup";
export const DELETE_PROJECT_ROOM_SETUP = "deleteProjectRoomSetup";
export const CLEAR_PROJECT_ROOM_SETUP_ERRORS =
  "clearProjectRoomSetupErrors";

// mutation types
export const SET_PROJECT_ROOM_SETUP = "setProjectRoomSetup";
export const SET_PROJECT_ROOM_SETUP_LIST = "setProjectRoomSetupList"
export const RESET_PROJECT_ROOM_SETUP = "resetProjectRoomSetup";
export const REMOVE_PROJECT_ROOM_SETUP = "removeProjectRoomSetup";
export const SET_PROJECT_ROOM_SETUP_ERRORS = "setProjectRoomSetupErrors";
export const RESET_PROJECT_ROOM_SETUP_ERRORS =
  "resetProjectRoomSetupErrors";

const state = {
  project_room_setup: {},
  project_room_setup_list: [],
  errors: {}
};

const getters = {
  projectRoomSetup(state) {
    return state.project_room_setup;
  },
  projectRoomSetupList(state) {
    return state.project_room_setup_list
  },
  projectRoomSetupErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_ROOM_SETUP_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" +
        payload.projectId +
        "/project_room_setups", payload.data
    )
      .then(res => {
        const projectRoomSetups = res.data.data._embedded.project_room_setups;
        context.commit(SET_PROJECT_ROOM_SETUP_LIST, projectRoomSetups);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ROOM_SETUP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_ROOM_SETUP](context) {
    context.commit(CLEAR_PROJECT_ROOM_SETUP);
  },
  [CREATE_PROJECT_ROOM_SETUP](context, payload) {
    return ApiService.post(
      "api/v1/projects/" + payload.projectId + "/project_room_setups",
      payload.data
    )
      .then(res => {
        const projectRoomSetup = res.data.data;
        context.commit(SET_PROJECT_ROOM_SETUP, projectRoomSetup);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ROOM_SETUP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PROJECT_ROOM_SETUP](context, payload) {
    return ApiService.put(
      "api/v1/projects/" +
        payload.projectId +
        "/project_room_setups/" +
        payload.id,
      payload.data
    )
      .then(res => {
        context.commit(SET_PROJECT_ROOM_SETUP, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ROOM_SETUP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_ROOM_SETUP](context, payload) {
    return ApiService.delete(
      "api/v1/projects/" +
        payload.projectId +
        "/project_room_setups/" +
        payload.id
    )
      .then(() => {
        context.commit(CLEAR_PROJECT_ROOM_SETUP);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_ROOM_SETUP_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_ROOM_SETUP_ERRORS](context) {
    context.commit(CLEAR_PROJECT_ROOM_SETUP_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_ROOM_SETUP](state, projectRoomSetup) {
    state.project_room_setup = projectRoomSetup;
  },
  [SET_PROJECT_ROOM_SETUP_LIST](state, projectRoomSetups) {
    state.project_room_setup_list = projectRoomSetups;
  },
  [CLEAR_PROJECT_ROOM_SETUP](state) {
    state.project_room_setup = {};
    state.project_room_setup_list = [];
  },
  [SET_PROJECT_ROOM_SETUP_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_PROJECT_ROOM_SETUP_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
