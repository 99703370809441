import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_NOTE_ENGAGEMENTS_LIST =
  "populateActivityNoteEngagementsList";
export const POPULATE_LEGACY_NOTE_ENGAGEMENTS_LIST =
  "populateLegacyNoteEngagementsList";
export const POPULATE_ACTIVITY_NOTE_ENGAGEMENTS_INFO =
  "populateActivityNoteEngagementsInfo";
export const CREATE_NOTE_ENGAGEMENTS = "createNoteEngagements";
export const UPDATE_NOTE_ENGAGEMENTS = "updateNoteEngagements";
export const DELETE_NOTE_ENGAGEMENTS = "deleteNoteEngagements";
export const CLEAR_NOTE_ENGAGEMENTS_LIST = "clearNoteEngagementsList";
export const CLEAR_NOTE_ENGAGEMENTS_INFO = "clearNoteEngagementsInfo";
export const CLEAR_NOTE_ENGAGEMENTS_ERRORS = "clearNoteEngagementsErrors";

// mutation types
export const SET_ACTIVITY_NOTE_ENGAGEMENTS_LIST =
  "setActivityNoteEngagementsList";
export const SET_LEGACY_NOTE_ENGAGEMENTS_LIST =
  "setLegacyNoteEngagementsList";
export const RESET_ACTIVITY_NOTE_ENGAGEMENTS_LIST =
  "resetActivityNoteEngagementsList";
export const SET_ACTIVITY_NOTE_ENGAGEMENTS_INFO =
  "setActivityNoteEngagementsInfo";
export const RESET_ACTIVITY_NOTE_ENGAGEMENTS_INFO =
  "resetActivityNoteEngagementsInfo";
export const ADD_ACTIVITY_NOTE_ENGAGEMENTS = "addActivityNoteEngagements";
export const UPDATE_ACTIVITY_NOTE_ENGAGEMENTS = "updateActivityNoteEngagements";
export const REMOVE_ACTIVITY_NOTE_ENGAGEMENTS = "removeActivityNoteEngagements";
export const SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS =
  "setActivityNoteEngagementsErrors";
export const RESET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS =
  "resetActivityNoteEngagementsErrors";

const state = {
  activity_note_engagements_errors: {},
  activity_note_engagements_list: [],
  legacy_note_engagements_list: [],
  activity_note_engagements_info: []
};

const getters = {
  activityNoteEngagementsErrors(state) {
    return state.activity_note_engagements_errors;
  },
  activityNoteEngagementsList(state) {
    return state.activity_note_engagements_list;
  },
  legacyNoteEngagementsList(state) {
    return state.legacy_note_engagements_list;
  },
  activityNoteEngagementsInfo(state) {
    return state.activity_note_engagements_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_NOTE_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" + payload.activityId + "/activity_response_notes",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_response_notes;
        context.commit(SET_ACTIVITY_NOTE_ENGAGEMENTS_LIST, responses);
        return responses;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_LEGACY_NOTE_ENGAGEMENTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId + "/legacy_response_notes",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data;
        context.commit(SET_ACTIVITY_NOTE_ENGAGEMENTS_LIST, responses);
        return responses;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_NOTE_ENGAGEMENTS_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_notes/" +
        payload.activityNoteEngagementId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_NOTE_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_NOTE_ENGAGEMENTS](context, payload) {
    return ApiService.post(
      "api/v1/activities/" + payload.activityId + "/activity_response_notes",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_NOTE_ENGAGEMENTS, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_NOTE_ENGAGEMENTS](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_notes" +
        payload.activityNoteEngagementId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_NOTE_ENGAGEMENTS_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_NOTE_ENGAGEMENTS](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_response_notes/" +
        payload.activityNoteEngagementId
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_NOTE_ENGAGEMENTS_LIST](context) {
    context.commit(RESET_ACTIVITY_NOTE_ENGAGEMENTS_LIST);
  },
  [CLEAR_NOTE_ENGAGEMENTS_INFO](context) {
    context.commit(RESET_ACTIVITY_NOTE_ENGAGEMENTS_INFO);
  },
  [CLEAR_NOTE_ENGAGEMENTS_ERRORS](context) {
    context.commit(RESET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_NOTE_ENGAGEMENTS_LIST](state, activity_note_engagements_list) {
    state.activity_note_engagements_list = activity_note_engagements_list;
  },
  [SET_LEGACY_NOTE_ENGAGEMENTS_LIST](state, legacy_note_engagements_list) {
    state.legacy_note_engagements_list = legacy_note_engagements_list;
  },
  [RESET_ACTIVITY_NOTE_ENGAGEMENTS_LIST](state) {
    state.activity_note_engagements_list = [];
  },
  [SET_ACTIVITY_NOTE_ENGAGEMENTS_INFO](state, activity_note_engagements_info) {
    state.activity_note_engagements_info = activity_note_engagements_info;
  },
  [RESET_ACTIVITY_NOTE_ENGAGEMENTS_INFO](state) {
    state.activity_note_engagements_info = [];
  },
  [ADD_ACTIVITY_NOTE_ENGAGEMENTS](state, activity_note_engagements_info) {
    state.activity_note_engagements_list.push(activity_note_engagements_info);
  },
  [UPDATE_ACTIVITY_NOTE_ENGAGEMENTS](state, activity_note_engagements_info) {
    let newActivityNoteEngagementsArray = [];
    newActivityNoteEngagementsArray.push(activity_note_engagements_info);
    state.activity_note_engagements_list = state.activity_note_engagements_list.map(
      obj => newActivityNoteEngagementsArray.find(o => o.id === obj.id) || obj
    );
  },
  [REMOVE_ACTIVITY_NOTE_ENGAGEMENTS](state, activityNoteEngagementId) {
    state.activity_note_engagements_list = state.activity_note_engagements_list.filter(
      function(obj) {
        return obj.id !== activityNoteEngagementId;
      }
    );
  },
  [SET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS](
    state,
    activity_note_engagements_errors
  ) {
    state.activity_note_engagements_errors = activity_note_engagements_errors;
  },
  [RESET_ACTIVITY_NOTE_ENGAGEMENTS_ERRORS](state) {
    state.activity_note_engagements_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
