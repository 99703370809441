import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_NOTIFICATION_PREFERENCES = "populateNotificationPreferences";
export const UPDATE_NOTIFICATION_PREFERENCES = "updateNotificationPreferences";
export const CLEAR_NOTIFICATION_PREFERENCES = "clearNotificationPreferences";
export const CLEAR_NOTIFICATION_ERRORS = "clearNotificationErrors";

// mutation types
export const SET_NOTIFICATION_PREFERENCES_LIST = "setNotificationPreferencesList";
export const RESET_NOTIFICATION_PREFERENCES_LIST = "resetNotificationPreferencesList";
export const SET_NOTIFICATION_PREFERENCES_ERRORS = "setNotificationPreferencesErrors";
export const RESET_NOTIFICATION_PREFERENCES_ERRORS = "resetNotificationPreferencesErrors";

const state = {
  errors: {},
  preferences_list: []
};

const getters = {
  notificationPreferencesErrors(state) {
    return state.errors;
  },
  notificationPreferencesList(state) {
    return state.preferences_list;
  }
};

const actions = {
  [POPULATE_NOTIFICATION_PREFERENCES](context, payload) {
    return ApiService.get("api/v1/users/" + payload.userId + "/system_notify_methods")
    .then(res => {
      const notifyMethods = res.data.data._embedded.system_notify_methods;
      context.commit(SET_NOTIFICATION_PREFERENCES_LIST, notifyMethods);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_NOTIFICATION_PREFERENCES_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [UPDATE_NOTIFICATION_PREFERENCES](context, payload) {
    return ApiService.put("api/v1/system_notify_methods/" + payload.methodId + "/" + payload.value)
    .then(() => {})
    .catch(error => {
      if (error.response) {
        context.commit(SET_NOTIFICATION_PREFERENCES_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CLEAR_NOTIFICATION_PREFERENCES](context) {
    context.commit(RESET_NOTIFICATION_PREFERENCES_LIST);
  },
  [CLEAR_NOTIFICATION_ERRORS](context) {
    context.commit(RESET_NOTIFICATION_PREFERENCES_ERRORS);
  },
};

const mutations = {
  [SET_NOTIFICATION_PREFERENCES_LIST](state, preferences) {
    state.preferences_list = preferences;
  },
  [RESET_NOTIFICATION_PREFERENCES_LIST](state) {
    state.preferences_list = [];
  },
  [SET_NOTIFICATION_PREFERENCES_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_NOTIFICATION_PREFERENCES_ERRORS](state) {
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};