<template>
  <div class="form-group">
    <b-form-checkbox
      :id="id"
      v-model="value"
      :value="1"
      :unchecked-value="0"
      class="mb-0"
      size="lg"
    >
      <p class="pt-1 font-size-base">
        {{ $t(systemOption.form_label) }}
      </p>
    </b-form-checkbox>

    <div class="row d-flex justify-content-start align-items-center">
      <label class="col-3">{{ $t('FORMS.INPUTS.TOLUNA_SAMPLE') }}</label>
      <input ref="ts_url" class="col-8 mb-2 form-control" v-model="ts_url" disabled />
      <div
        v-if="value === 1"
        class="d-flex justify-content-center align-items-center pl-2"
      >
        <a
          href="javascript:void(0)"
          @click="onCopyUrl('ts_url')"
        >
          <span class="svg-icon svg-icon-md mr-2">
            <inline-svg
              src="media/svg/icons/General/Duplicate.svg"
              title="Copy to Clipboard"
            />
          </span>
        </a>

        <a
          href="javascript:void(0)"
          @click="
            instructionUrl = null,
            $refs['instructions-modal'].$refs.modal.show()
            instructionUrl = 'ts_url'
          "
        >
          <span class="svg-icon svg-icon-md">
            <inline-svg
              src="media/svg/icons/Code/Info-circle.svg"
              :title="$t('MODALS.TITLE.INSTRUCTIONS')"
            />
          </span>
        </a>
      </div>
    </div>

    <div class="row d-flex justify-content-start align-items-center">
      <label class="col-3">{{ $t('FORMS.INPUTS.MY_OWN_SAMPLE') }}</label>
      <input ref="moa_url" class="col-8 form-control" v-model="moa_url" disabled />
      <div
        class="d-flex justify-content-center align-items-center pl-2"
        v-if="value === 1"
      >
        <a
          href="javascript:void(0)"
          @click="onCopyUrl('moa_url')"
        >
          <span class="svg-icon svg-icon-md mr-2">
            <inline-svg
              src="media/svg/icons/General/Duplicate.svg"
              title="Copy to Clipboard"
            />
          </span>
        </a>

        <a
          href="javascript:void(0)"
          @click="
            instructionUrl = null,
            $refs['instructions-modal'].$refs.modal.show(),
            instructionUrl = 'moa_url'
          "
        >
          <span class="svg-icon svg-icon-md">
            <inline-svg
              src="media/svg/icons/Code/Info-circle.svg"
              :title="$t('MODALS.TITLE.INSTRUCTIONS')"
            />
          </span>
        </a>
      </div>
    </div>

    <small
      v-if="helper"
      class="px-0 py-5 form-text text-muted"
    >
      {{ $t('FORMS.HELPERS.ADDITIONAL_REGISTRATION') }}
    </small>

    <InstructionsModal
      ref="instructions-modal"
      :instructionUrl="instructionUrl"
      :enabledVariables="enabledVariables"
      :type="type"
      :urls="{
        ts_url: ts_url,
        moa_url: moa_url
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Toast from "@/core/classes/Toast.js";
import InstructionsModal from "@/view/components/modals/VariablesInstructionsModal";

export default {
  name: "RegistrationOptions",

  props: {
    id:         { type: String, required: true },
    type:       { type: Number, required: true },
    helper:     { type: Boolean, required: false, default: false },
    variables:  { type: Array, required: false, function() { return [] } }
  },

  data() {
    return {
      value: 0,
      instructionUrl: null,
      toast: new Toast()
    }
  },

  components: {
    InstructionsModal
  },

  mounted() {
    this.value = (this.projectInfo.projectRegisterTypes.includes(this.type)) ? 1 : 0;
  },

  methods: {
    onCopyUrl(ref) {
      const range = document.createRange();
      range.selectNode(this.$refs[ref]);

      // Select the text content of the div
      window.getSelection().removeAllRanges(); // Clear previous selections
      window.getSelection().addRange(range);

      // Copy the selected text to the clipboard
      document.execCommand('copy');

      // Deselect the text (optional)
      window.getSelection().removeAllRanges();
      let prefix = (ref == 'ts_url') ? "Toluna Panel" : "My Own Sample";
      this.toast.notify(this, `${prefix} url copied to clipboard`, `Registration Link Copied`, 'success');
    },
    applyVariablesToLink: function(baseLink) {
      if (this.enabledVariables.length > 0) {
        baseLink = baseLink + `/`;
      }

      this.enabledVariables.forEach(function(variable) {
        baseLink = baseLink + `${variable.short}=` + `{` + `${variable.long}` + `};`;
      })

      if (this.enabledVariables.length > 0) {
        baseLink = baseLink.slice(0, -1);
      }

      return baseLink;
    }
  },

  watch: {
    value(newValue) {
      this.$emit("registration_option_change", { id: this.type, value: newValue });
    }
  },

  computed: {
    ...mapGetters([
      "systemUserRegisterTypes",
      "projectInfo",
      "projectSegmentations"
    ]),

    systemOption: function() {
      let type = this.type;
      return this.systemUserRegisterTypes.filter(function(registerType) {
        return registerType.id == type;
      })[0];
    },

    ts_url: function() {
      return (this.value == 0) ? "" : this.applyVariablesToLink(this.projectInfo.projectRegisterLink[`${this.systemOption.key}_ts`]);
    },

    moa_url: function() {
      return (this.value == 0) ? "" : this.applyVariablesToLink(this.projectInfo.projectRegisterLink[`${this.systemOption.key}_moa`]);
    },

    enabledVariables: function() {
      let variables = [];

      if (this.variables.includes('profile-question-6')) {
        variables.push({
          name: "Profile Country",
          short: "profile_country",
          long: "profile_country_values",
          description: "ISO 3166-1 Alpha-3 code"
        });
      }

      let enabledSegmentations = this.variables.filter(function(variable) {
        return variable.includes("segmentation-");
      });

      enabledSegmentations.sort();

      const self = this;
      enabledSegmentations.forEach(function(enabledSegmentation) {
        let id = enabledSegmentation.replace("segmentation-","");
        let segmentation = self.projectSegmentations.filter(function(projectSegmentation) {
          return projectSegmentation.id == id;
        });

        if (segmentation.length > 0) {
          segmentation = segmentation[0];
          let additionalVariable = {
            name: segmentation.name,
            short: segmentation.key,
            long: `${segmentation.key}_values`,
            description: ''
          }

          segmentation.project_segments.forEach(function(segment) {
            additionalVariable.description = additionalVariable.description + `${segment.key}(${segment.name}), `
          });

          if (additionalVariable.description.length > 0) {
            additionalVariable.description = additionalVariable.description.slice(0, -2);
          }

          if (additionalVariable.description.length > 0) {
            variables.push(additionalVariable)
          }
        }
      })

      return variables;
    },
  }
}
</script>