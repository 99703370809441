var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('b-form-checkbox',{staticClass:"mb-0",attrs:{"id":_vm.id,"value":1,"unchecked-value":0,"size":"lg"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('p',{staticClass:"pt-1 font-size-base"},[_vm._v(" "+_vm._s(_vm.$t(_vm.systemOption.form_label))+" ")])]),_c('div',{staticClass:"row d-flex justify-content-start align-items-center"},[_c('label',{staticClass:"col-3"},[_vm._v(_vm._s(_vm.$t('FORMS.INPUTS.TOLUNA_SAMPLE')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ts_url),expression:"ts_url"}],ref:"ts_url",staticClass:"col-8 mb-2 form-control",attrs:{"disabled":""},domProps:{"value":(_vm.ts_url)},on:{"input":function($event){if($event.target.composing)return;_vm.ts_url=$event.target.value}}}),(_vm.value === 1)?_c('div',{staticClass:"d-flex justify-content-center align-items-center pl-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onCopyUrl('ts_url')}}},[_c('span',{staticClass:"svg-icon svg-icon-md mr-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Duplicate.svg","title":"Copy to Clipboard"}})],1)]),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.instructionUrl = null,
          _vm.$refs['instructions-modal'].$refs.modal.show()
          _vm.instructionUrl = 'ts_url'}}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Info-circle.svg","title":_vm.$t('MODALS.TITLE.INSTRUCTIONS')}})],1)])]):_vm._e()]),_c('div',{staticClass:"row d-flex justify-content-start align-items-center"},[_c('label',{staticClass:"col-3"},[_vm._v(_vm._s(_vm.$t('FORMS.INPUTS.MY_OWN_SAMPLE')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.moa_url),expression:"moa_url"}],ref:"moa_url",staticClass:"col-8 form-control",attrs:{"disabled":""},domProps:{"value":(_vm.moa_url)},on:{"input":function($event){if($event.target.composing)return;_vm.moa_url=$event.target.value}}}),(_vm.value === 1)?_c('div',{staticClass:"d-flex justify-content-center align-items-center pl-2"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.onCopyUrl('moa_url')}}},[_c('span',{staticClass:"svg-icon svg-icon-md mr-2"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/General/Duplicate.svg","title":"Copy to Clipboard"}})],1)]),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.instructionUrl = null,
          _vm.$refs['instructions-modal'].$refs.modal.show(),
          _vm.instructionUrl = 'moa_url'}}},[_c('span',{staticClass:"svg-icon svg-icon-md"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Info-circle.svg","title":_vm.$t('MODALS.TITLE.INSTRUCTIONS')}})],1)])]):_vm._e()]),(_vm.helper)?_c('small',{staticClass:"px-0 py-5 form-text text-muted"},[_vm._v(" "+_vm._s(_vm.$t('FORMS.HELPERS.ADDITIONAL_REGISTRATION'))+" ")]):_vm._e(),_c('InstructionsModal',{ref:"instructions-modal",attrs:{"instructionUrl":_vm.instructionUrl,"enabledVariables":_vm.enabledVariables,"type":_vm.type,"urls":{
      ts_url: _vm.ts_url,
      moa_url: _vm.moa_url
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }