<template>
  <div>
    <b-form-group
      :id="id+'-group'"
      :label="computedLabel"
      :label-for="id"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError }"
    >
      <template v-for="(option, i) in options">
        <b-form-radio
          :key="i"
          v-model="model.$model"
          :name="id + '-option-' + i"
          :value="option.id"
          :disabled="submissionStates.submitting === true || !newPage"
          size="lg"
          class="mb-2"
        >
          {{ option.name }}
        </b-form-radio>
        <template v-for="(option2, o) in options2">
          <b-form-radio
            v-if="(option.id == 1 || option.id == 3) && model.$model == i + 1"
            :key="'child_option-' + i + '-' + o"
            v-model="model2.$model"
            :name="id + '-option-' + i + '-' + o"
            :value="option2.id"
            :disabled="submissionStates.submitting === true"
            size="lg"
            class="mb-2 ml-7"
          >
            {{ option2.name }}
          </b-form-radio>
        </template>
      </template>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTypeRadioOption",

  props: {
    model: { type: Object, required: true },
    model2: { type: Object, required: true },
    options: { type: Array, required: true },
    options2: { type:Array, required: true },
    submissionStates: { type: Object, required: true },
    id: { type: String, required: true },
    label: { type: String, required: true },
    error: { type: String, required: true },
    newPage: { type: Boolean, required: true }
  },
  
  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  computed: {
    computedLabel: function() {
      if (this.model.$params.hasOwnProperty('required')) {
        return this.label + "*";
      } else {
        return this.label;
      }
    },
  }
}
</script>