<template>
  <b-form-group
    :id="id + 'group'"
    :label="computedLabel"
    :label-for="id"
    :label-sr-only="!displayLabel"
    class="w-100 mb-0"
    :class="computedLabelAlignment"
    :label-cols-sm="computedLabelColsSM"
    :label-cols-lg="computedLabelColsLG"
  >
    <template v-for="(parent, p) in options">
      <b-form-checkbox
        :id="id + '-parent-' + p"
        :name="id"
        :key="p"
        :value="1"
        unchecked-value="0"
        size="lg"
        :disabled="submissionStates.submitting === true"
        @change="propagateChildren($event, p)"
      >
        <p class="pt-1 mb-2">{{ parent[computedLabelKey] }}</p>
      </b-form-checkbox>
      <template v-for="(child, c) in parent[children_key]">
        <b-form-checkbox
          :id="parent.id + '-child-' + child.id"
          :ref="p"
          class="ml-7"
          :name="id"
          :key="p + '-' + c"
          :value="child.id"
          unchecked-value="0"
          size="lg"
          :disabled="submissionStates.submitting === true"
          @change="updateChild($event, parent.id + '-child-' + child.id)"
        >
          <p class="pt-1 mb-2">{{ child[computedLabelKey] }}</p>
        </b-form-checkbox>
      </template>
    </template>
  </b-form-group>
</template>

<script>
export default {
  name: "GenericNestedCheckbox",

  props: {
    id: { type: String, required: true },
    label: { type: String, required: false },
    alignLabel: { type: String, required: false },
    error: { type: String, required: false},
    showInline: { type: Boolean, required: true },
    submissionStates: { type: Object, required: true },
    options: { type: Array, required: true },
    id_key: { type: String, required: false },
    children_key: { type: String, required: true },
    label_key: { type: String, required: false },
    initialSelection: { type: Array, required: false }
  },

  data() {
    return {
      children: []
    }
  },

  mounted() {
    this.initialSelection.forEach((selection) => {
      document.getElementById(selection.parent + '-child-' + selection.id).click();
    });
  },

  methods: {
    updateChild: function(event, id) {
      let val = Number(document.getElementById(id).value);

      if (event != 0) {
        if (this.children.includes(val) == false) {
          this.children.push(val);
        }
      } else {
        if (this.children.includes(val)) {
          const index = this.children.indexOf(val);
          this.children.splice(index, 1);
        }
      }
    },
    propagateChildren: function($event, p) {
      var self = this;
      this.$refs[p].forEach(function (item) {
        let val = item.value;

        if ($event == 1) {
          if (self.children.includes(val) == false) {
            document.getElementById(item.id).click();
          }
        } else {
          if (self.children.includes(val) == true) {
            document.getElementById(item.id).click();
          }
        }
      });
    }
  },

  watch: {
    children: {
      handler(val) {
        this.$emit('children_changed',val);
      },
      deep: true
    },
    initialSelection: {
      handler() {
        this.initialSelection.forEach((selection) => {
          document.getElementById(selection.parent + '-child-' + selection.id).click();
        });
      },
      deep: true
    }
  },

  computed: {
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    displayError: function() {
      if (this.error != undefined) {
        if (this.error.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    computedLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return this.label;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    computedLabelColsSM: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 4;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelColsLG: function() {
      if (this.displayLabel) {
        if (this.showInline) {
          return 3;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    computedLabelAlignment: function() {
      if (this.alignLabel != undefined) {
        if (this.alignLabel == "right") {
          return "text-right";
        } else if (this.alignLabel == "center") {
          return "text-center";
        } else {
          return "text-left";
        }
      } else {
        return "text-left";
      }
    },
    computedIdKey: function() {
      if (this.id_key != undefined) {
        return this.id_key;
      } else {
        return "id";
      }
    },
    computedLabelKey: function() {
      if (this.label_key != undefined) {
        return this.label_key;
      } else {
        return "label";
      }
    }
  }
};
</script>
