<template>
  <div>
    <b-form-group
      :id="id+'-group'"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError || slim }"
    >
      <label
        v-if="computedDisplayLabel"
        :id="id+'-label'"
        :style="type == 'datetime' ? 'cursor: help' : null"
        :for="id"
      >
        {{ computedLabel }}
      </label>
      <date-picker
        class="w-100"
        :id="id"
        :name="id"
        v-model="value"
        :disabled="submissionStates.submitting === true || computedDisabled"
        :type="type"
        :value-type="computedValueFormat"
        @change="updateDate()"
        :placeholder="label"
        :format="computedFormat"
        :disabled-date="illegalDates"
        :disabled-time="illegalTimes"
      >
      </date-picker>
      <b-form-text
        v-if="helper != undefined"
        class="px-5 py-1"
        :id="'helper-' + id"
      >
        {{ helper }}
      </b-form-text>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ error }}
    </div>
    <b-tooltip v-if="type == 'datetime'" placement="right" :target="id+'-label'" triggers="hover">
      {{ computedTooltip }}
    </b-tooltip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "GenericDatetimeSelector",

  components: { DatePicker },

  props: {
    model: { required: true, type: Object },
    submissionStates: { required: true, type: Object },
    id: { required: true, type: String },
    type: { required: true, type: String },
    label: { required: false, type: String },
    error: { required: false, type: String },
    notBefore: { required: false, type: String },
    notAfter: { required: false, type: String },
    helper: { required: false, type: String },
    disabled: { required: false, type: Boolean },
    storedAsDate: { required: false, type: Boolean },
    slim: { required: false, type: Boolean, default: false }
  },

  data() {
    return {
      value: null
    }
  },

  watch: {
    calculatedModelDate: function () {
      this.updateDates();
    }
  },

  mounted() {
    if (this.type == 'datetime') {
      this.value = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD HH:mm:ss");
    } else {
      this.value = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD");
    };
  },

  methods: {
    updateDate() {
      let offset = null;
      if (this.type == "date") {
        offset = this.convertFromUTCTimezone(this.value, "HH:mm Z");
      } else {
        offset = this.convertFromUTCTimezone(this.value, "Z");
      };
      this.value =  this.value + ' ' + offset;
      let utcDate = moment.utc(this.value, "YYYY-MM-DD HH:mm Z").format("YYYY-MM-DD HH:mm:ss");
      
      this.$emit('date_updated', utcDate);
    },
    updateDates() {
      if (this.type == 'datetime') {
        this.value = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD HH:mm:ss");
      } else {
        this.value = this.convertFromUTCTimezone(this.dateUTC, "YYYY-MM-DD");
      };
    },
    convertFromUTCTimezone(date, format) {
      if (this.currentUser.system_timezone_setting_id != undefined) {
        if (this.currentUser.system_timezone_setting_id == 1) {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
        } else if (this.currentUser.system_timezone_setting_id == 2) {
          if (this.projectInfo.systemTimezone != null) {
            return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.projectInfo.systemTimezone.offset).format(format);
          } else {
            return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
          };
        } else {
          return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").utcOffset(this.currentUser.systemTimezone.offset).format(format);
        }
      } else {
        return moment.utc(date, "YYYY-MM-DD HH:mm:ss Z").local().format(format);
      }
    },
    illegalDates: function(date) {
      if (this.computedMinDate != null && this.computedMaxDate != null) {
        return date < new Date(this.computedMinDate).setHours(0, 0, 0, 0) || date > new Date(this.computedMaxDate).setHours(23, 59, 59, 999);
      } else if (this.computedMinDate != null) {
        return date < new Date(this.computedMinDate).setHours(0, 0, 0, 0);
      } else if (this.computedMaxDate != null) {
        return date > new Date(this.computedMaxDate).setHours(23, 59, 59, 999);
      } else {
        return false;
      }
    },
    illegalTimes: function(date) {
      if (this.computedMinTime != null && this.computedMaxTime != null) {
        return date < new Date(this.computedMinTime) || date > new Date(this.computedMaxTime);
      } else if (this.computedMinTime != null) {
        return date < new Date(this.computedMinTime);
      } else if (this.computedMaxTime != null) {
        return date > new Date(this.computedMaxTime)
      } else {
        return false;
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentUser",
      "projectInfo"
    ]),
    calculatedModelDate: function() {
      return this.model.$model;
    },
    computedTooltip: function() {
      var tooltip = 'UTC';
      tooltip = tooltip + this.convertFromUTCTimezone(this.dateUTC, "ZZ");
      
      return tooltip;
    },
    computedDisplayLabel: function() {
      return (this.label != undefined) ? true : false;    
    },
    computedLabel: function() {
      if (this.model.$params.hasOwnProperty('required')) {
        if (this.model.required) {
          return this.label + "*";
        } else {
          return this.label;
        }
      } else {
        return this.label;
      }
    },
    computedMaxDate: function() {
      let maxDate = null; 

      if (this.notAfter != null) {
        maxDate = this.notAfter + ' +0000';
        maxDate = this.convertFromUTCTimezone(maxDate, "YYYY-MM-DD");
      };

      return maxDate;
    },
    computedMinDate: function() {
      let minDate = null; 

      if (this.notBefore != null) {
        minDate = this.notBefore + ' +0000';
        minDate = this.convertFromUTCTimezone(minDate, "YYYY-MM-DD");
      };

      return minDate;
    },
    computedMaxTime: function() {
      let maxTime = null; 

      if (this.notAfter != null && this.type != "date") {
        maxTime = this.notAfter + ' +0000';
        maxTime = this.convertFromUTCTimezone(maxTime, "YYYY-MM-DD HH:mm:ss");
      };

      return maxTime;
    },
    computedMinTime: function() {
      let minTime = null; 

      if (this.notBefore != null && this.type != "date") {
        minTime = this.notBefore + ' +0000';
        minTime = this.convertFromUTCTimezone(minTime, "YYYY-MM-DD HH:mm:ss");
      };

      return minTime;
    },
    computedFormat: function() {
      if (this.type == "datetime") {
        return "D MMM YYYY HH:mm";
      } else {
        return "D MMM YYYY";
      }
    },
    computedValueFormat: function() {
      if (this.type == "datetime") {
        return "YYYY-MM-DD HH:mm";
      } else {
        return "YYYY-MM-DD";
      }
    },
    computedDisabled: function() {
      if (this.disabled != undefined) {
        return this.disabled;
      } else {
        return false
      }
    },
    dateUTC: function() {
      if (this.storedAsDate != null) {
        if (this.storedAsDate == true) {
          return this.model.$model + ' 00:00:00 +0000'
        } else {
          return this.model.$model + ' +0000'
        }
      } else {
        return this.model.$model + ' +0000'
      }
    }
  }
}
</script>