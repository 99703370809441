import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PAGE_LIST = "populatePageList";
export const POPULATE_PAGE_INFO = "populatePageInfo";
export const CREATE_PAGE = "createPage";
export const UPDATE_PAGE = "updatePage";
export const UPDATE_PAGE_ORDER = "updatePageOrder";
export const DELETE_PAGE = "deletePage";
export const MUTATE_PAGE_SECTION = "mutatePageSection";
export const CLEAR_PAGE_LIST = "clearPageList";
export const CLEAR_PAGE_INFO = "clearPageInfo";
export const CLEAR_PAGE_ERRORS = "clearPageErrors";

// mutation types
export const SET_PAGE_LIST = "setPageList";
export const RESET_PAGE_LIST = "resetPageList";
export const SET_PAGE_INFO = "setPageInfo";
export const RESET_PAGE_INFO = "resetPageInfo";
export const ADD_PAGE_TO_LIST = "addPageToList";
export const UPDATE_PAGE_IN_LIST = "updatePageInList";
export const REMOVE_PAGE_IN_LIST = "removePageInList";
export const REMOVE_PAGE_SECTION_IN_LIST = "removePageSectionInList";
export const SET_PAGE_ERRORS = "setPageErrors";
export const RESET_PAGE_ERRORS = "resetPageErrors";

const state = {
  page_errors: {},
  page_list: [],
  page_info: [],
};

const getters = {
  pageErrors(state) {
    return state.page_errors;
  },
  pageList(state) {
    return state.page_list;
  },
  pageInfo(state) {
    return state.page_info;
  }
};

const actions = {
  [POPULATE_PAGE_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/pages", { params: payload.params }
    )
      .then(res => {
        const pages = res.data.data._embedded.pages;
        context.commit(SET_PAGE_LIST, pages);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PAGE_INFO](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/pages/" + payload.id, { params: payload.params }
    )
      .then(res => {
        const page = res.data.data;
        context.commit(SET_PAGE_INFO, page);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_PAGE](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + '/pages', payload.data)
      .then(res => {
        context.commit(ADD_PAGE_TO_LIST, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PAGE](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/pages/" + payload.id, payload.data)
      .then(res => {
        context.commit(UPDATE_PAGE_IN_LIST, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PAGE_ORDER](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/page_order", payload.data)
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PAGE](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + "/pages/" + payload.id)
      .then(() => {
        context.commit(REMOVE_PAGE_IN_LIST, payload.id);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [MUTATE_PAGE_SECTION](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/pages/" + payload.id, { params: payload.params }
    )
      .then(res => {
        const page = res.data.data;
        context.commit(UPDATE_PAGE_IN_LIST, page);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PAGE_LIST](context) {
    context.commit(RESET_PAGE_LIST);
  },
  [CLEAR_PAGE_INFO](context) {
    context.commit(RESET_PAGE_INFO);
  },
  [CLEAR_PAGE_ERRORS](context) {
    context.commit(RESET_PAGE_ERRORS);
  }
};

const mutations = {
  [SET_PAGE_LIST](state, pages) {
    state.page_list = pages;
  },
  [RESET_PAGE_LIST](state) {
    state.page_list = [];
  },
  [SET_PAGE_INFO](state, page) {
    state.page_info = page;
  },
  [RESET_PAGE_INFO](state) {
    state.page_info = {};
  },
  [ADD_PAGE_TO_LIST](state, page) {
    state.page_list.push(page);
  },
  [UPDATE_PAGE_IN_LIST](state, page) {
    let newPageArray = [];
    newPageArray.push(page);
    state.page_list = state.page_list.map(obj => newPageArray.find(o => o.id === obj.id) || obj);
  },
  [REMOVE_PAGE_IN_LIST](state, pageId) {
    state.page_list = state.page_list.filter(function( obj ) {
      return obj.id !== pageId;
    });
  },
  [SET_PAGE_ERRORS](state,errors) {
    state.page_errors = errors;
  },
  [RESET_PAGE_ERRORS](state) {
    state.page_errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};