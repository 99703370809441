import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_REPORTS_LIST = "populateProjectReportsList";
export const CREATE_PROJECT_REPORT = "createProjectReport";
export const DELETE_PROJECT_REPORT = "deleteProjectReport";
export const CLEAR_PROJECT_REPORTS_LIST = "clearProjectReportsList";
export const CLEAR_PROJECT_REPORTS_ERRORS = "clearProjectReportsErrors";

// mutation types
export const SET_PROJECT_REPORTS_LIST = "setProjectProfilesList";
export const RESET_PROJECT_REPORTS_LIST = "resetProjectProfilesList";
export const SET_PROJECT_REPORTS_ERRORS = "setProjectProfilesErrors";
export const RESET_PROJECT_REPORTS_ERRORS = "resetProjectProfilesErrors";
export const ADD_NEW_REPORT = "addNewReport";
export const REMOVE_REPORT = "removeReport";

const state = {
  project_reports_list: [],
  errors: {}
};

const getters = {
  projectReports(state) {
    return state.project_reports_list;
  },
  projectReportsErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_REPORTS_LIST](context, payload) {
    return ApiService.get(
      "api/v1/projects/" + payload.projectId +
        "/project_reports", { params: payload.params }
    )
      .then(res => {
        const projectReports = res.data.data._embedded.project_reports;
        context.commit(SET_PROJECT_REPORTS_LIST, projectReports);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_REPORTS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_PROJECT_REPORT](context, payload) {
    return ApiService.post("api/v1/projects/" + payload.projectId + '/project_reports', payload.data)
      .then(res => {
        context.commit(ADD_NEW_REPORT, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_REPORTS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_REPORT](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + '/project_reports/' + payload.projectReportId)
      .then(res => {
        context.commit(REMOVE_REPORT, payload.projectReportId);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_REPORTS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_REPORTS_LIST](context) {
    context.commit(RESET_PROJECT_REPORTS_LIST);
  },
  [CLEAR_PROJECT_REPORTS_ERRORS](context) {
    context.commit(RESET_PROJECT_REPORTS_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_REPORTS_LIST](state, projectReports) {
    state.project_reports_list = projectReports;
  },
  [RESET_PROJECT_REPORTS_LIST](state) {
    state.project_reports_list = {};
  },
  [SET_PROJECT_REPORTS_ERRORS](state,errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_REPORTS_ERRORS](state) {
    state.errors = {};
  },
  [ADD_NEW_REPORT](state, projectReport) {
    state.project_reports_list.push(projectReport);
  },
  [REMOVE_REPORT](state, projectReportId) {
    state.project_reports_list = state.project_reports_list.filter(function( obj ) {
      return obj.id !== projectReportId;
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};