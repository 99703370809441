<template>
  <div>
    <label :for="id">{{ label }}</label>
    <div :id="id" class="fitb-input-wrapper">
      <div class="toolbar">
        <!-- <span class="formats">
          <button
            type="button"
            class="bold square"
          >
            <svg viewBox="0 0 18 18">
              <path class="stroke" d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"></path>
              <path class="stroke" d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"></path>
            </svg>
          </button>
          <button
            type="button"
            class="italic square"
          >
            <svg viewBox="0 0 18 18">
              <line class="stroke" x1="7" x2="13" y1="4" y2="4"></line>
              <line class="stroke" x1="5" x2="11" y1="14" y2="14"></line>
              <line class="stroke" x1="8" x2="10" y1="14" y2="4"></line>
            </svg>
          </button>
          <button
            type="button"
            class="underline square"
          >
            <svg viewBox="0 0 18 18">
              <path class="stroke" d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"></path>
              <rect class="fill" height="1" rx="0.5" ry="0.5" width="12" x="3" y="15"></rect>
            </svg>
          </button>
          <button
            type="button"
            class="strike square"
          >
            <svg viewBox="0 0 18 18">
              <line class="stroke ql-thin" x1="15.5" x2="2.5" y1="8.5" y2="9.5"></line>
              <path class="fill" d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"></path>
              <path class="fill" d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"></path>
            </svg>
          </button>
        </span> -->
        <span class="formats">
          <button
            type="button"
            class="outline"
            :class="{ 'fitb-invalid' : submissionAttempted.state == true && error != null && error.type == 1 }"
            @click="insertSlot('OpenEnded')"
          >
            {{ $t("FITB.BUTTON.INSERT_OE") }}
          </button>
          <button
            type="button"
            class="outline"
            :class="{ 'fitb-invalid' : submissionAttempted.state == true && error != null && error.type == 1 }"
            @click="insertSlot('SingleChoice')"
          >
            {{ $t("FITB.BUTTON.INSERT_SC") }}
          </button>
        </span>
      </div>
      <div class="container">
        <div class="editor" spellcheck="false">
          <div
            id="editable-html"
            v-if="managementType == 'post'"
            ref="editable-html"
            contenteditable
            class="editable-html"
          >
          </div>

          <div
            id="editable-html"
            v-else-if="managementType == 'update'"
            ref="editable-html"
            contenteditable
            class="editable-html"
          >
            <template v-for="(block, b) in initialSlots">
              <OpenEnded
                :key="b"
                v-if="block.type == 'OpenEnded'"
                :initialPlaceholder="block.placeholder"
                :slotNumber="block.slotNumber"
                :managementType="managementType"
                :slotPlaceholder="slotPlaceholder"
                :oeTooltip="oeTooltip"
              ></OpenEnded>
              <SingleChoice
                :key="b"
                v-else-if="block.type == 'SingleChoice'"
                :initialPlaceholder="block.placeholder"
                :initialChoices="block.choices"
                :slotNumber="block.slotNumber"
                :managementType="managementType"
                :slotPlaceholder="slotPlaceholder"
                :choicePlaceholder="choicePlaceholder"
                :scTooltip="scTooltip"
              ></SingleChoice>
              <br :key="b" v-else-if="block.type == 'page_break'" />
              <template v-else>{{ block.placeholder }}</template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <label v-if="submissionAttempted.state == true && error != null" class="custom-validation-failure">{{ error.message }}</label>
  </div>
</template>

<script>
/*eslint-disable */
import Vue from 'vue'
import OpenEnded from "@/modules/together-helpers/components/specialised-inputs/fitbInputOpenEnded";
import SingleChoice from "@/modules/together-helpers/components/specialised-inputs/fitbInputSingleChoice";
import HtmlEditor from "@/modules/together-helpers/helpers/HtmlEditor";

export default {
  name: "fitbEditor",

  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    submissionAttempted: { type: Object, required: true },
    managementType: { type: String, required: true },
    model: { type: Array, required: false },
    index: { type: Number, required: false }
  },

  data() {
    return {
      question: "",
      slotNumber: 1,
      createdSlots: [],
      initialSlots: []
    }
  },

  components: {
    OpenEnded,
    SingleChoice
  },

  mounted() {
    const self = this;
    const editableHtml = document.getElementsByClassName("editable-html")[0];
    editableHtml.addEventListener("keydown", function(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        let htmlEditor = new HtmlEditor(document.createElement('br'), window.getSelection(), self.$refs['editable-html']);
        htmlEditor.insert();
      }
    });

    
    if (this.managementType == 'update' && this.model[0] != undefined) {
      this.initialSlots = this.model[this.index].arrayQuestion;
      this.slotNumber = this.slotNumber + this.initialSlots.filter(function( slot ) {
        return slot.type != "text";
      }).length;
    }
  },

  methods: {
    getSlotNumber: function() {
      let slotNumber = this.slotNumber;
      this.slotNumber+=1;
      return slotNumber;
    },
    insertSlot: function(slotType) {
      var slotClass = (slotType == "OpenEnded") ? Vue.extend(OpenEnded) : Vue.extend(SingleChoice);
      var instance = new slotClass({ propsData: { 
        slotNumber: this.slotNumber,
        managementType: this.managementType,
        slotPlaceholder: this.slotPlaceholder,
        choicePlaceholder: this.choicePlaceholder,
        scTooltip: this.scTooltip,
        oeTooltip: this.oeTooltip
      }});
      instance.$mount();

      let htmlEditor = new HtmlEditor(instance, window.getSelection(), this.$refs['editable-html']);
      htmlEditor.insert();

      const self = this;
      this.forceSlotUpdate().then(() => {
        htmlEditor.newElement.$data.submissionAttempted = self.submissionAttempted.state;
        self.slotNumber++;
      });
    },
    convertHTMLToSlotArray: function() {
      let slotData = [];

      if (this.$refs['editable-html'] != undefined) {
        this.$refs['editable-html'].childNodes.forEach(function(slot) {
          if (slot.__vue__ != undefined) {
            slot = slot.__vue__.$data.slot;
            slotData.push(slot);
          }
        })        
      };

      return slotData;
    },
    forceSlotUpdate: async function() {
      this.$forceUpdate();
      await this.$nextTick();
    },
    inputValid: function() {
      return (this.error == null) ? true : false;
    },
    generateQuestion: function() {
      let question = "";
      let slotCount = 1;

      this.$refs['editable-html'].childNodes.forEach(function(slot) {
        if (slot.__vue__ != undefined) {
          let slotCode = `$[${slotCount}${slot.__vue__.$data.short_code}-question-${slot.__vue__.$data.slot.placeholder}]`;
          question = `${question}${slotCode}`;
          slotCount++;
        } else if (typeof slot == "object" && slot.tagName == "BR") {
          question = `${question}<br>`;
        } else {
          question = `${question}${slot.textContent}`;
        }
      });

      return question;
    },
    generateChoices: function() {
      let choices = [];
      let slotCount = 1;

      this.$refs['editable-html'].childNodes.forEach(function(slot) {
        if (slot.__vue__ != undefined) {
          if (slot.__vue__.$data.short_code == "SC") {
            for (const property in slot.__vue__.$data.slot.choices) {
              choices.push({
                id: slot.__vue__.$data.slot.choices[property]['id'],
                choice: slot.__vue__.$data.slot.choices[property]['choice'],
                slot: slotCount,
                stimulus_assets: [],
                stimuli: []
              });
            }
          }
          slotCount++;
        }
      });

      return choices;
    }
  },

  watch: {
    submissionAttempted(newValue) {
        this.$refs['editable-html'].childNodes.forEach(function(slot) {
          if (slot.__vue__ != undefined) {
            slot = slot.__vue__.$data.submissionAttempted = newValue.state;
          }
        });
    }
  },

  computed: {
    slotPlaceholder: function() {
      return `${this.$t("FITB.PLACEHOLDER.SLOT")}`;
    },
    choicePlaceholder: function() {
      return `${this.$t("FITB.PLACEHOLDER.CHOICE")}`;
    },
    scTooltip: function() {
      return `${this.$t("FITB.TOOLTIP.SINGLE_CHOICE")}`;
    },
    oeTooltip: function() {
      return `${this.$t("FITB.TOOLTIP.OPEN_ENDED")}`;
    },
    error: function() {
      this.slotNumber;
      this.submissionAttempted.attempts;
      let slots = this.convertHTMLToSlotArray();

      let errorSlots = slots.filter(function( slot ) {
        return slot.type == "OpenEnded" && slot.placeholder.length == 0 ||
          slot.type == "SingleChoice" && (slot.placeholder.length == 0 || Object.keys(slot.choices).length < 2);
      });

      let uniqueSlotPlaceholders = slots.filter(
        (obj, index) =>
          slots.findIndex((item) => item.placeholder === obj.placeholder) === index
      );

      let duplicateSlotChoices = false;
      slots.forEach(function(slot) {
        if (slot.type == "SingleChoice") {
          let choices = Object.values(slot['choices']);
          let uniqueSlotChoices = choices.filter(
            (obj, index) =>
              choices.findIndex((item) => item.choice === obj.choice) === index
          );
          if (uniqueSlotChoices.length < choices.length) {
            duplicateSlotChoices = true;
          }
        }
      });

      if (slots.length == 0) {
        return {
          type: 1,
          message: `${this.$t("FITB.ERROR.TYPE_1")}`
          
        }
      } else if (errorSlots.length > 0) {
        return {
          type: 2,
          message: `${this.$t("FITB.ERROR.TYPE_2")}`
        }
      } else if (uniqueSlotPlaceholders.length < slots.length) {
        return {
          type: 3,
          message: `${this.$t("FITB.ERROR.TYPE_3")}`
        }
      } else if (duplicateSlotChoices) {
        return {
          type: 4,
          message: `${this.$t("FITB.ERROR.TYPE_4")}`
        }
      } else {
        return null;
      }
    }
  }
}
/*eslint-enable */
</script>