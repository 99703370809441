import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_PAGE_SECTION_LIST = "populatePageSectionList";
export const POPULATE_PAGE_SECTION_INFO = "populatePageSectionInfo";
export const CREATE_PAGE_SECTION = "createPageSection";
export const UPDATE_PAGE_SECTION = "updatePageSection";
export const UPDATE_PAGE_SECTION_ORDER = "updatePageSectionOrder";
export const DELETE_PAGE_SECTION = "deletePageSection";
export const CLEAR_PAGE_SECTION_LIST = "clearPageSectionList";
export const CLEAR_PAGE_SECTION_INFO = "clearPageSectionInfo";
export const CLEAR_PAGE_SECTION_ERRORS = "clearPageSectionErrors";

// mutation types
export const SET_PAGE_SECTION_LIST = "setPageSectionList";
export const RESET_PAGE_SECTION_LIST = "resetPageSectionList";
export const SET_PAGE_SECTION_INFO = "setPageSectionInfo";
export const RESET_PAGE_SECTION_INFO = "resetPageSectionInfo";
export const ADD_PAGE_SECTION_TO_LIST = "addPageSectionToList";
export const UPDATE_PAGE_SECTION_IN_LIST = "updatePageSectionInList";
export const REMOVE_PAGE_SECTION_IN_LIST = "removePageSectionInList";
export const SET_PAGE_SECTION_ERRORS = "setPageSectionErrors";
export const RESET_PAGE_SECTION_ERRORS = "resetPageSectionErrors";

const state = {
  page_section_errors: {},
  page_section_list: [],
  page_section_info: [],
};

const getters = {
  pageSectionErrors(state) {
    return state.page_section_errors;
  },
  pageSectionList(state) {
    return state.page_section_list;
  },
  pageSectionInfo(state) {
    return state.page_section_info;
  }
};

const actions = {
  [POPULATE_PAGE_SECTION_LIST](context, payload) {
    return ApiService.get(
      "api/v1/pages/" + payload.pageId +
        "/page_sections", { params: payload.params }
    )
      .then(res => {
        const pageSections = res.data.data._embedded.page_sections;
        context.commit(SET_PAGE_SECTION_LIST, pageSections);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [POPULATE_PAGE_SECTION_INFO](context, payload) {
    return ApiService.get(
      "api/v1/pages/" + payload.pageId +
        "/page_sections/" + payload.id, { params: payload.params }
    )
      .then(res => {
        const pageSection = res.data.data;
        context.commit(SET_PAGE_SECTION_INFO, pageSection);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CREATE_PAGE_SECTION](context, payload) {
    return ApiService.post("api/v1/pages/" + payload.pageId + '/page_sections', payload.data)
      .then(res => {
        context.commit(ADD_PAGE_SECTION_TO_LIST, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PAGE_SECTION](context, payload) {
    return ApiService.put("api/v1/pages/" + payload.pageId + "/page_sections/" + payload.id, payload.data)
      .then(res => {
        context.commit(UPDATE_PAGE_SECTION_IN_LIST, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPDATE_PAGE_SECTION_ORDER](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/pages/" + payload.id + "/page_order", payload.data)
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PAGE_SECTION](context, payload) {
    return ApiService.delete("api/v1/pages/" + payload.pageId + "/page_sections/" + payload.id)
      .then(() => {
        context.commit(REMOVE_PAGE_SECTION_IN_LIST, payload.id);
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PAGE_SECTION_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PAGE_SECTION_LIST](context) {
    context.commit(RESET_PAGE_SECTION_LIST);
  },
  [CLEAR_PAGE_SECTION_INFO](context) {
    context.commit(RESET_PAGE_SECTION_INFO);
  },
  [CLEAR_PAGE_SECTION_ERRORS](context) {
    context.commit(RESET_PAGE_SECTION_ERRORS);
  }
};

const mutations = {
  [SET_PAGE_SECTION_LIST](state, pageSections) {
    state.page_section_list = pageSections;
  },
  [RESET_PAGE_SECTION_LIST](state) {
    state.page_section_list = [];
  },
  [SET_PAGE_SECTION_INFO](state, pageSection) {
    state.page_section_info = pageSection;
  },
  [RESET_PAGE_SECTION_INFO](state) {
    state.page_section_info = {};
  },
  [ADD_PAGE_SECTION_TO_LIST](state, pageSection) {
    state.page_section_list.push(pageSection);
  },
  [UPDATE_PAGE_SECTION_IN_LIST](state, pageSection) {
    let newPageSectionArray = [];
    newPageSectionArray.push(pageSection);
    state.page_section_list = state.page_section_list.map(obj => newPageSectionArray.find(o => o.id === obj.id) || obj);
  },
  [REMOVE_PAGE_SECTION_IN_LIST](state, pageSectionId) {
    state.page_section_list = state.page_section_list.filter(function( obj ) {
      return obj.id !== pageSectionId;
    });
  },
  [SET_PAGE_SECTION_ERRORS](state,errors) {
    state.page_section_errors = errors;
  },
  [RESET_PAGE_SECTION_ERRORS](state) {
    state.page_section_errors = {};
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};