import ApiService from "@/core/services/api.service";

// action types
export const POPULATE_POLL_QUESTION_LIST = "populatePollQuestionList";

// mutation types


const state = {};

const getters = {};

const actions = {
  [POPULATE_POLL_QUESTION_LIST](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_poll_questions", {
      params: payload.params
    }).then((res) => {
      const pollQuestions = res.data.data._embedded.activity_poll_questions;
      return pollQuestions;
    })
    .catch(error => {
      throw error;
    });
  }
}

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters
};