import ApiService from "@/core/services/api.service";
import Vue from "vue";

//action types
export const POPULATE_PROJECT_USERS_LIST = "populateProjectUsersList";
export const POPULATE_LEGACY_PROJECT_USERS_LIST = "populateLegacyProjectUsersList";
export const POPULATE_PROJECT_USER_INFO = "populateProjectUsersInfo";
export const POPULATE_LEGACY_PROJECT_USER_INFO = "populateLegacyProjectUsersInfo";
export const CREATE_PROJECT_USER = "createProjectUser";
export const UPDATE_PROJECT_USER = "updateProjectUser";
export const DELETE_PROJECT_USER = "deleteProjectUser";
export const UPLOAD_PROJECT_USERS = "uploadProjectUsers";
export const PROCESS_UPLOADED_PROJECT_USERS = "processUploadedProjectUsers";
export const CLEAR_PROJECT_USERS_LIST = "clearProjectUsersList";
export const CLEAR_PROJECT_USERS_INFO = "clearProjectUsersInfo";
export const CLEAR_PROJECT_USERS_ERRORS = "clearProjectUsersErrors";

// mutation types
export const SET_PROJECT_USERS_LIST = "setProjectUsersList";
export const SET_LEGACY_PROJECT_USERS_LIST = "setLegacyProjectUsersList";
export const RESET_PROJECT_USERS_LIST = "resetProjectUsersList";
export const SET_PROJECT_USERS_INFO = "setProjectUsersInfo";
export const RESET_PROJECT_USERS_INFO = "resetProjectUsersInfo";
export const SET_PROJECT_USERS_META = "setProjectUsersMeta";
export const RESET_PROJECT_USERS_META = "resetProjectUsersMeta";
export const SET_PROJECT_USERS_ERRORS = "setProjectUsersErrors";
export const RESET_PROJECT_USERS_ERRORS = "resetProjectUsersErrors";
export const REMOVE_PROJECT_USER = "removeProjectUser";

const state = {
  project_users_list: [],
  legacy_project_users_list: [],
  project_users_info: {},
  project_users_meta: {},
  errors: {}
};

const getters = {
  projectUsers(state) {
    return state.project_users_list;
  },
  legacyProjectUsers(state) {
    return state.legacy_project_users_list;
  },
  projectUser(state) {
    return state.project_users_info;
  },
  projectUsersMeta(state) {
    return state.project_users_meta;
  },
  projectUsersErrors(state) {
    return state.errors;
  }
};

const actions = {
  [POPULATE_PROJECT_USERS_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/users", {
      params: payload.params
    })
    .then(res => {
      const projectUsers = res.data.data._embedded.users;
      context.commit(SET_PROJECT_USERS_LIST, projectUsers);
      if (res.data.meta != undefined) {
        context.commit(SET_PROJECT_USERS_META, res.data.meta);
      }
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_LEGACY_PROJECT_USERS_LIST](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/legacy_users", {
      params: payload.params
    })
    .then(res => {
      const projectUsers = res.data;
      context.commit(SET_LEGACY_PROJECT_USERS_LIST, projectUsers);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_PROJECT_USER_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/users/" + payload.userId, {
      params: payload.params
    })
    .then(res => {
      const projectUser = res.data.data;
      context.commit(SET_PROJECT_USERS_INFO, projectUser);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [POPULATE_LEGACY_PROJECT_USER_INFO](context, payload) {
    return ApiService.get("api/v1/projects/" + payload.projectId + "/legacy_users/" + payload.userId, {
      params: payload.params
    })
    .then(res => {
      const legacyProjectUser = res.data;  
      context.commit(SET_PROJECT_USERS_INFO, legacyProjectUser);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error.response);
      }
      throw error;
    });
  },
  [CREATE_PROJECT_USER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        "api/v1/projects/" + payload.projectId + "/users",
        payload.data
      )
      .then(res => {
        const projectUser = res.data.data;
        context.commit(SET_PROJECT_USERS_INFO, projectUser);
        resolve();
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
          reject(error.response);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
    });
  },
  [UPDATE_PROJECT_USER](context, payload) {
    return ApiService.put("api/v1/projects/" + payload.projectId + "/users/" + payload.userId, payload.data)
      .then(res => {
        context.commit(SET_PROJECT_USERS_INFO, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [DELETE_PROJECT_USER](context, payload) {
    return ApiService.delete("api/v1/projects/" + payload.projectId + "/users/" + payload.userId)
      .then(res => {
        context.commit(REMOVE_PROJECT_USER, payload.userId);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [UPLOAD_PROJECT_USERS](context, payload) {
    return ApiService.post(`api/v1/projects/${payload.projectId}/project_user_uploads`, payload.data)
      .then((res) => {
        context.commit(REMOVE_PROJECT_USER, payload.userId);
        return res.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [PROCESS_UPLOADED_PROJECT_USERS](context, payload) {
    return ApiService.put(`api/v1/projects/${payload.projectId}/project_user_uploads/${payload.id}`)
      .catch(error => {
        if (error.response) {
          context.commit(SET_PROJECT_USERS_ERRORS, error.response.data);
        } else {
          Vue.$log.error(error.response);
        }
        throw error;
      });
  },
  [CLEAR_PROJECT_USERS_LIST](context) {
    context.commit(RESET_PROJECT_USERS_LIST);
    context.commit(RESET_PROJECT_USERS_META);
  },
  [CLEAR_PROJECT_USERS_INFO](context) {
    context.commit(RESET_PROJECT_USERS_INFO);
  },
  [CLEAR_PROJECT_USERS_ERRORS](context) {
    context.commit(RESET_PROJECT_USERS_ERRORS);
  }
};

const mutations = {
  [SET_PROJECT_USERS_LIST](state, project_users) {
    state.project_users_list = project_users;
  },
  [SET_LEGACY_PROJECT_USERS_LIST](state, legacy_project_users) {
    state.legacy_project_users_list = legacy_project_users;
  },
  [RESET_PROJECT_USERS_LIST](state) {
    state.project_users_list = {};
  },
  [SET_PROJECT_USERS_INFO](state, project_user) {
    state.project_users_info = project_user;
  },
  [RESET_PROJECT_USERS_INFO](state) {
    state.project_users_info = {};
  },
  [SET_PROJECT_USERS_META](state, project_users_meta) {
    state.project_users_meta = project_users_meta;
  },
  [RESET_PROJECT_USERS_META](state) {
    state.project_users_meta = {};
  },
  [SET_PROJECT_USERS_ERRORS](state, errors) {
    state.errors = errors;
  },
  [RESET_PROJECT_USERS_ERRORS](state) {
    state.errors = {};
  },
  [REMOVE_PROJECT_USER](state, userId) {
    state.project_users_list = state.project_users_list.filter(function( obj ) {
      return obj.id !== userId;
    });
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};