<template>
  <b-form-group
    id="input-activity-title-group"
    :label="this.label != null ? this.label + '*' : $t('FORMS.INPUTS.PROJECT_WELCOME_TITLE') + '*'"
    label-for="input-activity-title"
    class="col-xl-12 px-0"
  >
    <b-form-input
      type="text"
      class="form-control form-control-solid h-auto py-4 px-6"
      id="input-activity-title"
      name="input-activity-title"
      v-model="model.$model"
      :state="validateState()"
      aria-describedby="feedback-activity-title"
      :disabled="submissionStates.submitting === true"
      :placeholder="$t('FORMS.INPUTS.PROJECT_WELCOME_TITLE')"
    ></b-form-input>
    <b-form-invalid-feedback id="feedback-activity-title">
      {{ $t('FORMS.ERROR.ACTIVITY_TITLE') }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
  export default {
    name: "InputActivityTitle",

    props: [
      'model',
      'submissionStates',
      'label'
    ],
    
    methods: {
      validateState() {
        const { $dirty, $error } = this.model;
        return $dirty ? !$error : null;
      }
    }
  };
</script>
