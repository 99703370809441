import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";     //English (UK)
import { locale as fr } from "@/core/config/i18n/fr";     //French
import { locale as es } from "@/core/config/i18n/es";     //Spanish
import { locale as ptPT } from "@/core/config/i18n/ptPT"; //Portuguese
import { locale as zhTW } from "@/core/config/i18n/zhTW"; //Chinese (Traditional)
import { locale as id } from "@/core/config/i18n/id";     //Indonesian
import { locale as zhCN } from "@/core/config/i18n/zhCN"; //Chinese (Mandarin)
import { locale as de } from "@/core/config/i18n/de";     //German
import { locale as ru } from "@/core/config/i18n/ru";     //Russian
import { locale as it } from "@/core/config/i18n/it";     //Italian
import { locale as zhHK } from "@/core/config/i18n/zhHK"; //Chinese (Cantonese)
import { locale as ja } from "@/core/config/i18n/ja";     //Japanese
import { locale as pl } from "@/core/config/i18n/pl";     //Polish
import { locale as nl } from "@/core/config/i18n/nl";     //Dutch
import { locale as cs } from "@/core/config/i18n/cs";     //Czech
import { locale as el } from "@/core/config/i18n/el";     //Greek
import { locale as sv } from "@/core/config/i18n/sv";     //Swedish
import { locale as tr } from "@/core/config/i18n/tr";     //Turkish
import { locale as fi } from "@/core/config/i18n/fi";     //Finnish
import { locale as enUS } from "@/core/config/i18n/enUS"; //English (US)
import { locale as ko } from "@/core/config/i18n/ko";     //Korean
import { locale as hu } from "@/core/config/i18n/hu";     //Hungarian
import { locale as vi } from "@/core/config/i18n/vi";     //Vietnamese
import { locale as no } from "@/core/config/i18n/no";     //Nowegian
import { locale as hy } from "@/core/config/i18n/hy";     //Armenian
import { locale as pt } from "@/core/config/i18n/pt";     //Brazilian
import { locale as frCA } from "@/core/config/i18n/frCA"  //French Canadian
import { locale as hi } from "@/core/config/i18n/hi";     //Hindu
import { locale as th } from "@/core/config/i18n/th";     //Thai
import { locale as ms } from "@/core/config/i18n/ms";     //Malay
import { locale as tl } from "@/core/config/i18n/tl";     //Tagalog

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, fr, es, ptPT, zhTW, id, zhCN, de, ru, it, zhHK, ja, pl, nl, cs, el, sv, tr, fi, enUS, ko, hu, vi, no, hy, pt, frCA, hi, th, ms, tl };

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'en', // set locale
  messages // set locale messages
});

export default i18n;
