import ApiService from "@/core/services/api.service";

// action types
export const POPULATE_PROJECT_COMMUNICATION_PREVIEW = "populateProjectCommunicationPreview";

// mutation types
export const SET_PROJECT_COMMUNICATION_PREVIEW = "setProjectCommunicationPreview";

const state = {
  project_communication_preview: null
};

const getters = {
  projectCommunicationPreview(state) {
    return state.project_communication_preview;
  }
};

const actions = {
  [POPULATE_PROJECT_COMMUNICATION_PREVIEW](context, payload) {
    return ApiService.post(`api/v1/projects/${payload.projectId}/project_email_preview`, payload.params )
      .then(res => {
        const preview = res.data;
        context.commit(SET_PROJECT_COMMUNICATION_PREVIEW, preview);
      })
  }
};

const mutations = {
  [SET_PROJECT_COMMUNICATION_PREVIEW](state, preview) {
    state.project_communication_preview = preview;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};