<template>
  <div
    ref="editable-slot"
    :id="`editable-slot-${slotNumber}`"
    class="fitb d-inline-flex mx-1"
    :contenteditable="false"
  >
    <div
      class="fitb-form d-flex justify-content-between align-items-center"
      :class="{ 'fitb-invalid' : submissionAttempted && isInvalid }"
    >
      <b-form-input
        v-model="slot.placeholder"
        :placeholder="slotPlaceholder"
        class="open-ended px-2"
        @keydown="formatInput($event)"
      >
      </b-form-input>
      <a
        class="d-flex align-items-center"
        href="javascript:void(0)"
        @click="deleteSlot"
      >
        <i class="fas fa-times invalid-indicator-icon icon-sm mx-2"></i>
      </a>
    </div>

    <b-tooltip
      v-if="submissionAttempted && isInvalid"
      :target="`editable-slot-${slotNumber}`"
      triggers="hover"
      placement="right"
    >
      {{ oeTooltip }}
    </b-tooltip>
    &zwnj;
  </div>
</template>

<script>
export default {
  name: "fitbInputOpenEnded",
  
  props: {
    slotNumber: { type: Number, required: true },
    slotPlaceholder: { type: String, required: false, default: "Enter Placeholder" },
    initialPlaceholder: { type: String, required: false },
    managementType: { type: String, required: true },
    oeTooltip: { type: String, required: true }
  },

  data() {
    return {
      short_code: "OE",
      slot: {
        type: "OpenEnded",
        placeholder: ""
      },
      submissionAttempted: false
    }
  },

  methods: {
    deleteSlot: function() {
      document.getElementById(`editable-slot-${this.slotNumber}`).remove();
    },
    getData: function() {
      return this.slot;
    },
    formatInput: function (value) {
      if (!/[a-zA-Z0-9_ \s]/.test(value.key)) {
        this.ignoredValue = value.key ? value.key : "";
        value.preventDefault();
      }
    }
  },

  watch: {
    initialPlaceholder: {
      handler(newPlaceholder) {
        if (newPlaceholder != undefined) {
          this.slot.placeholder = newPlaceholder          
        }
      },
      immediate: true
    }
  },

  computed: {
    isInvalid: function() {
      return (this.slot.placeholder.length == 0) ? true : false;
    }
  }
}
</script>