class Toast {

  constructor() {}

  notify(component, content, title, variant) {
    component.$root.$bvToast.toast(content, {
      title: title,
      autoHideDelay: 5000,
      appendToast: false,
      variant: variant
    });
  }

}

export default Toast;