import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_SURVEY_QUESTION_LIST = "populateSurveyQuestionList";
export const POPULATE_SURVEY_QUESTION_INFO = "populateSurveyQuestionInfo";
export const CLEAR_SURVEY_QUESTION_LIST = "clearSurveyQuestionList";
export const CLEAR_SURVEY_QUESTION_INFO = "clearSurveyQuestionInfo";
export const CLEAR_SURVEY_QUESTION_ERRORS = "clearSurveyQuestionErrors";

// mutation types
export const SET_SURVEY_QUESTION_LIST = "setSurveyQuestionList";
export const RESET_SURVEY_QUESTION_LIST = "resetSurveyQuestionList";
export const SET_SURVEY_QUESTION_LIST_META = "setSurveyQuestionListMeta";
export const RESET_SURVEY_QUESTION_LIST_META = "resetSurveyQuestionListMeta";
export const SET_SURVEY_QUESTION_INFO = "setSurveyQuestionInfo";
export const RESET_SURVEY_QUESTION_INFO = "resetSurveyQuestionInfo";
export const SET_SURVEY_QUESTION_ERRORS = "setSurveyQuestionErrors";
export const RESET_SURVEY_QUESTION_ERRORS = "resetSurveyQuestionErrors";

const state = {
  survey_question_errors: {},
  survey_question_list: [],
  survey_question_list_meta: {},
  survey_question_info: []
};

const getters = {
  surveyQuestionErrors(state) {
    return state.survey_question_errors;
  },
  surveyQuestionList(state) {
    return state.survey_question_list;
  },
  surveyQuestionListMeta(state) {
    return state.survey_question_list_meta;
  },
  surveyQuestionInfo(state) {
    return state.survey_question_info;
  }
};

const actions = {
  [POPULATE_SURVEY_QUESTION_LIST](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_survey_questions", {
      params: payload.params
    }).then((res) => {
      const surveyQuestions = res.data.data._embedded.activity_survey_questions;
      context.commit(SET_SURVEY_QUESTION_LIST, surveyQuestions);
      if (res.data.meta != undefined) {
        context.commit(SET_SURVEY_QUESTION_LIST_META, res.data.meta);
      };
      return res.data.data._embedded.activity_survey_questions;
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_SURVEY_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [POPULATE_SURVEY_QUESTION_INFO](context, payload) {
    return ApiService.get("api/v1/activities/" + payload.activityId + "/activity_survey_questions/" + payload.id, {
      params: payload.params
    }).then(res => {
      const surveyQuestion = res.data.data;
      context.commit(SET_SURVEY_QUESTION_INFO, surveyQuestion);
    })
    .catch(error => {
      if (error.response) {
        context.commit(SET_SURVEY_QUESTION_ERRORS, error.response.data);
      } else {
        Vue.$log.error(error);
      }
      throw error;
    });
  },
  [CLEAR_SURVEY_QUESTION_LIST](context) {
    context.commit(RESET_SURVEY_QUESTION_LIST);
    context.commit(RESET_SURVEY_QUESTION_LIST_META);
  },
  [CLEAR_SURVEY_QUESTION_INFO](context) {
    context.commit(RESET_SURVEY_QUESTION_INFO);
  },
  [CLEAR_SURVEY_QUESTION_ERRORS](context) {
    context.commit(RESET_SURVEY_QUESTION_ERRORS);
  }
}

const mutations = {
  [SET_SURVEY_QUESTION_LIST](state, surveyQuestions) {
    let arr = [];
    for (let index = 0; index < surveyQuestions.length; index++) {
      const element = surveyQuestions[index];
      arr.push(element);
      const nexElement = surveyQuestions[index + 1];
      if (nexElement) {
        if (element.page < nexElement.page) {
          arr.push({ id: null, is_page_break: true });
        }
      }
    }
    state.survey_question_list = arr;
  },
  [RESET_SURVEY_QUESTION_LIST](state) {
    state.survey_question_list = [];
  },
  [SET_SURVEY_QUESTION_LIST_META](state, survey_question_list_meta) {
    state.survey_question_list_meta = survey_question_list_meta;
  },
  [RESET_SURVEY_QUESTION_LIST_META](state) {
    state.survey_question_list_meta = {};
  },
  [SET_SURVEY_QUESTION_INFO](state, surveyQuestion) {
    state.survey_question_info = surveyQuestion;
  },
  [RESET_SURVEY_QUESTION_INFO](state) {
    state.survey_question_info = [];
  },
  [SET_SURVEY_QUESTION_ERRORS](state, errors) {
    state.survey_question_errors = errors;
  },
  [RESET_SURVEY_QUESTION_ERRORS](state) {
    state.survey_question_errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};