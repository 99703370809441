<template>
  <div class="w-100">
    <b-form-group
      :id="id + '-group'"
      :label="computedLabel"
      :label-for="id"
      :label-sr-only="!displayLabel"
      class="w-100 px-0"
      :class="{ 'mb-0 ': model.$anyError }"
    >
    <b-form-select v-model="model.$model" :options="items" class="form-control form-control-solid py-4 px-6 h-auto"></b-form-select>
    </b-form-group>
    <div class="custom-validation-failure mb-7" v-if="model.$anyError">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: "InputGenericSelect",

  props: ["model", "submissionStates", "id", "label", "error", "items","enableHandlerForFITBEditor"],

  methods: {
    validateState() {
      const { $dirty, $error } = this.model;
      return $dirty ? !$error : null;
    }
  },

  watch: {
    model: {
      handler() {
        let selectorValue = this.model.$model;
        if (this.enableHandlerForFITBEditor) {
          this.$emit('show_special_html_editor', selectorValue) 
        }
      }
    },
  },

  computed: {
    computedLabel: function() {
      if (this.model.$params.hasOwnProperty("required")) {
        return this.label + "*";
      } else {
        return this.label;
      }
    },
    displayLabel: function() {
      if (this.label != undefined) {
        if (this.label.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  }
};
</script>
