import ApiService from "@/core/services/api.service";
import Vue from "vue";

// action types
export const POPULATE_ACTIVITY_IDEASTORM_RESPONSES_LIST =
  "populateActivityIdeastormRepsonsesList";
export const POPULATE_ACTIVITY_IDEASTORM_RESPONSES_INFO =
  "populateActivityIdeastormRepsonsesInfo";
export const POPULATE_ACTIVITY_ADDITIONAL_IDEASTORM_RESPONSES_INFO =
  "populateActivityAdditionalIdeastormRepsonsesInfo";
export const CREATE_IDEASTORM_RESPONSES = "createIdeastormRepsonses";
export const UPDATE_IDEASTORM_RESPONSES = "updateIdeastormRepsonses";
export const DELETE_IDEASTORM_RESPONSES = "deleteIdeastormRepsonses";
export const CLEAR_IDEASTORM_RESPONSES_LIST = "clearIdeastormRepsonsesList";
export const CLEAR_IDEASTORM_RESPONSES_INFO = "clearIdeastormRepsonsesInfo";
export const CLEAR_IDEASTORM_RESPONSES_ERRORS = "clearIdeastormRepsonsesErrors";

// mutation types
export const SET_ACTIVITY_IDEASTORM_RESPONSES_LIST =
  "setActivityIdeastormRepsonsesList";
export const RESET_ACTIVITY_IDEASTORM_RESPONSES_LIST =
  "resetActivityIdeastormRepsonsesList";
export const SET_ACTIVITY_IDEASTORM_RESPONSES_INFO =
  "setActivityIdeastormRepsonsesInfo";
export const RESET_ACTIVITY_IDEASTORM_RESPONSES_INFO =
  "resetActivityIdeastormRepsonsesInfo";
export const APPEND_ACTIVITY_IDEASTORM_RESPONSES_LIST =
  "appendActivityIdeastormRepsonsesInfo";
export const ADD_ACTIVITY_IDEASTORM_RESPONSES = "addActivityIdeastormRepsonses";
export const UPDATE_ACTIVITY_IDEASTORM_RESPONSES =
  "updateActivityIdeastormRepsonses";
export const REMOVE_ACTIVITY_IDEASTORM_RESPONSES =
  "removeActivityIdeastormRepsonses";
export const SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS =
  "setActivityIdeastormRepsonsesErrors";
export const RESET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS =
  "resetActivityIdeastormRepsonsesErrors";

const state = {
  activity_ideastorm_responses_errors: {},
  activity_ideastorm_responses_list: [],
  activity_ideastorm_responses_info: []
};

const getters = {
  activityIdeastormRepsonsesErrors(state) {
    return state.activity_ideastorm_responses_errors;
  },
  activityIdeastormRepsonsesList(state) {
    return state.activity_ideastorm_responses_list;
  },
  activityIdeastormRepsonsesInfo(state) {
    return state.activity_ideastorm_responses_info;
  }
};

const actions = {
  [POPULATE_ACTIVITY_IDEASTORM_RESPONSES_LIST](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_ideastorm_responses;
        context.commit(SET_ACTIVITY_IDEASTORM_RESPONSES_LIST, responses);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_IDEASTORM_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses" +
        payload.activityIdeastormResponseId,
      {
        params: payload.params
      }
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_IDEASTORM_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [POPULATE_ACTIVITY_ADDITIONAL_IDEASTORM_RESPONSES_INFO](context, payload) {
    return ApiService.get(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses",
      {
        params: payload.params
      }
    )
      .then(res => {
        const responses = res.data.data._embedded.activity_ideastorm_responses;
        context.commit(
          APPEND_ACTIVITY_IDEASTORM_RESPONSES_LIST,
          payload,
          responses
        );
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CREATE_IDEASTORM_RESPONSES](context, payload) {
    return ApiService.post(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses",
      payload.data
    )
      .then(res => {
        context.commit(ADD_ACTIVITY_IDEASTORM_RESPONSES, res.data.data);
        return res.data.data;
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [UPDATE_IDEASTORM_RESPONSES](context, payload) {
    return ApiService.put(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses" +
        payload.activityIdeastormResponseId,
      payload.data
    )
      .then(res => {
        const response = res.data.data;
        context.commit(SET_ACTIVITY_IDEASTORM_RESPONSES_INFO, response);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [DELETE_IDEASTORM_RESPONSES](context, payload) {
    return ApiService.delete(
      "api/v1/activities/" +
        payload.activityId +
        "/activity_ideastorm_responses/" +
        payload.id
    )
      .then(res => {
        Vue.$log.info("deleted");
        Vue.$log.info(res);
      })
      .catch(error => {
        if (error.response) {
          context.commit(
            SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS,
            error.response.data
          );
        } else {
          Vue.$log.error(error);
        }
        throw error;
      });
  },
  [CLEAR_IDEASTORM_RESPONSES_LIST](context) {
    context.commit(RESET_ACTIVITY_IDEASTORM_RESPONSES_LIST);
  },
  [CLEAR_IDEASTORM_RESPONSES_INFO](context) {
    context.commit(RESET_ACTIVITY_IDEASTORM_RESPONSES_INFO);
  },
  [CLEAR_IDEASTORM_RESPONSES_ERRORS](context) {
    context.commit(RESET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS);
  }
};

const mutations = {
  [SET_ACTIVITY_IDEASTORM_RESPONSES_LIST](
    state,
    activity_ideastorm_responses_list
  ) {
    state.activity_ideastorm_responses_list = activity_ideastorm_responses_list;
  },
  [RESET_ACTIVITY_IDEASTORM_RESPONSES_LIST](state) {
    state.activity_ideastorm_responses_list = [];
  },
  [SET_ACTIVITY_IDEASTORM_RESPONSES_INFO](
    state,
    activity_ideastorm_responses_info
  ) {
    state.activity_ideastorm_responses_info = activity_ideastorm_responses_info;
  },
  [RESET_ACTIVITY_IDEASTORM_RESPONSES_INFO](state) {
    state.activity_ideastorm_responses_info = [];
  },
  [ADD_ACTIVITY_IDEASTORM_RESPONSES](state, activity_ideastorm_responses_info) {
    state.activity_ideastorm_responses_list.push(
      activity_ideastorm_responses_info
    );
  },
  [UPDATE_ACTIVITY_IDEASTORM_RESPONSES](
    state,
    activity_ideastorm_responses_info
  ) {
    let newActivityIdeastormRepsonsesArray = [];
    newActivityIdeastormRepsonsesArray.push(activity_ideastorm_responses_info);
    state.activity_ideastorm_responses_list = state.activity_ideastorm_responses_list.map(
      obj =>
        newActivityIdeastormRepsonsesArray.find(o => o.id === obj.id) || obj
    );
  },
  [APPEND_ACTIVITY_IDEASTORM_RESPONSES_LIST](
    state,
    payload,
    activity_ideastorm_responses_additional_list
  ) {
    let responseToIndex = state.activity_ideastorm_responses_list.findIndex(
      p => p.id == payload.responding_to
    ); // to be update
    if (responseToIndex !== -1) {
      state.activity_ideastorm_responses_list = state.activity_ideastorm_responses_list.splice(
        responseToIndex,
        0,
        activity_ideastorm_responses_additional_list
      );
    } else {
      state.activity_ideastorm_responses_list.push(
        activity_ideastorm_responses_additional_list
      );
    }
  },
  [REMOVE_ACTIVITY_IDEASTORM_RESPONSES](state, activityIdeastormResponseId) {
    state.activity_ideastorm_responses_list = state.activity_ideastorm_responses_list.filter(
      function(obj) {
        return obj.id !== activityIdeastormResponseId;
      }
    );
  },
  [SET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS](
    state,
    activity_ideastorm_responses_errors
  ) {
    state.activity_ideastorm_responses_errors = activity_ideastorm_responses_errors;
  },
  [RESET_ACTIVITY_IDEASTORM_RESPONSES_ERRORS](state) {
    state.activity_ideastorm_responses_errors = [];
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
